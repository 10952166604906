import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

export default function WorkEngagementResults({
  responses,
}: {
  responses: any;
}) {
  console.log('WorkEngagementResults', responses);

  const lang = useSelector((state: Store) => state.lang);
  if (!responses) return null;

  const avg = responses?.reduce((sum, response) => sum + response, 0) / 9;
  let vigor = responses[0] + responses[1] + responses[4];
  let dedication = responses[2] + responses[3] + responses[6] / 18;
  let absorption = responses[5] + responses[7] + responses[8];

  vigor = Math.round(vigor / 3);
  dedication = Math.round(dedication / 3);
  absorption = Math.round(absorption / 3);

  return (
    <div>
      <div className='list-none'>
        {/* <div>Average Flourishing Score: {avg.toFixed(1)} / 10</div> */}
        {/* {domains.map((d, i) => {
          return <div key={i}>{`${DOMAINS[i]} ${d.toFixed(1)}/10`}</div>; */}
        {/* })} */}
      </div>
      {/* <hr /> */}
      {responses && (
        <div className='p-2 shadow rounded-lg'>
          {/* <div className='text-center'>Avg Flourishing Score</div> */}
          <div
            className='p-2 bg-emerald rounded-lg text-center'
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <img
              className='-mt-10 mx-auto object-cover'
              src='/work_engagement.svg'
              alt=''
            />
            <div
              className='flex-grow text-white text-4xl'
              style={{
                fontFamily: 'Georgia',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}>
              {lang === 'en' ? (
                'Average Work Engagement Score'
              ) : (
                <span>
                  Puntuación media <br />
                  Trabajo y compromiso
                </span>
              )}
            </div>

            {/* @ts-ignore */}
            <div className='text-white'>{avg.toFixed(1)}/6pts</div>
            <img className='text-' src='/flourishing-results-dots.svg' alt='' />
            <p
              className='text-2xl'
              style={{
                color: '#6B583F',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}>
              {lang === 'en'
                ? 'Your work engagement results'
                : 'Tus resultados de Trabajo y compromiso'}
            </p>
          </div>
        </div>
      )}

      <div className='mt-10 grid grid-cols-1 md:grid-cols-3 gap-3'>
        <Card
          title={lang === 'en' ? 'Vigor' : 'Vigor'}
          text={
            lang === 'en'
              ? 'High levels of energy and mental resilience while working. Persistence even in the face of difficulties.'
              : 'Altos niveles de energía y resiliencia en el trabajo. Perseverancia incluso al afrontar dificultades.'
          }
          icon='/vigor.svg'
          value={vigor}
          lang={lang}
        />
        <Card
          title={lang === 'en' ? 'Dedication' : 'Dedicación'}
          text={
            lang === 'en'
              ? `Strongly involved in one's work.  Sense of significance, enthusiasm, inspiration, pride, and challenge.`
              : 'Estar fuertemente involu-crado en el trabajo. Sentido de propósito, entusiasmo, inspiración, orgullo y desafío.'
          }
          icon='/dedication.svg'
          value={dedication}
          lang={lang}
        />
        <Card
          title={lang === 'en' ? 'Absorption' : 'Concentración'}
          text={
            lang === 'en'
              ? `Fully concentrated and happily engrossed in one’s work. Time passes quickly. Difficulties with detaching oneself from work`
              : `Estar absorto y ensimismado en la tarea que se realiza. El tiempo pasa volando, y resulta difícil dejar de hacer lo que se tiene entre manos.
`
          }
          icon='/absorption.svg'
          value={absorption}
          lang={lang}
        />
      </div>
      <div className='mt-10' />
      {lang === 'en' ? (
        <h2 className='ml-2 mt-4 mb-2 text-lg text-white text-right'>
          There are 3 dimensions that characterize how positive and <br />
          fulfilling is your work-related state of mind. Above are how
          <br />
          you scored in each of the three statements.
        </h2>
      ) : (
        <h2 className='ml-2 mt-2 mb-2 text-lg text-white text-right'>
          Hay 3 dimensiones que caracterizan cuán positivo y satisfactorio{' '}
          <br /> es tu estado mental relacionado con el trabajo. Tu puntuación
          <br />
          para cada una de las tres dimensiones aparece arriba.{' '}
        </h2>
      )}
    </div>
  );
}
//  <div className='flex flex-col text-center overflow-hidden '>
//    <div className='mx-auto bg-gold rounded-full p-4 shadow-xl overflow-hidden'>
//      <img className='h-15 w-15' src={Icon} alt='' />
//    </div>
//    <h3 className='mt-3 text-xl text-center uppercase text-emerald'>{header}</h3>
//    {/* <div className='flex flex-col justify-around'> */}
//    <div className='flex-grow text-sm text-white px-4 py-5 sm:p-6'>{text}</div>
//    <h2 className='text-emerald'>{body}</h2>
//    {/* </div> */}
//  </div>;
const Card = ({ title, text, value, icon, lang }) => (
  <div className='flex flex-col items-center overflow-hidden'>
    <img
      className='rounded-full h-24 w-24 p-2'
      src={icon}
      alt=''
      style={{ backgroundColor: '#C4C4C4' }}
    />
    <h2 className='mt-4 text-emerald text-2xl'>{title}</h2>
    <p className='mt-4 text-white text-center flex-grow'>{text}</p>
    <p className='text-emerald text-2xl' style={{ fontFamily: 'Georgia' }}>
      {value}/6 pts
    </p>
  </div>
);
