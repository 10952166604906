import { setColorLevel } from '../utils/set_color_level';

const StatCard = ({
  title,
  about,
  secondaryText,
  stat,
  percent,
  icon,
  version = 1,
}: {
  title: string;
  about?: string;
  secondaryText?: string;
  stat: string;
  percent: string;
  icon: string;
  version?: number;
}) => {
  // Hack to show the flipped version for the one spanish set
  if (version === 2) {
    return (
      <div
        className='text-black text-center shadow rounded-lg'
        style={{
          backgroundColor: '#C4C4C4',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '20px',
        }}>
        {/* <img alt='' src={`${icon}`} className='p-2 h-14 mx-auto object-cover' /> */}
        <img className='mx-auto mt-2 h-16 w-16' src={icon} alt='' />
        <div className='grid grid- px-2 py-2 sm:p-3'>
          <div className='ml-2 text-center'>
            <div className='flex-grow text-base'>{about}</div>
            <div
              className='text-xl leading-0 font-medium'
              style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
              {title.toUpperCase()}
            </div>
          </div>
          {/* body  */}
          <div className={``}>
            {stat && percent && (
              <div className=''>
                <div
                  className='items-baseline text-2xl text-emerald'
                  style={{ fontFamily: 'Georgia' }}>
                  {percent}%
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // The standard (V1) version
  return (
    <div
      className='text-black text-center shadow rounded-lg'
      style={{
        backgroundColor: '#C4C4C4',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
      }}>
      {/* <img alt='' src={`${icon}`} className='p-2 h-14 mx-auto object-cover' /> */}
      <img className='mx-auto mt-2 h-16 w-16' src={icon} alt='' />
      <div className='grid grid- px-2 py-2 sm:p-3'>
        <div className='ml-2 text-center'>
          <div
            className='text-xl leading-0 font-medium'
            style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            {title.toUpperCase()}
          </div>
          <div className='flex-grow text-base'>{about}</div>
        </div>
        {/* body  */}
        <div className={``}>
          {stat && percent && (
            <div className=''>
              <div
                className='items-baseline text-2xl text-emerald'
                style={{ fontFamily: 'Georgia' }}>
                {percent}%
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default StatCard;

const Saved_StatCard = ({
  title,
  about,
  stat,
  percent,
  icon,
}: {
  title: string;
  about?: string;
  stat: number;
  percent: number;
  icon: string;
}) => {
  return (
    <div className='bg-white overflow-hidden shadow rounded-lg'>
      <div className='flex flex-col px-2 py-2 sm:p-3'>
        <div className='text-md leading-0 font-medium text-gray-500 ml-2 truncate'>
          {title}
        </div>
        <p className='flex-grow ml-2 text-sm text-gray-500'>{about}</p>
        {/*  */}
        <div className='place-self-end'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 bg-indigo-50 rounded-md mt-0 h-14 w-14'>
              <img
                alt='icon of '
                src={icon}
                className='mt-0 mb-0 p-1 object-cover'
              />
            </div>
            <div className='ml-5 w-0 flex-1'>
              <dl>
                <dt className='text-sm leading-5 font-medium text-gray-500 truncate'>
                  {/* {title} */}
                </dt>
                <dd className=''>
                  <div className='text-2xl leading-8 font-semibold text-gray-900'>
                    {stat}
                  </div>
                  <div className='items-baseline text-sm leading-5 font-semibold text-indigo-600'>
                    {percent}%
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        {/* <p className='text-sm text-gray-500'>{about}</p> */}
      </div>
    </div>
  );
};
