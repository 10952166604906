import React, { useEffect, useState } from 'react';
import { onAuthStateChange } from '../firebase/on_auth_change';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation } from 'react-query';

import FirebaseClient from '../firebase/firebase_client';
import { Spinner } from '../components/Spinner';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

export const AccountPage = () => {
  const [statusMsg, setStatusMsg] = useState<string | undefined>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const lang = useSelector((state: Store) => state.lang);

  const [user, setUser] = useState(FirebaseClient.auth?.currentUser);

  const mutation = useMutation(
    ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      if (!user?.email) {
        throw new Error('Email not found');
      }
      setErrorMsg(undefined);
      setStatusMsg(undefined);
      return FirebaseClient.auth
        .signInWithEmailAndPassword(user?.email, currentPassword)
        .then((response) => {
          console.log('Signed in successfully');
          return FirebaseClient.auth?.currentUser?.updatePassword(newPassword);
        })
        .then((response) => {
          console.log('Change password successfully');
        });
    },
    {
      onSuccess: (response) => {
        setStatusMsg('Success');
        setErrorMsg(undefined);
      },
      onError: (error: any) => {
        console.error(error);
        setErrorMsg(error?.message);
      },
    },
  );

  useEffect(() => {}, []);
  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <dl className='mt-10'>
        <dt>{lang === 'en' ? 'Email' : 'Correo electrónico'}</dt>

        <dd>{user?.email}</dd>
      </dl>

      <div className='mt-10' />
      {/* {JSON.stringify(user?.providerData)} */}
      {/* {JSON.stringify(user?.updatePassword)} */}

      <h1 className='text-xl leading 8'>
        {lang === 'en' ? 'Change password' : 'Cambiar la contraseña'}
      </h1>
      <p className=''>{statusMsg} </p>
      <p className='text-red-500'>{errorMsg} </p>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
        }}
        onSubmit={(values) => {
          const { currentPassword, newPassword } = values;
          mutation.mutate({ currentPassword, newPassword });
        }}>
        <Form className='w-full md:w-1/2' translate='yes'>
          <div>
            <label
              htmlFor='currentPassword'
              className='block text-sm font-medium leading-5 text-gray-700'>
              {lang === 'en' ? 'Current Password' : 'Contraseña Actual'}
            </label>
            <div className='mt-1 rounded-md shadow-sm'>
              <Field
                id='currentPassword'
                name='currentPassword'
                type='password'
                autoComplete='current-password'
                required
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='newPassword'
              className='block text-sm font-medium leading-5 text-gray-700'>
              {lang === 'en' ? 'New Password' : 'Nueva contraseña'}
            </label>
            <div className='mt-1 rounded-md shadow-sm'>
              <Field
                id='newPassword'
                name='newPassword'
                type='password'
                required
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              />
            </div>
          </div>
          {/* <div className='mt-6 flex items-center justify-between'>
            <div className='text-sm leading-5'>
              <Link
                to='/reset-password'
                className='font-medium text-gray-600 hover:text-skyblue focus:outline-none focus:underline transition ease-in-out duration-150'>
                Forgot your password?
              </Link>
            </div>
          </div> */}

          <button
            type='submit'
            className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gold hover:bg-emerald focus:outline-none transition duration-150 ease-in-out'>
            {mutation.isLoading && <Spinner />}
            {lang === 'en' ? 'Change Password' : 'Nueva contraseña'}
          </button>
        </Form>
      </Formik>
    </div>
  );
};
