export const surveyES = [
  {
    code: 'M5',
    q: 'El deseo de complacer a los demás',
    category: 'ExtrinsicPleasant',
  },
  {
    code: 'M6',
    q: 'El deseo de que cuenten conmigo',
    category: 'ExtrinsicPleasant',
  },
  { code: 'M7', q: 'El deseo de caer bien', category: 'ExtrinsicPleasant' },
  {
    code: 'M8',
    q: 'El deseo de que se me valore',
    category: 'ExtrinsicPleasant',
  },
  {
    code: 'M9',
    q: 'El deseo de contar con el respeto de los demás',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M10',
    q: 'El deseo de que se me considere una buena persona ',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M11',
    q: 'El deseo de recibir un trato bueno y justo',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M12',
    q: 'El deseo de recibir lo que merezco',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M13',
    q: 'El deseo de obtener algún bien espiritual',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M14',
    q: 'El deseo de obtener paz interior ',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M15',
    q: 'El deseo de hacer presente la dimensión espiritual en mi trabajo',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M16',
    q: 'El deseo de conseguir dones o gracias espirituales ',
    category: 'ExtrinsicSpiritual',
  },
  { code: 'M1', q: 'El deseo de ganar dinero', category: 'ExtrinsicUseful' },
  {
    code: 'M2',
    q: 'El deseo de conservar el trabajo',
    category: 'ExtrinsicUseful',
  },
  {
    code: 'M3',
    q: 'El deseo de tener estabilidad económica ',
    category: 'ExtrinsicUseful',
  },
  {
    code: 'M4',
    q: 'El deseo de mantener un cierto nivel de vida',
    category: 'ExtrinsicUseful',
  },

  {
    code: 'M21',
    q: 'El deseo de hacer lo que me gusta',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M22',
    q: 'El deseo de sentirme bien',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M23',
    q: 'El deseo de sentirme realizado',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M24',
    q: 'El deseo de sentirme satisfecho',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M25',
    q: 'El deseo de convertirme en una mejor persona trabajando',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M26',
    q: 'El deseo de poner en práctica mis valores en el trabajo',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M27',
    q: 'El deseo de cumplir con mi deber, de trabajar bien',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M28',
    q: 'El deseo de crecer moralmente como persona trabajando',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M29',
    q: 'El deseo de crecer espiritualmente trabajando',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M30',
    q: 'El deseo de vivir una vida llena de sentido trabajando',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M31',
    q: 'El deseo de alcanzar armonía interior a través del trabajo',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M32',
    q: 'El deseo de alcanzar paz interior a través del trabajo',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M17',
    q: 'El deseo de poner en práctica mis habilidades y conocimientos',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M18',
    q: 'El deseo de ser competente',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M19',
    q: 'El deseo de crecer en experiencia',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M20',
    q: 'El deseo de no fallar en lo que hago',
    category: 'IntrinsicUseful',
  },

  {
    code: 'M37',
    q: 'El deseo de hacer la vida agradable a los demás con mi trabajo',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M38',
    q: 'El deseo de contribuir a un buen ambiente de trabajo',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M39',
    q: 'El deseo de hacer que mis compañeros de trabajo estén satisfechos',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M40',
    q: 'El deseo de ayudar a que mis compañeros de trabajo estén contentos',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M41',
    q: 'El deseo de hacer el bien a los demás a través de mi trabajo',
    category: 'TranscendentMoral',
  },
  {
    code: 'M42',
    q: 'El deseo de contribuir al bien común a través de mi trabajo',
    category: 'TranscendentMoral',
  },
  {
    code: 'M43',
    q: 'El deseo de ayudar a mis compañeros a ser mejores personas',
    category: 'TranscendentMoral',
  },
  {
    code: 'M44',
    q: 'El deseo de contribuir con mi trabajo al bienestar de la sociedad ',
    category: 'TranscendentMoral',
  },
  {
    code: 'M45',
    q: 'El deseo de contribuir con mi trabajo al bien espiritual de los demás',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M46',
    q: 'El deseo de que los demás encuentren paz interior',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M47',
    q: 'El deseo de contribuir a que los demás encuentren más sentido a sus vidas',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M48',
    q: 'El deseo de promover un sentido espiritual más alto en el trabajo',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M33',
    q: 'El deseo de ayudar a otras personas',
    category: 'TranscendentUseful',
  },
  {
    code: 'M34',
    q: 'El deseo de contribuir al bienestar futuro de los demás y de mi familia',
    category: 'TranscendentUseful',
  },
  {
    code: 'M35',
    q: 'El deseo de servir a los demás',
    category: 'TranscendentUseful',
  },
  {
    code: 'M36',
    q: 'El deseo de ser útil para los demás',
    category: 'TranscendentUseful',
  },
];

export const survey2ES = [
  {
    code: 'M53',
    q: 'El deseo de complacer a Dios a través de mi trabajo',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M54',
    q: 'El deseo de agradar a Dios con mi trabajo ',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M55',
    q: 'El deseo de satisfacer a Dios con mi trabajo',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M56',
    q: 'El deseo de dar gracias a Dios con mi trabajo',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M57',
    q: 'El deseo de hacer bien mi trabajo como una ofrenda para Dios',
    category: 'ReligiousMoral',
  },
  {
    code: 'M58',
    q: 'El deseo de utilizar bien los dones o talentos recibidos de Dios',
    category: 'ReligiousMoral',
  },
  {
    code: 'M59',
    q: 'El deseo de corresponder a Dios por los dones que me ha regalado',
    category: 'ReligiousMoral',
  },
  {
    code: 'M60',
    q: 'El deseo de dar a Dios los frutos de mi trabajo',
    category: 'ReligiousMoral',
  },
  {
    code: 'M61',
    q: 'El deseo de alabar a Dios con mi trabajo',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M62',
    q: 'El deseo de reflejar la bondad de Dios a través de mi trabajo',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M63',
    q: 'El deseo de mostrar la grandeza de Dios a través de mi trabajo',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M64',
    q: 'El deseo de dar honor y gloria a Dios con mi trabajo',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M49',
    q: 'El deseo de cumplir la voluntad de Dios a través de mi trabajo',
    category: 'ReligiousUseful',
  },
  {
    code: 'M50',
    q: 'El deseo de servir a Dios con mi trabajo ',
    category: 'ReligiousUseful',
  },
  {
    code: 'M51',
    q: 'El deseo de hacer lo que Dios espera de mí en el trabajo ',
    category: 'ReligiousUseful',
  },
  {
    code: 'M52',
    q: 'El deseo de ser fiel a Dios a través de mi trabajo',
    category: 'ReligiousUseful',
  },
];

export const FlourishingESV2 = [
  {
    code: '65',
    q: 'En general, ¿cómo está de satisfecho/a con su vida?',
    category: '',
  },
  {
    code: '69',
    q: 'En general, ¿cree que las cosas que hace en la vida valen la pena?',
    category: '',
  },
];

export const FlourishingES = [
  {
    code: '65',
    q: 'En general, ¿cómo está de satisfecho/a con su vida?',
    category: '',
  },
  {
    code: '66',
    q: 'En general, ¿cómo se siente normalmente, en términos de felicidad?',
    category: '',
  },
  {
    code: '67',
    q: '¿Cómo calificaría su SALUD FÍSICA?',
    category: '',
  },
  {
    code: '68',
    q: '¿Cómo calificaría su SALUD MENTAL?',
    category: '',
  },
  {
    code: '69',
    q: 'En general, ¿cree que las cosas que hace en la vida valen la pena?',
    category: '',
  },
  { code: '70', q: 'Sé que mi vida tiene un propósito', category: '' },
  {
    code: '71',
    q: 'Procuro actuar bien siempre, también en situaciones más difíciles',
    category: '',
  },
  {
    code: '72',
    q: 'Soy capaz de renunciar a un poco de felicidad en el presente a cambio de una felicidad mayor más adelante',
    category: '',
  },
  {
    code: '73',
    q: 'Estoy contento/a con mis amistades y mis relaciones personales',
    category: '',
  },
  {
    code: '74',
    q: 'Mis relaciones actuales son realmente tan buenas como me gustaría',
    category: '',
  },
  {
    code: '75',
    q: '¿Con qué frecuencia me asalta la preocupción de poder cubrir los gastos mensuales?',
    category: '',
  },
  {
    code: '76',
    q: '¿Con qué frecuencia me asalta la preocupación por la seguridad, por tener dónde vivir o qué comer?',
    category: '',
  },
];
