import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

export const BookCTA = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div
      className='p-10 min-h-[500px] grid md:grid-cols-2 place-items-center'
      style={{ backgroundColor: '#FCF3EA' }}>
      {lang === 'en' ? (
        <div className='font-oswald text-3xl'>
          All the theory behind this survey is described in the book
          <br />
          <span className='font-bold'>
            MOTIVATION IN ORGANIZATIONS AND MEANING OF WORK
          </span>
        </div>
      ) : (
        <div className='font-oswald text-3xl'>
          Toda la teoría que se encuentra tras esta encuesta está descrita en el
          libro
          <br />
          <span className='font-bold'>
            MOTIVACIÓN EN LAS ORGANIZACIONES Y SENTIDO DEL TRABAJO
          </span>
        </div>
      )}
      {lang === 'en' ? (
        <img
          src='/media/libro-manuel-guillen-ingles.png'
          alt='book'
          className='h-[500px]'
        />
      ) : (
        <img
          src='/media/libro-manuel-guillen.png'
          alt='book'
          className='h-[500px]'
        />
      )}
    </div>
  );
};
