import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CTA from '../components/LearnMoreCTA';
import { WorkPageTurner } from '../components/WorkPageTurner';
import { Store } from '../redux/store';

export const WorkEngagementPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div
          className='p-5 md:p-24'
          style={{
            background:
              'linear-gradient(180deg, rgba(35, 35, 35, 0.7) 0%, rgba(77, 64, 48, 0.7) 100%)',
            backdropFilter: 'blur(30px)',
          }}>
          <h1 className='text-gold text-4xl' style={{ fontFamily: 'Georgia' }}>
            {lang === 'en' ? 'Work Engagement' : 'Trabajo y compromiso'}
          </h1>
          <img className='mt-2' src='/work-motivation-dots.svg' alt='' />
          <div className='mt-10' />
          <p className='text-white text-2xl'>
            {lang === 'en'
              ? 'Engagement is a positive, fulfilling, work-related state of mind that is characterized by vigor, dedication, and absorption. Rather than a momentary and specific state, it refers to a more persistent and pervasive affective-cognitive state.'
              : 'El compromiso, relativo al trabajo, es un estado mental positivo que produce satisfacción. Más que un estado momentáneo y específico, se refiere a un estado cognitivo-afectivo más persistente y generalizado. Se caracteriza por el vigor, la dedicación y el estar absorto en lo que uno hace.'}
          </p>
          <div className='mt-10' />
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'>
            {lang === 'en' ? <></> : <> </>}
            <Card
              title={lang === 'en' ? 'Vigor' : 'Vigor'}
              text={
                lang === 'en'
                  ? 'High levels of energy and mental resilience while working. Persistence even in the face of difficulties.'
                  : 'Altos niveles de energía y resiliencia en el trabajo. Perseverancia incluso al afrontar dificultades.'
              }
              icon='/vigor.svg'
            />
            <Card
              title={lang === 'en' ? 'Dedication' : 'Dedicación'}
              text={
                lang === 'en'
                  ? `Strongly involved in one's work.  Sense of significance, enthusiasm, inspiration, pride, and challenge.`
                  : 'Estar fuertemente involucrado en el trabajo. Sentido de propósito, entusiasmo, inspiración, orgullo y desafío.'
              }
              icon='/dedication.svg'
            />
            <Card
              title={lang === 'en' ? 'Absorption' : 'Concentración'}
              text={
                lang === 'en'
                  ? `Fully concentrated and happily engrossed in one’s work. Time passes quickly. Difficulties with detaching oneself from work`
                  : `Estar absorto y ensimismado en la tarea que se realiza. El tiempo pasa volando, y resulta difícil dejar de hacer lo que se tiene entre manos.
`
              }
              icon='/absorption.svg'
            />
          </div>
          {/*  */}
          <div className='mt-20' />
          <WorkPageTurner page={'work-engagement'} />
        </div>
      </div>
      <div className='bg-white'>
        <CTA />
      </div>
    </div>
  );
};

const Card = ({ title, text, icon }) => (
  <div className='flex flex-col items-center overflow-hidden'>
    <img
      className='rounded-full h-24 w-24 p-2'
      src={icon}
      alt=''
      style={{ backgroundColor: '#C4C4C4' }}
    />
    <h2 className='mt-4 text-emerald text-2xl font-bold uppercase'>{title}</h2>
    <p className='mt-4 text-white text-center flex-grow'>{text}</p>
  </div>
);
