import firebase from 'firebase/app';
import FirebaseClient from './firebase_client';

const provider = new firebase.auth.GoogleAuthProvider();

export async function doSignInWithGoogle() {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: SIGN IN WITH GOOGLE REQUESTED');
    console.groupEnd();
  }
  try {
    const results = FirebaseClient.auth.signInWithPopup(provider);
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: SIGN IN WITH GOOGLE SUCCEEDED');
      console.groupEnd();
    }

    return results;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: SIGN IN WITH GOOGLE FAILED');
      console.log('### ERROR ###', error);
    }
    console.groupEnd();
    return { hasError: true, error };
  }
}
