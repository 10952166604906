import { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

// Male-Female
// Age range--- <= 24 // 25-34 // 35-44 // 45-54 // >=55
// Kind of work situation: e.g. student, self-employed, others-employed, retired, other…
// Country

const DemographicQuestions = ({ section, handleCompleted }) => {
  const numberOfSections = 5;
  const lang = useSelector((state: Store) => state.lang);
  const [showTitle, setShowTitle] = useState(false);


  if (showTitle) {
    return (
      <div className='text-ieco-2 '>
        <div className=''>
          <h2 className='text-2xl  text-center'>
            Section {section}/{numberOfSections}
          </h2>
          {/* <h1 className='text-5xl'>
            {lang === 'en' ? 'Work Motivation' : 'Motivación el en trabajo'}
          </h1> */}
          {/* <h2 className='text-2xl  text-center italic'>{title}</h2> */}

          {lang === 'en' ? (
            <div className='mt-10 text-xl '>
              <p className='mt-4'>Press next to start.</p>
            </div>
          ) : (
            <div className=''>
              <p>Presione siguiente para comenzar</p>
            </div>
          )}

          <div className='flex mt-10'>
            <button
              className='flex  px-8 py-2 bg-gold  rounded-full'
              onClick={() => setShowTitle(false)}>
              {lang === 'en' ? <span>Next</span> : <span>Siguiente</span>}
              <svg
                className='flex-shrink-1 h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        </div>
        <div className='ml-2' />
      </div>
    );
  }

  return (
    <div className=''>
      <div className=''>
        <h2 className='text-xl'>
          {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
        </h2>
        {/* <h2 className='text-3xl font-bold'>
          {lang === 'en'
            ? 'Demographic Information'
            : 'Segmentación demográfica'}
        </h2> */}

        <div className='mt-10' />

        <Formik
          initialValues={{
            sex: '',
            age: '',
            work: '',
            country: '',
            rec: '',
            rectext: '',
          }}
          onSubmit={(values) => {
            // alert(JSON.stringify(values));
            handleCompleted({ answers: values, section });
          }}>
          {({ values }) => {
            return (
              <Form className=''>
                {/* SEX */}
                <div className='mt-10 text-center flex flex-col items-center'>
                  <h1 className='font-bold border-b border-gold'>
                    {lang === 'en' ? 'Sex' : 'Sexo'}
                  </h1>
                  <div className='mt-2 italic space-x-4'>
                    {[
                      { value: 'male', en: 'Male', es: 'Hombre' },
                      { value: 'female', en: 'Female', es: 'Mujer' },
                    ].map((sex, index) => {
                      return (
                        <label key={index}>
                          <Field
                            type='radio'
                            name='sex'
                            value={sex.value}
                            required
                          />
                          <span className='ml-2'>{sex[lang]}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>

                {/* AGE */}
                {/* = 24 // 25-34 // 35-44 // 45-54 // >=55 */}
                <div className='mt-10 text-center flex flex-col items-center'>
                  <h1 className='font-bold border-b border-gold'>
                    {lang === 'en'
                      ? 'What is your age group?'
                      : 'Rango de edad'}
                  </h1>
                  <div className='mt-2 italic space-x-4'>
                    {['<25', '25-34', '35-44', '45-54', '>55'].map(
                      (age, index) => {
                        return (
                          <label key={index} className=''>
                            <Field
                              type='radio'
                              name='age'
                              value={age}
                              required
                            />
                            <span className='ml-2'>{age}</span>
                          </label>
                        );
                      },
                    )}
                  </div>
                </div>

                {/* WORK SITUATION */}
                {/* student, self-employed, others-employed, retired, other… */}
                <div className='mt-10 text-center flex flex-col items-center'>
                  <h1 className='font-bold border-b border-gold'>
                    {lang === 'en'
                      ? 'What is your work situation?'
                      : 'Situación laboral'}
                  </h1>

                  <div className='mt-2 italic space-x-4'>
                    {[
                      { value: 'student', en: 'Student', es: 'Estudiante' },
                      {
                        value: 'self-employed',
                        en: 'Self-employed',
                        es: 'Autónomo',
                      },
                      {
                        value: 'others-employed',
                        en: 'Others-employed',
                        es: 'Otros trabajos',
                      },
                      { value: 'retired', en: 'Retired', es: 'Jubilado' },
                      { value: 'other', en: 'Other', es: 'Otro' },
                    ].map((work, index) => {
                      return (
                        <label key={index} className=''>
                          <Field
                            type='radio'
                            name='work'
                            value={work.value}
                            required
                          />
                          <span className='ml-2'>{work[lang]}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>

                {/* COUNTRY */}
                <div className='mt-10 text-center flex flex-col items-center'>
                  <h1 className='font-bold border-b border-gold'>
                    {lang === 'en' ? 'What is your country?' : 'País'}
                  </h1>
                  <Field
                    className='m-2 p-2 bg-gold bg-opacity-50'
                    type='text'
                    name='country'
                    required
                  />
                </div>

                <div className='flex mt-10 flex-col items-center'>
                  <button
                    type='submit'
                    className='flex text-white font-bold px-8 py-1 bg-gold rounded-full'>
                    <span>{lang === 'en' ? 'Next' : 'Siguiente'}</span>
                    <svg
                      className='flex-shrink-1 h-6 w-6'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'>
                      <path
                        fillRule='evenodd'
                        d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className='ml-2' />
    </div>
  );
};

export default DemographicQuestions;
