import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Formik, Field, Form, FormikHelpers } from 'formik';

import FirebaseClient from '../firebase/firebase_client';
import { Spinner } from '../components/Spinner';
// import { Spinner } from '../../components/network_state/Spinner';

export const RequestResetPasswordPage = () => {
  const [statusMsg, setStatusMsg] = useState<string | undefined>();
  const mutation = useMutation(
    ({ email }: { email: string }) =>
      FirebaseClient.auth.sendPasswordResetEmail(email),
    {
      onSuccess: (data) => {
        setStatusMsg('Success. Please check your email');
      },

      onError: (error: any) => {
        setStatusMsg(JSON.stringify(error?.message));
      },
    },
  );
  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <main className='mt-4 max-w-3xl mx-auto'>
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <h1>Request Reset Password Email</h1>
          <p>{statusMsg}</p>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={(values) => {
                const { email } = values;
                mutation.mutate({ email });
              }}>
              <Form translate='yes'>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    Email address
                  </label>
                  <div className='mt-1 rounded-md shadow-sm'>
                    <Field
                      id='email'
                      name='email'
                      type='email'
                      placeholder='name@example.com'
                      autoComplete='username'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-crimson focus:border-crimson-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-crimson-500 hover:bg-crimson-800 focus:outline-none focus:border-crimson-700 focus:shadow-outline-indigo active:bg-crimson-500 transition duration-150 ease-in-out'>
                  {mutation.isLoading && <Spinner />}
                  Send email
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};
export default RequestResetPasswordPage;
