import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { doSignOut } from '../firebase/sign_out';
import { QuizMenu } from './QuizMenu';
import { AboutMenu } from './AboutMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../redux/store';
import { Avatar } from '@chakra-ui/react';

const LINKS = [
  {
    text: { en: 'Quiz', es: 'Encuesta' },
    link: { en: '/quiz/instructions', es: '/quiz/instructions' },
    external: false,
  },
  {
    text: { en: 'Interpret your results', es: 'Interpreta tus resultados' },
    link: {
      en: 'http://app.iecoinstitute.org/interpret-your-results/',
      es: 'http://app.iecoinstitute.org/es/interpreta-tus-resultados/',
    },
    external: true,
  },
  {
    text: { en: 'Resources', es: 'Recursos' },
    link: {
      en: 'http://app.iecoinstitute.org/resources/',
      es: 'http://app.iecoinstitute.org/es/recursos/',
    },
    external: true,
  },
  {
    text: { en: 'About Us', es: 'Quiénes somos' },
    link: {
      en: 'http://app.iecoinstitute.org/about/',
      es: 'http://app.iecoinstitute.org/es/about/',
    },
    external: true,
  },

  // TODO: signed-in ones
];

const AppBarLink = ({
  text,
  link,
  external,
}: {
  text: string;
  link: string;
  external: boolean;
}) => {
  if (external) {
    return (
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        className={`relative ml-8 inline-flex items-center px-1 pt-1 text-sm text-ieco-2 font-bold`}>
        {text}
      </a>
    );
  }
  return (
    <Link
      to={link}
      className={`relative ml-8 inline-flex items-center px-1 pt-1 text-sm text-ieco-2 font-bold`}>
      {text}
    </Link>
  );
};

export const NavBar = ({ currentUser }: { currentUser: any }) => {
  const location = useLocation();
  const [profileDisplayOpen, setProfileDisplayOpen] = useState(false);

  const lang = useSelector((state: Store) => state.lang);
  const dispatch = useDispatch();

  return (
    <nav className='relative hidden lg:block shadow-2xl'>
      <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='relative flex justify-between items-center h-20'>
          <Link to='/' className='block'>
            <img
              className='object-contain object-center'
              src='/media/IECO-LOGO.png'
              width='200px'
              alt='IECO logo'
            />
          </Link>

          <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
            {LINKS.map((link, index) => (
              <AppBarLink
                key={index}
                text={link.text[lang]}
                link={link.link[lang]}
                external={link.external}
              />
            ))}
            {currentUser && (
              <AppBarLink
                link='/history'
                text={lang === 'es' ? 'Historial' : 'History'}
                external={false}
              />
            )}
          </div>
          <div className='absolute inset-y-0 right-0 flex shrink-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
            {/* Profile dropdown */}
            {!currentUser && (
              <div className='ml-3 relative space-x-2'>
                <Link
                  to='/sign-up'
                  className='px-10 py-1 text-bold whitespace-nowrap border-2 border-black rounded-full'>
                  {lang === 'en' ? 'Sign up' : 'Registrarse'}
                </Link>
                <Link
                  to='/sign-in'
                  className='px-10 py-1 text-white text-bold whitespace-nowrap border-2 border-crimson-default bg-crimson-default rounded-full'>
                  {lang === 'en' ? 'Sign in' : 'Iniciar sesión'}
                </Link>
              </div>
            )}

            {/* Profile Button */}
            {/* displayed only if signed in*/}
            <ClickAwayListener onClickAway={() => setProfileDisplayOpen(false)}>
              <div className={`ml-3 ${currentUser ? 'relative' : 'hidden'}`}>
                <div>
                  <button
                    className='flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out'
                    id='user-menu'
                    aria-label='User menu'
                    aria-haspopup='true'
                    onClick={() => setProfileDisplayOpen(!profileDisplayOpen)}>
                    <img
                      className='h-8 w-8 rounded-full'
                      src={currentUser?.photoURL || '/media/avatar.svg'}
                      alt="User's profile avatar"
                    />
                  </button>
                </div>
                <Transition
                  show={profileDisplayOpen}
                  enter='transition ease-out duration-200'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-0 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <ProfileDropdownPanel account={currentUser} />
                </Transition>
              </div>
            </ClickAwayListener>

            {/* Language Selection Outlet */}
            <div className='ml-8 space-x-2'>
              <Avatar
                size='sm'
                src='/media/2560px-Flag_of_Spain.svg.png'
                onClick={() => {
                  dispatch({
                    type: 'SET_LANG',
                    payload: { lang: 'es' },
                  });
                }}
              />
              <Avatar
                size='sm'
                src='/media/1200px-Flag_of_the_United_Kingdom.png'
                onClick={() => {
                  dispatch({
                    type: 'SET_LANG',
                    payload: { lang: 'en' },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const ProfileDropdownPanel = ({ account }: { account: any }) => {
  // const dispatch = useDispatch();
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10'>
      <div
        className='py-1 rounded-md bg-white shadow-xs'
        role='menu'
        aria-orientation='vertical'
        aria-labelledby='user-menu'>
        <Link
          to='/account'
          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
          role='menuitem'>
          {lang === 'en' ? 'Account' : 'Cuenta'}
        </Link>
        <div
          onClick={() => doSignOut()}
          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
          role='menuitem'>
          {lang === 'en' ? 'Sign out' : 'Desconectar'}
        </div>
      </div>
    </div>
  );
};
