import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Store } from '../redux/store';

export default function Hero() {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div
      className='relative overflow-hidden bg-cover bg-right-bottom bg-no-repeat'
      style={{ backgroundImage: 'url(/media/motivaciones.jpeg)', minHeight: '600px' }}>
      <div className='absolute inset-0 w-full h-full bg-gray-800 bg-opacity-50 z-10'></div>
      <div className='relative max-w-screen-xl mx-auto z-20'>
        <div className='relative pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32'>
          <main className='mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
            <div className='sm:text-center lg:text-left text-white'>
              <h1
                className='font-oswald uppercase text-4xl tracking-tight leading-10 font-bold sm:text-5xl sm:leading-none md:text-6xl'
                style={{ lineHeight: '125%' }}>
                {lang === 'en' ? (
                  <>
                    Do you see your work just as a{' '}
                    <span className='bg-gold'>job</span>, a{' '}
                    <span className='bg-gold'>career</span>, a{' '}
                    <span className='bg-gold'>calling</span> or a{' '}
                    <span className='bg-gold'>higher calling</span>?
                  </>
                ) : (
                  <>
                    ¿Ves tu trabajo como un{' '}
                    <span className='bg-gold'>empleo</span>, una{' '}
                    <span className='bg-gold'>carrera</span>, una{' '}
                    <span className='bg-gold'>vocación</span> o una{' '}
                    <span className='bg-gold'>llamada más alta</span>?
                  </>
                )}
              </h1>

              <div className='mt-[80px] flex items-center justify-between'>

                <p className='font-comforter text-gold text-6xl'>
                  {lang === 'en'
                    ? 'Discover your motivations at work!'
                    : '¡Descubre tus motivaciones en el trabajo!'}
                </p>

                  <div className='rounded-md shadow'>
                    <Link
                      to='/quiz/instructions'
                      className='w-full flex items-center justify-center px-10 rounded-2xl text-black bg-gold hover:bg-emerald 
                    transition duration-150 ease-in-out py-2 md:text-xl md:px-10'
                      style={{ fontFamily: 'Georgia' }}>
                      {lang === 'en' ? 'Take the quiz' : 'Iniciar encuesta'}
                    </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
