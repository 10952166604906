import { Center, Progress, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PrinterIcon } from '@heroicons/react/outline';

import {
  carlos as survey,
  Question,
  Section,
  Survey,
} from '../../data/surveys';
import { Store } from '../../redux/store';
import FirebaseClient from '../../firebase/firebase_client';
import { WorkMotivationResults } from '../../components/results/WorkMotivationResults';
import { WorkMapResults } from '../../components/results/WorkMapResults';
import { WorkMeaningResults } from '../../components/results/WorkMeaningResults';

export const saveQuiz = async (responses) => {
  console.log('Final Responses', responses);
  const response = await FirebaseClient.functions.httpsCallable('saveCarlos')({
    responses: JSON.stringify(responses),
  });
};

// TODO: need a context here for saving information
export const SurveyContext = React.createContext({
  // sectionNumber: 0,
  lang: 'en',
  // toggleTheme: () => {},
});

const AppBar = () => {
  const [showLang, setShowLang] = useState(false);
  // console.log(isMenuOpen);

  const lang = useSelector((state: Store) => state.lang);
  const dispatch = useDispatch();
  return (
    <div
      className='top-0 left-0 w-full h-16'
      style={{
        backgroundColor: '#E5E5E5',
        filter: 'dropShadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        // backdropFilter: 'blur(10px)',
      }}>
      <div className='w-full h-full flex justify-end items-center'>
        {/* Language Selection Outlet */}
        <div
          className='ml-8 inline-flex items-center px-1 pt-1'
          onMouseEnter={() => setShowLang(true)}
          onMouseLeave={() => setShowLang(false)}>
          {lang === 'en' && showLang && (
            <img
              src='/language-en.svg'
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'en' ? 'es' : 'en' },
                });
              }}
            />
          )}
          {lang === 'es' && showLang && (
            <img
              src='/language-es.svg'
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'es' ? 'en' : 'es' },
                });
              }}
            />
          )}
          {!showLang && (
            <img
              className=''
              src={lang === 'en' ? '/english-gray.svg' : '/spanish-gray.svg'}
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'en' ? 'es' : 'en' },
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const SurveyV2 = () => {
  const lang = useSelector((state: Store) => state.lang);

  // useEffect(() => {
  //   wrangle();
  // }, []);

  return (
    <div
      className='absolute top-0 left-0 min-h-full overflow-y-scroll overflow-x-hidden'
      style={{ width: '100vw', minHeight: '100vh' }}>
      <AppBar />

      <div
        className='bg-cover bg-center bg-no-repeat min-h-screen'
        style={{ backgroundImage: 'url("/carlos-bg.jpeg")' }}>
        <div
          className='relative min-h-screen overflow-hidden backdrop-filter backdrop-blur'
          style={{
            background:
              'linear-gradient(180deg, rgba(62, 158, 140, 0.4446) 0%, rgba(77, 64, 48, 0.57) 100%)',
          }}>
          <Helmet>
            <title>Purpose</title>
          </Helmet>
          <div className='p-4'>
            <SurveyContext.Provider value={{ lang: 'en' }}>
              <SectionsStepper survey={survey} sections={survey.sections} />
            </SurveyContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionsStepper = ({
  survey,
  sections,
  ...rest
}: {
  survey: Survey;
  sections: Section[];
  rest?: any;
}) => {
  const surveyContext = useContext(SurveyContext);
  const lang = useSelector((state: Store) => state.lang);

  const [seenInstructions, setSeenInstructions] = useState(false);
  const [sectionNumber, setSectionNumber] = useState(0);
  const [sectionAnswers, saveSectionAnswers] = useState<any>([]);

  const saveSection = (answers) => {
    console.group('SAVE SECTION');
    console.log('sectionAnswers', sectionAnswers);
    console.log('answers', answers);
    console.groupEnd();
    const newSectionAnswers = [...sectionAnswers, answers];
    if (sectionNumber === sections.length - 1) {
      saveQuiz(newSectionAnswers);
    }
    saveSectionAnswers(newSectionAnswers);
    setSectionNumber(sectionNumber + 1);
  };

  const btnText = lang === 'en' ? 'Continue' : 'Siguiente';

  console.group('SECTION');
  console.log('section', sectionNumber, sections[sectionNumber]);
  console.groupEnd();

  if (sectionNumber === sections.length) {
    return <Results responses={sectionAnswers} />;
  }

  return (
    <div className='text-center'>
      <header className='text-center text-shadow space-y-2'>
        <h1
          className='text-gold text-5xl'
          style={{
            fontFamily: 'Georgia',
            textShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px',
          }}>
          {lang === 'en' ? 'Work Motivation' : 'Motivación en el trabajo'}
        </h1>

        {seenInstructions && (
          <>
            <h2
              className='text-4xl text-white italic'
              style={{ fontFamily: 'Georgia' }}>
              {sections[sectionNumber].title[lang]}
            </h2>

            <h3
              className='text-2xl text-white font-bold'
              style={{ fontFamily: 'Georgia' }}>
              {`${lang === 'en' ? 'Section' : 'Sección'} ${sectionNumber + 1}/${
                sections.length
              }`}
            </h3>

            <div className='px-20'>
              <Progress
                colorScheme='green'
                value={(100 * (sectionNumber + 1)) / sections.length}
              />
            </div>
          </>
        )}
      </header>

      {seenInstructions ? (
        <Stepper
          sections={sections}
          sectionNumber={sectionNumber}
          saveSection={saveSection}
        />
      ) : (
        <div className='text-white text-center grid grid-cols-1 place-items-center p-4 space-y-4'>
          <h2 className='text-2xl font-bold'>
            {lang === 'en'
              ? 'Quiz Instructions'
              : 'Instrucciones sobre la encuesta'}
          </h2>
          <p className='max-w-md'>{survey.instructions[lang]}</p>
          <button
            className='flex px-8 py-2 bg-gold text-white rounded-full hover:bg-emerald'
            onClick={() => setSeenInstructions(true)}>
            {btnText}
          </button>
        </div>
      )}
    </div>
  );
};

const Stepper = ({
  sections,
  sectionNumber,
  saveSection,
  ...rest
}: {
  sections: Section[];
  sectionNumber: number;
  saveSection: any;
  rest?: any;
}) => {
  const lang = useSelector((state: Store) => state.lang);

  const section = sections[sectionNumber];
  const { questions, prompt } = section;

  const [showIntro, setShowIntro] = useState(true);

  const handleSaveSection = (answers) => {
    console.group('HANDLE SAVE SECTION');
    console.log(answers);
    console.groupEnd();
    setShowIntro(true);
    saveSection(answers);
  };

  const btnText = lang === 'en' ? 'Continue' : 'Siguiente';

  if (showIntro) {
    return (
      <div className='my-10 space-y-10'>
        <p className='text-2xl text-white'>{section.instructions[lang]}</p>

        {section?.allowSkip && section?.skipInstructions && (
          <p className='text-2xl text-white'>
            {section?.skipInstructions[lang]}
          </p>
        )}

        <Center>
          <button
            className='flex px-8 py-2 bg-gold text-white rounded-full hover:bg-emerald'
            onClick={() => setShowIntro(false)}>
            {btnText}
          </button>
          {section?.allowSkip && (
            <button
              className='ml-2 flex px-8 py-2 bg-white text-gold rounded-full hover:bg-emerald hover:text-white'
              onClick={() =>
                handleSaveSection(Array(questions.length).fill(-1))
              }>
              <span>{lang === 'en' ? 'Skip' : 'Omitir'}</span>
              <svg
                className='flex-shrink-1 h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'></path>
              </svg>
            </button>
          )}
        </Center>
      </div>
    );
  }

  if (sectionNumber === sections.length - 1) {
    return (
      <div className='mt-10 grid grid-cols-1 place-items-center'>
        <Demographics
          questions={questions}
          prompt={prompt}
          saveAnswers={(answers) => handleSaveSection(answers)}
        />
      </div>
    );
  }

  // TODO: results view

  // if (sectionNumber === sections.length) {
  //   return (
  // <div className='mt-10 grid grid-cols-1 place-items-center'>
  //   <h1>
  //     {lang === 'en'
  //       ? 'Thank you for taking the time to complete this questionnaire. We truly value the information you have provided.'
  //       : 'Gracias por tomarse el tiempo para completar esta encuesta. Apreciamos la información que ha proporcionado.”'}
  //   </h1>
  // </div>
  //   );
  // }

  return (
    <div className='mt-10 grid grid-cols-1 place-items-center'>
      <QuestionsStepper
        questions={questions}
        prompt={prompt}
        saveAnswers={(answers) => handleSaveSection(answers)}
      />
    </div>
  );
};

const QuestionsStepper = ({
  questions,
  prompt,
  saveAnswers,
  ...rest
}: {
  questions: Question[];
  prompt: { en: string; es: string };
  saveAnswers: any;
  rest?: any;
}) => {
  const lang = useSelector((state: Store) => state.lang);

  const [questionNumber, setQuestionNumber] = useState(0);

  const [answers, setAnswers] = useState(Array(questions.length).fill(-1));

  const question = questions[questionNumber];
  const { responses } = question;

  const handleGoBack = () =>
    questionNumber > 0 && setQuestionNumber(questionNumber - 1);

  const handleAnswerQuestion = (answer) => {
    console.log('answer', answer);

    const newAnswers = [...answers];
    newAnswers[questionNumber] = answer;

    if (questionNumber < questions.length - 1) {
      setAnswers(newAnswers);
      setTimeout(() => setQuestionNumber(questionNumber + 1), 300);
    } else {
      // save and end the section
      // setAnswers(newAnswers);
      setTimeout(() => saveAnswers(newAnswers), 300);
    }
  };

  console.group('Question Stepper');
  console.log('answers', answers);
  console.groupEnd();

  return (
    <div className='text-center text-white space-y-4'>
      <h2>{`${lang === 'en' ? 'Question' : 'Pregunta'} ${
        questionNumber + 1
      } / ${questions.length}`}</h2>

      <div className=''>
        <Progress
          colorScheme='green'
          value={(100 * (questionNumber + 1)) / questions.length}
        />
      </div>

      <h3 className='text-2xl text-white'>{prompt[lang]}</h3>

      <h3
        className='bg-gold rounded-lg p-1 mt-10 text-center text-2xl'
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.25) 0px -5px 4px inset',
        }}>
        {question[lang]}
      </h3>

      <Center>
        {/* @ts-ignore */}
        <RadioGroup
          onChange={handleAnswerQuestion}
          value={answers[questionNumber]}
          colorScheme='gold'>
          <Stack direction='column'>
            {responses.map((response, i) => {
              return (
                <Radio key={i} value={`${response.value}`}>
                  {response[lang]}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </Center>

      <div className='mt-10' />

      {questionNumber > 0 && (
        <button
          className='flex px-8 py-2 bg-gold text-white rounded-full hover:bg-emerald'
          onClick={handleGoBack}>
          {lang === 'en' ? 'Back' : 'Atrás'}
        </button>
      )}

      <div className='mt-10' />

      {/* FIXME: remove after dev */}
      {/* <Button
        colorScheme='blackAlpha'
        onClick={() => handleSaveAnswers()}
        leftIcon={<ArrowLeftIcon />}>
        Complete Section and Continue
      </Button> */}
    </div>
  );
};

const SEX = [
  { value: 0, en: 'male', es: 'masculino' },
  { value: 1, en: 'female', es: 'femenino' },
];

const AGES = [
  { value: 0, label: '<25' },
  { value: 1, label: '25-34' },
  { value: 2, label: '35-44' },
  { value: 3, label: '45-54' },
  { value: 4, label: '>54' },
];

const WORK_SITUATION = [
  { value: 0, en: 'Student', es: 'Estudiante' },
  { value: 1, en: 'Self employed, freelance', es: 'Autónomo, freelance' },
  { value: 2, en: 'Hourly employees', es: 'Trabajo a tiempo parcial' },
  {
    value: 3,
    en: 'Commission-based workers, sales',
    es: 'Trabajo por comisión, ventas',
  },
  {
    value: 4,
    en: 'Salaried professional and technical workers',
    es: 'Profesional / Trabajador/a técnico',
  },
  {
    value: 5,
    en: 'Middle managers and supervisors',
    es: 'Supervisor/a / Mando intermedio',
  },
  {
    value: 6,
    en: 'Executives and senior managers',
    es: 'Ejecutivo/a / gerente',
  },
];

const YEARS_OF_SERVICE = [
  { value: 0, en: 'Less than 2 years', es: 'Menos de 2 años' },
  { value: 1, en: '2 - 5 years', es: '2 - 5 años' },
  { value: 2, en: '5 - 10 years', es: '5 - 10 años' },
  {
    value: 3,
    en: 'More than 10 years',
    es: 'Más de 10 años',
  },
];

const Demographics = ({
  questions,
  prompt,
  saveAnswers,
  ...rest
}: {
  questions: Question[];
  prompt: { en: string; es: string };
  saveAnswers: any;
  rest?: any;
}) => {
  const lang = useSelector((state: Store) => state.lang);
  const [answers, setAnswers] = useState({
    sex: -1,
    age: -1,
    work: -1,
    years: -1,
    country: '',
  });

  const headers =
    lang === 'en'
      ? {
          sex: 'Sex',
          age: 'Age',
          work: 'Work situation',
          years: 'Years of service in your current organization',
          country: 'Country',
        }
      : {
          sex: 'Sexo',
          age: 'Edad',
          work: 'Situación laboral',
          years: 'Años de servicio en su actual organización',
          country: 'País',
        };

  const handleAnswerQuestion = (question, value) => {
    setAnswers({ ...answers, [question]: value });
  };

  const handleSave = () => {
    saveAnswers(answers);
  };

  console.log('Answers', answers);

  return (
    <div className='grid grid-cols-1 place-items-center text-white text-center'>
      {/* SEX */}
      <h2 className='m-4 border-b-2 border-white'>{headers.sex}</h2>
      {/* @ts-ignore */}
      <RadioGroup
        onChange={(value) => handleAnswerQuestion('sex', value)}
        value={answers.sex}
        colorScheme='green'>
        <Stack direction='row'>
          {SEX.map((response, i) => {
            return (
              <Radio key={i} value={`${response.value}`}>
                {response[lang]}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
      {/* AGES */}
      <h2 className='m-4 border-b-2 border-white'>{headers.age}</h2>
      {/* @ts-ignore */}
      <RadioGroup
        onChange={(value) => handleAnswerQuestion('age', value)}
        value={answers.age}
        colorScheme='green'>
        <Stack direction='row'>
          {AGES.map((response, i) => {
            return (
              <Radio key={i} value={`${response.value}`}>
                {response.label}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
      {/* WORK SITUATION  */}
      <h2 className='m-4 border-b-2 border-white'>{headers.work}</h2>
      {/* @ts-ignore */}
      <RadioGroup
        onChange={(value) => handleAnswerQuestion('work', value)}
        value={answers.work}
        colorScheme='green'>
        <Stack direction='column'>
          {WORK_SITUATION.map((response, i) => {
            return (
              <Radio key={i} value={`${response.value}`}>
                {response[lang]}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
      {/* YEARS OF SERVICE  */}
      <h2 className='m-4 border-b-2 border-white'>{headers.years}</h2>
      {/* @ts-ignore */}
      <RadioGroup
        onChange={(value) => handleAnswerQuestion('years', value)}
        value={answers.years}
        colorScheme='green'>
        <Stack direction='column'>
          {YEARS_OF_SERVICE.map((response, i) => {
            return (
              <Radio key={i} value={`${response.value}`}>
                {response[lang]}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
      {/* COUNTRY  */}
      <div>
        <h2 className='m-4 border-b-2 border-white'>{headers.country}</h2>
        <input
          className='text-black p-2'
          placeholder='Country'
          type='text'
          value={answers.country}
          onChange={(e) => handleAnswerQuestion('country', e.target.value)}
        />
      </div>
      {/*  */}
      <div className='pt-4' />
      <button
        className='flex px-8 py-2 bg-gold text-white rounded-full hover:bg-emerald'
        onClick={() => handleSave()}>
        {lang === 'en' ? 'Save' : 'Grabar'}
      </button>
    </div>
  );
};

const Results = ({ responses }) => {
  const lang = useSelector((state: Store) => state.lang);

  responses[1] = responses[1].map((a) => Number(a));
  responses[2] = responses[2].map((a) => Number(a));

  console.log('Responses', responses);

  return (
    <div className='mt-20 px-10 grid grid-cols-1 place-items-center'>
      <h1 className='text-white text-3xl'>
        {lang === 'en'
          ? 'Thank you for taking the time to complete this questionnaire. We truly value the information you have provided.'
          : 'Gracias por tomarse el tiempo para completar esta encuesta. Apreciamos la información que ha proporcionado.'}
      </h1>

      <div className='h-20' />

      <div className='flex space-x-4'>
        <button className='bg-emerald p-2 rounded-full text-white'>
          <a
            href={`${
              lang === 'en'
                ? 'motivations-understanding-results-v4.pdf'
                : 'motivaciones-entender-resultados-v5.pdf'
            }`}
            download='find-meaningful-work-results.pdf'>
            {lang === 'en'
              ? 'Understanding your results'
              : 'interpreta tus resultados'}
          </a>
        </button>
        <button
          className='flex items-center text-white'
          onClick={() => window.print()}>
          <PrinterIcon className='h-6 w-6 text-white' />
          <span className='ml-2'>Print</span>
        </button>
      </div>

      <div className='h-20' />

      <WorkMotivationResults responses={responses} />
      <div className='h-20' />
      <WorkMapResults responses={responses} />
      <div className='h-20' />
      <WorkMeaningResults responses={responses} />
      <div className='h-20' />
    </div>
  );
};
