import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/store';
import { carlos as survey } from '../../data/surveys';
import { categories } from '../../utils/categories';
import { setColorLevel, setTextColorLevel } from '../../utils/set_color_level';

export const calcMatrixScores = ({ answers1, answers2 }) => {
  // const s = new Set();
  // survey.forEach((a) => s.add(a.category));
  // const a = [];
  // s.forEach((s) => a.push(s));
  // console.log('categories', a);
  console.log(`answer2: ${answers2}`);

  const scores = {};
  categories.forEach((cat) => (scores[cat] = 0));

  answers1.forEach((a, i) => {
    const cat = survey.sections[1].questions[i].meta.category;
    scores[cat] += parseInt(a);
  });
  //Religious questions
  answers2.forEach((a, i) => {
    const cat = survey.sections[2].questions[i].meta.category;
    scores[cat] += parseInt(a);
  });
  console.log('scores', scores);
  return scores;
};

interface Props {
  responses: any[];
}

export const WorkMapResults = (props: Props) => {
  const { responses } = props;
  const lang = useSelector((state: Store) => state.lang);
  // Relational scores
  const scores = calcMatrixScores({
    answers1: responses[1].map((a) => (a < 0 ? 0 : a)),
    answers2: responses[2].map((a) => (a < 0 ? 0 : a)),
  });

  return (
    <div>
      {' '}
      <div
        className='bg-gold py-6 text-center'
        style={{
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <h1
          className='text-4xl'
          style={{
            font: '#6B583F',
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Your results' : 'Tus resultados'}
        </h1>
        <img className='' src='/results-white-dots.svg' alt='' />
        <h2 className='text-white text-2xl'>
          {lang === 'en' ? 'Your work map' : 'Tu mapa de las motivaciones'}
        </h2>
      </div>
      <p className='mt-4 mx-auto max-w-lg text-white text-center'>
        {lang === 'en'
          ? 'Relational matrix of motivations regarding the motives or goals of my motivation and each kind of good that we pursue when you work'
          : 'Matriz que relaciona las distintas motivaciones con los tipos de bienes que buscamos en el trabajo'}
      </p>
      <div className='flex bg-gray-100 m-1 p-2 shadow rounded-lg overflow-hidden overflow-x-auto'>
        <table className='mt-2 min-w-full overflow-x'>
          <thead>
            <tr>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th
                className='text-white rounded-full'
                colSpan={4}
                style={{
                  background: '#5B5B5B',
                }}>
                {lang === 'en' ? 'MOTIVATIONS' : 'MOTIVACIONES'}
              </th>
            </tr>
            <tr>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50  text-xs leading-4 font-medium text-gray-500 text-right'>
                {lang === 'en' ? 'RECEIVE' : 'RECIBIR'}

                <img
                  alt='hand'
                  src='/receive.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'ACHIEVE' : 'LOGRAR'}
                <img
                  alt='fist'
                  src='/achieve.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'GIVE' : 'DAR'}
                {/* Transcendent */}
                <img
                  src='/give.svg'
                  className='hidden md:inline-block w-10 p-1'
                  alt=''
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'GIVE BACK' : 'DEVOLVER'}
                {/* Religious */}
                <img
                  alt=''
                  src='/give-back.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {(lang === 'en'
              ? ['SPIRITUAL', 'MORAL', 'PLEASANT', 'USEFUL']
              : ['ESPIRITUAL', 'MORAL', 'AGRADABLE', 'ÚTIL']
            ).map((rowLabel, i) => {
              //
              const offset = i * 4;
              let rowScores;
              if (scores) {
                rowScores = [0, 1, 2, 3].map((i) =>
                  (scores[categories[offset + i]] / 4).toFixed(1),
                );
              } else {
                if (i === 0) {
                  rowScores = ['Grace', 'Holiness', 'Light', 'Praise'];
                } else if (i === 1) {
                  rowScores = ['Respect', 'Excellence', 'Good', 'Adoration'];
                } else if (i === 2) {
                  rowScores = ['Esteem', 'Satisfaction', 'Joy', 'Gratefulness'];
                } else {
                  rowScores = ['Support', 'Competence', 'Service', 'Fidelity'];
                }
              }

              const icons = [
                'spiritual.svg',
                'moral.svg',
                'pleasant.svg',
                'useful.svg',
              ];

              return (
                <tr className='text-center' key={rowLabel}>
                  {i === 0 && (
                    <td rowSpan={4} className='rounded-2xl whitespace-no-wrap'>
                      <div
                        className='py-20 px-1 rounded-full'
                        style={{
                          background: '#5B5B5B',
                        }}>
                        <p className='p-0 -mx-10 transform -rotate-90 text-white text-xs md:text-sm'>
                          {lang === 'en' ? 'TYPE OF GOOD' : 'TIPO DE BIEN'}
                        </p>
                      </div>
                    </td>
                  )}
                  <td className='flex justify-between items-center px-1 py-1 bg-gray-50 text-sm text-gray-500'>
                    <p className='text-xs md:text-base'>{rowLabel}</p>
                    <img
                      src={`/${icons[i]}`}
                      className='hidden md:inline-block w-10 p-1'
                      alt=''
                    />
                  </td>
                  {rowScores.map((s, i) => {
                    return (
                      <td
                        key={i}
                        className={`px-1 py-2 text-xs md:text-sm ${setTextColorLevel(
                          s,
                        )} text-center
                      ${setColorLevel(s)}`}>
                        {s}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <p className='text-right  mt-2 text-sm text-white'>
        {lang === 'en' ? (
          <span>
            For each motivation there is an intensity that ranges from 0 to 10.
            <br />
            This intensity is the average score. Yours are displayed above.
          </span>
        ) : (
          <span>
            Cada motivación tiene una intensidad que va de 0 a 10. La puntuación{' '}
            <br /> media de intensidad para cada tipo de motivación aparece
            arriba.
          </span>
        )}
      </p>
    </div>
  );
};
