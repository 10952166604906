import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Store } from '../redux/store';

const PAGES = {
  'work-motivation': {
    left: { en: 'Ready to begin', es: 'Quiero hacer la encuesta', to: '/quiz' },
    right: {
      en: 'Map of Motivations',
      es: 'Mapa de motivaciones',
      to: '/quiz/map-of-motivations',
    },
  },
  'map-of-motivations': {
    left: {
      en: 'Work Motivations',
      es: 'Motivación en el trabajo',
      to: '/quiz/work-motivation',
    },
    right: {
      en: 'Work Meanings',
      es: 'Sentidos del trabajo',
      to: '/quiz/work-meanings',
    },
  },
  'work-meanings': {
    left: {
      en: 'Map of Motivations',
      es: 'Mapa de motivaciones',
      to: '/quiz/map-of-motivations',
    },
    right: {
      en: 'Flourishing Domains',
      es: 'Desarrollo humano pleno',
      to: '/quiz/flourishing-domains',
    },
  },
  'flourishing-domains': {
    left: {
      en: 'Work Meanings',
      es: 'Sentidos del trabajo',
      to: '/quiz/work-meanings',
    },
    right: {
      en: 'Work Engagement',
      es: 'Trabajo y compromiso',
      to: '/quiz/work-engagement',
    },
  },
  'work-engagement': {
    left: {
      en: 'Flourishing Domains',
      es: 'Desarrollo humano pleno',
      to: '/quiz/flourishing-domains',
    },
    right: {
      en: 'Ready to begin',
      es: 'Quiero hacer la encuesta',
      to: '/quiz/instructions',
    },
  },
};

export const WorkPageTurner = ({ page }: { page: string }) => {
  const lang = useSelector((state: Store) => state.lang);

  const text = PAGES[page];

  return (
    <div className='mt-2 grid grid-cols-1 md:grid-cols-3 text-white md:text-2xl'>
      <div
        className={`${
          page === 'work-motivation'
            ? 'text-gold'
            : 'text-white hover:text-emerald'
        } relative group  hover:font-bold`}>
        <Link className={`block text-right`} to={text.left.to}>
          <span
            className='absolute bottom-2 right-0 hover:font-bold'
            style={{ fontFamily: 'Georgia' }}>
            {text.left[lang]}
          </span>
          <svg
            className='absolute right-0 bottom-0'
            viewBox='0 0 430 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM430 7L1 7V9L430 9V7Z'
              fill='currentColor'
            />
          </svg>
        </Link>
      </div>
      {/*  */}
      <div className='py-10 md:py-0' />
      {/*  */}
      <div
        className={`${
          page === 'work-engagement'
            ? 'text-gold'
            : 'text-white hover:text-emerald'
        } relative group  hover:font-bold`}>
        <Link className='block text-left' to={text.right.to}>
          <span
            className='absolute bottom-2 left-0'
            style={{ fontFamily: 'Georgia' }}>
            {text.right[lang]}
          </span>
          <svg
            className='absolute left-0 bottom-0 transform rotate-180'
            // width='230'
            // height='16'
            viewBox='0 0 430 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM430 7L1 7V9L430 9V7Z'
              fill='currentColor'
              // fill='#3E9E8C'
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};
