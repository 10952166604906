import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import FirebaseClient from '../firebase/firebase_client';
import SignInPage from '../pages/SignIn';
import { AccountPage } from '../pages/Account';
import { HistoryPage } from '../pages/History';
import { QuizPage } from '../pages/Quiz';
import { QuizInstructionsPage } from '../pages/QuizInstructions';
import { RequestResetPasswordPage } from '../pages/RequestResetPassword';
import { SignUpPage } from '../pages/SignUp';
import { WorkMotivationsPage } from '../pages/WorkMotivationsPage';
import { MapOfMotivationsPage } from '../pages/MapOfMotivationsPage';
import { WorkMeaningsPage } from '../pages/WorkMeaningsPage';
import { FlourishingDomainsPage } from '../pages/FlourishingDomains';
import { QuizSectionOneInstructions } from '../pages/QuizSectionOneInstructions';

import { WorkEngagementPage } from '../pages/WorkEngagement';
import { TermsOfService } from '../pages/TermsOfService';
import { Legal } from '../pages/Legal';
import { SurveyV2 } from '../pages/v2/Survey';
import { ResultsV2 } from '../pages/v2/Results';
import { HomePage } from '../pages/Home';
import { SurveyV2AEDRH } from '../pages/v2AEDRH/Survey';

export class Router extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      console.group('ROUTER ERROR: COMPONENT DID CATCH');
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    // const { account } = this.props;
    console.group('ROUTER');
    // console.log(this.props);
    console.log('Hello world', window.location);
    console.groupEnd();

    if (window.location.host.includes('purpose')) {
      console.log('Redirecting...');
      // 
      if (window.location.host.includes('aedrh')) {
        return (
          <Switch>
            <Route path='/' component={SurveyV2AEDRH} />
          </Switch>
        );
      }
      // 
      return (
        <Switch>
          <Route path='/' component={SurveyV2} />
        </Switch>
      );
    }

    // TODO:

    // TODO: errorboundary
    return (
      <Switch>
        <Route exact path='/' component={HomePage} />

        {/* V2 */}
        <Route exact path='/v2/surveys/purpose' component={SurveyV2} />
        <Route exact path='/v2/surveys/aedrh' component={SurveyV2AEDRH} />

        <Route exact path='/terms' component={TermsOfService} />
        {/* <Route exact path='/about' component={AboutPage} /> */}
        {/* <Route exact path='/book' component={BookPage} /> */}
        <Route exact path='/legal' component={Legal} />
        {/* <Route path='/quiz/:id' component={QuizPage} /> */}
        <Route exact path='/quiz/' component={QuizPage} />
        <Route
          exact
          path='/quiz/sections/work-motivation/instructions'
          component={QuizSectionOneInstructions}
        />
        <Route
          exact
          path='/quiz/sections/work-motivation/questions'
          component={QuizSectionOneInstructions}
        />
        <Route
          exact
          path='/quiz/instructions'
          component={QuizInstructionsPage}
        />
        <Route
          exact
          path='/quiz/flourishing-domains'
          component={FlourishingDomainsPage}
        />
        <Route
          exact
          path='/quiz/work-engagement'
          component={WorkEngagementPage}
        />

        <Route exact path='/quiz/work-meanings' component={WorkMeaningsPage} />
        <Route
          exact
          path='/quiz/work-motivation'
          component={WorkMotivationsPage}
        />
        <Route
          exact
          path='/quiz/map-of-motivations'
          component={MapOfMotivationsPage}
        />

        {/* /about/map-coordinates */}
        {/* <Route exact path='/about/:slug' component={AboutPages} /> */}
        {/* <Route
          exact
          path='/about/map-coordinates'
          component={AboutMapCoordinates}
        /> */}
        {/* <Route exact path='/about/compass' component={AboutCompass} /> */}
        {/* <Route exact path='/about/roadmap' component={AboutRoadmap} /> */}

        {/* Auth Pages */}
        <Route exact path='/sign-in' component={SignInPage} />
        <Route exact path='/sign-up' component={SignUpPage} />
        <Route
          exact
          path='/reset-password'
          component={RequestResetPasswordPage}
        />
        <Route path='/result' component={ResultsV2} />

        {/* <Route path='/resources' component={Resources} /> */}
        {/* <Route path='/admin/surveys' component={ConfigureSurveys} /> */}

        <PrivateRoute>
          {/* Just need a history page for now */}
          <Route exact path='/account' component={AccountPage} />
          {/* <Route path='/results/:id' component={ResultsPage} /> */}
          {/* <Route path='/results/:id' component={ResultsPage} /> */}
          {/* <Route exact path='/results' component={ResultsPage} /> */}
          <Route path='/results/:id' component={ResultsV2} />
          <Route exact path='/history' component={HistoryPage} />
        </PrivateRoute>

        <Route path='/:slug'>
          <Redirect to='/' />
        </Route>
      </Switch>
    );
  }
}

function PrivateRoute({ children, ...rest }: { children: any; rest?: any }) {
  const { currentUser } = FirebaseClient.auth;
  console.log('Current user: ', currentUser);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
