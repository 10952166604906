import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { makeEmptyAnswers } from '../utils/random_answers';

export type Store = {
  responses: {
    survey: {};
    survey2: {};
    workEngagement: [];
    flourishing: {};
    demographics: {};
  };
  lang: 'en' | 'es';
};

const initialState = {
  responses: makeEmptyAnswers({}),
  lang: 'en',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESPONSES':
      console.log('SET RESPONSES', action);
      return { ...state, responses: action.payload };

    case 'SET_LANG':
      console.log('SET LANGUAGE', action);
      return { ...state, lang: action.payload.lang };

    case 'RESET':
      return {
        ...state,
        responses: makeEmptyAnswers({}),
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'motivation',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    // 'sessions',
  ],
};
//@ts-ignore
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  // defaultStore, //preloaded state
  composeWithDevTools(),
);
const persistor = persistStore(store);
export { store, persistor };
