import React from 'react';
import { Link } from 'react-router-dom';

export const QuizSectionOneInstructions = () => {
  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover min-h-screen'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div className='min-h-screen flex flex-col items-center bg-gray-900 bg-opacity-50 p-24'>
          <div className='max-w-2xl'>
            <h1 className='text-gold text-4xl text-center'>Work motivation</h1>
            <h2 className='text-white text-2xl text-center italic'>
              Motivations
            </h2>
            <h2 className='text-white text-2xl text-center'>Section 1/3</h2>
            <div className='mt-10' />
            <p className='text-white text-xl'>
              This Section has 48 questions about your general work motivations
              or what really makes you strive more and work better or harder.
            </p>
            <p className='text-white text-xl'>Press next to start</p>
          </div>
          <div className='mt-10' />
          <div className=''>
            <Link
              className='flex items-center px-8 py-2 bg-gold text-white rounded-full hover:bg-emerald'
              to='/quiz/sections/work-motivation/questions'>
              Next
              <svg
                className='h-5'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </Link>
          </div>

          {/*  */}
        </div>
      </div>
    </div>
  );
};

const Card = ({
  title,
  text,
  icon,
}: {
  title: string;
  text: string;
  icon: JSX.Element;
}) => {
  return (
    <div className='flex flex-col overflow-hidden'>
      <div className=''>
        <div className={`flex flex-col items-center px-0 py-0 sm:px-0 `}>
          {/* <div className={`${bg} flex flex-col items-center px-2 py-2 sm:px-3 `}> */}
          <div className='mt-4' />
          <div className='bg-gold rounded-full p-4 shadow-xl overflow-hidden'>
            {icon}
          </div>
          <div className='mt-3' />
          <h3 className='text-xl text-emerald text-center'>{title}</h3>
        </div>
      </div>
      <div className='text-base text-center text-white flex-grow'>
        <div className='px-4 py-5 sm:p-6'>{text}</div>
      </div>
    </div>
  );
};
