import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Store } from '../redux/store';

export default function CTA() {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <h2
          className='text-3xl font-extrabold tracking-tight text-black'
          style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          {lang === 'en' ? (
            <span className='block'>
              To
              <span className='text-emerald'> learn more </span>
              how to interpret
              <span className='text-emerald'> your results </span>
            </span>
          ) : (
            <span className='block'>
              Para
              <span className='text-emerald'> saber más </span>
              sobre cómo interpretar
              <span className='text-emerald'> tus resultados </span>
            </span>
          )}
        </h2>
        <div className='mt-8 flex justify-center'>
          <div
            className='inline-flex rounded-full'
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <Link
              to='/about/map-coordinates'
              className='inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl shadow text-black hover:text-white bg-gold hover:bg-emerald'
              style={{ fontFamily: 'Georgia' }}>
              {lang === 'en' ? 'Check out here' : 'Pincha aquí'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
