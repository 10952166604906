import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FirebaseClient from '../firebase/firebase_client';
import { Store } from '../redux/store';

export const HistoryPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  const { currentUser } = FirebaseClient.auth;
  const [quizzes, setQuizzes] = useState<any>([]);

  useEffect(() => {
    if (currentUser) {
      FirebaseClient.db
        .collection('users')
        .doc(currentUser.uid)
        .collection('quizzes')
        .get()
        .then((snapshot) => {
          console.log('Results', snapshot);
          let q = [];
          snapshot.forEach((doc) => {
            //@ts-ignore
            q.push({ id: doc.id, ...doc.data() });
          });
          //sort by date, recent to oldest
          //@ts-ignore
          q = q.sort((a, b) => b.createdAt - a.createdAt);
          console.log(q);
          setQuizzes(q);
        });
    }
  }, [currentUser]);

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <main className='mt-4 max-w-3xl mx-auto'>
        <h1
          className='py-14 bg-emerald text-white text-center text-4xl rounded-xl'
          style={{
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Completed Quizzes' : 'Encuestas completadas'}
        </h1>
        <ul className='mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 text-white'>
          {quizzes.map((quiz) => {
            const createdAt = new Date(quiz.createdAt);
            const day = createdAt.getDay();
            const month = createdAt.getMonth();
            const date = createdAt.getDate();
            const year = createdAt.getFullYear();
            return (
              <Link
                className='block  p-2 my-2 bg-brown text-center rounded-xl'
                key={quiz.createdAt}
                to={`/results/${quiz.id}`}>
                <dl>
                  <dt className='sr-only capitalize'>Day of the week</dt>
                  <dd className='capitalize'>
                    {lang === 'en' ? DaysEN[day] : DaysES[day]}
                  </dd>
                </dl>
                <h2 className='capitalize'>{`${
                  lang === 'en' ? MonthsEN[month] : MonthsES[month]
                } ${date} ${year}`}</h2>
              </Link>
            );
          })}
        </ul>
      </main>
    </div>
  );
};

const DaysEN = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const MonthsEN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MonthsES = [
  'enero',
  'feb',
  'marzo',
  'abr',
  'mayo',
  'jun',
  'jul',
  'agosto',
  'set',
  'oct',
  'nov',
  'dic',
];

const DaysES = [
  'domingo',
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado',
];
