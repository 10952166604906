import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { App } from './App';
import './styles/index.css';

const theme = extendTheme({
  colors: {
    gold: '#E5A758',
    emerald: '#3E9E8C',
    brown: '#6B583F',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
