import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FirebaseClient from '../../firebase/firebase_client';
import { Store } from '../../redux/store';
import { calcMatrixScores } from '../../utils/calc_matrix_scores';
import { calcMotivationGoodScores } from '../../utils/calc_motivation_good_scores';
import { Answers, makeEmptyAnswers } from '../../utils/random_answers';
import { toPng } from 'html-to-image';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';

const boxText = {
  en: [
    ['Voluntary desire to receive', 'the spiritual good', 'Gift/Present/Grace'],
    [
      'Voluntary desire to achieve',
      'the spiritual good',
      'Sanctity/Blessedness/Perfection',
    ],
    [
      'Voluntary desire to give',
      'the spiritual good',
      'Sanctity/Blessedness/Holiness',
    ],
    [
      'Voluntary desire to return',
      'the spiritual good',
      'Praise/Acclaimation/Glory',
    ],
    [
      'Voluntary desire to receive',
      'the moral good',
      'Reputation/Admiration/Respect',
    ],
    [
      'Voluntary desire to achieve',
      'the moral good',
      'Virtuousness/Excellence/Goodness',
    ],
    [
      'Voluntary desire to give',
      'the moral good',
      'Gentleness/Understanding/Good',
    ],
    [
      'Voluntary desire to return',
      'the moral good',
      'Reverence/Adoration/Workship',
    ],
    [
      'Voluntary desire to receive',
      'the spiritual good',
      'Relatedness/Affiliation/Esteem',
    ],
    [
      'Voluntary desire to achieve',
      'the spiritual good',
      'Self-actualization/Growth/Self-esteem',
    ],
    [
      'Voluntary desire to give',
      'the spiritual good',
      'Compassion/Kindness/Care',
    ],
    [
      'Voluntary desire to return',
      'the spiritual good',
      'Gratitude/Thanks/Appreciation',
    ],
    [
      'Voluntary desire to receive',
      'the useful good',
      'Sustenance/Safety/Support',
    ],
    [
      'Voluntary desire to achieve',
      'the useful good',
      'Competency/Mastery/Success',
    ],
    ['Voluntary desire to give', 'the useful good', 'Assistance/Help/Service'],
    [
      'Voluntary desire to return',
      'the useful good',
      'Obedience/Assent/Fidelity',
    ],
  ],
  es: [
    ['Deseo voluntario de recibir', 'el bien espiritual', 'Don/Regalo/Gracia'],
    [
      'Deseo voluntario de lograr',
      'el bien espiritual',
      'Santidad/Beatitud/Perfección',
    ],
    [
      'Deseo voluntario de dar',
      'el bien espiritual',
      'Santificación/Salvación/Bendición',
    ],
    [
      'Deseo voluntario de devolver',
      'el bien espiritual',
      'Alabanza/Aclamacióno/Gloria',
    ],
    [
      'Deseo voluntario de recibir',
      'el bien moral',
      'Reputación/Admiración/Respeto',
    ],
    ['Deseo voluntario de lograr', 'el bien moral', 'Virtud/Excelencia/Bondad'],
    ['Deseo voluntario de dar', 'el bien moral', 'Amabilidad/Comprensión/Bien'],
    [
      'Deseo voluntario de devolver',
      'el bien moral',
      'Reverencia/Adoración/Culto',
    ],
    [
      'Deseo voluntario de recibir',
      'el bien agradable',
      'Relación/Afiliación/Estima',
    ],
    [
      'Deseo voluntario de lograr',
      'el bien agradable',
      'Auto-realización/Auto-estima/Satisfacción',
    ],
    ['Deseo voluntario de dar', 'el bien agradable', 'Compasión/Afecto/Cariño'],
    [
      'Deseo voluntario de devolver',
      'el bien agradable',
      'Gratitud/Agradecimiento/Aprecio',
    ],
    [
      'Deseo voluntario de recibir',
      'el bien útil',
      'Sostenimiento/Seguridad/Apoyo',
    ],
    [
      'Deseo voluntario de lograr',
      'el bien útil',
      'Competencia/Habilidad/Éxito',
    ],
    ['Deseo voluntario de dar', 'el bien útil', 'Asistencia/Ayuda/Servicio'],
    [
      'Deseo voluntario de devolver',
      'el bien útil',
      'Obediencia/Asentimiento/Fidelidad',
    ],
  ],
};

const mapMatrixScoresToBox = (index) =>
  ({
    4: 'ExtrinsicMoral',
    8: 'ExtrinsicPleasant',
    0: 'ExtrinsicSpiritual',
    12: 'ExtrinsicUseful',
    5: 'IntrinsicMoral',
    9: 'IntrinsicPleasant',
    1: 'IntrinsicSpiritual',
    13: 'IntrinsicUseful',
    7: 'ReligiousMoral',
    11: 'ReligiousPleasant',
    3: 'ReligiousSpiritual',
    15: 'ReligiousUseful',
    6: 'TranscendentMoral',
    10: 'TranscendentPleasant',
    2: 'TranscendentSpiritual',
    14: 'TranscendentUseful',
  }[index]);

const bgColor = (i) => {
  if ([0, 1, 2, 3, 7, 11, 15].includes(i)) {
    return 'bg-ieco-2 bg-opacity-20';
  }
  if ([4, 5, 6, 10, 14].includes(i)) {
    return 'bg-ieco-2 bg-opacity-40';
  }
  if ([8, 9, 13].includes(i)) {
    return 'bg-ieco-2 bg-opacity-60';
  }
  return 'bg-ieco-2 bg-opacity-80';
};

const textColor = (i) => {
  if ([0, 1, 2, 3, 7, 11, 15].includes(i)) {
    return 'text-ieco-2';
  }
  return 'text-white';
};

const FourTypeLabels = [
  {
    key: 'calling',
    en: 'higher calling',
    es: 'llamada más alta',
  },
  {
    key: 'vocation',
    en: 'calling',
    es: 'vocación',
  },
  {
    key: 'career',
    en: 'career',
    es: 'carrera',
  },
  {
    key: 'work',
    en: 'job',
    es: 'empleo',
  },
];

const ScoreCircle = ({ score, bgColor }) => {
  return (
    <div
      className={`relative w-16 h-16 rounded-full grid grid-cols-1 place-items-center border-ieco-2 border-2 overflow-hidden ${bgColor}`}
    >
      <div
        className={`absolute top-0 w-16 bg-white`}
        style={{ height: `${64 - 6.4 * score}px` }}
      />
      <div className='absolute bg-gray-200 h-full w-full opacity-50' />
      <div className='z-10 text-gold text-xl font-bold font-oswald'>
        {Math.round(score)}
      </div>
    </div>
  );
};

// IDEA: to a fixed sized inner grid and then add overlays on top of it with absolute positioning
const ResultsGrid = ({ answers, page }) => {
  const lang = useSelector((state: Store) => state.lang);
  console.log('answers', answers);

  const sectionScores = {
    calling: (
      [0, 1, 2, 3, 7, 11, 15].reduce((s, i) => (s += answers[i]), 0) / 7
    ).toFixed(0),
    vocation: (
      [4, 5, 6, 10, 14].reduce((s, i) => (s += answers[i]), 0) / 5
    ).toFixed(0),
    career: ([8, 9, 13].reduce((s, i) => (s += answers[i]), 0) / 3).toFixed(0),
    work: [12].reduce((s, i) => (s += answers[i]), 0).toFixed(0),
  };

  const totalScore = answers.reduce((s, _, i) => (s += answers[i]), 0);

  const sectionPercents = {
    calling:
      [0, 1, 2, 3, 7, 11, 15].reduce((s, i) => (s += answers[i]), 0) /
      totalScore,
    vocation:
      [4, 5, 6, 10, 14].reduce((s, i) => (s += answers[i]), 0) / totalScore,
    career: [8, 9, 13].reduce((s, i) => (s += answers[i]), 0) / totalScore,
    work: [12].reduce((s, i) => (s += answers[i]), 0) / totalScore,
  };

  return (
    <div className='p-10 grid grid-cols-6 grid-rows60 overflowhidden'>
      {/* Column Header */}
      <div className='col-start-3 col-span-4 text-center grid grid-cols-1 place-items-center'>
        <h2 className='text-3xl text-center font-oswald font-bold text-gold border-b-2 border-gray-200 px-10 mb-4'>
          {lang === 'en' ? 'Types of motivation' : 'tipos de motivacion'}
        </h2>
      </div>
      {/* Row Header*/}
      <div className='row-start-2 w-10 col-span-1 row-span-5 text-center flex items-center'>
        <h2 className='-rotate-90 whitespace-nowrap text-3xl text-center font-oswald font-bold text-gold border-b-2 border-gray-200 px-10 mr-4'>
          {lang === 'en' ? 'Types of good' : 'tipos de bien'}
        </h2>
      </div>
      {/* Top Header*/}
      <div className='row-start-2 col-start-3 col-span-5 row-span-1 grid grid-cols-4 text-center place-items-center'>
        {{
          en: [
            ['Receiving', 'Extrinsic'],
            ['Achieving', 'Intrinsic'],
            ['Giving', 'Transcendent'],
            ['Returning', 'Religious'],
          ],
          es: [
            ['Recibir', 'Extrínseca'],
            ['Lograr', 'Intrínseca'],
            ['Dar', 'Transitiva'],
            ['Devolver', 'Religiosa'],
          ],
        }[lang].map((row, i) => (
          <div key={i} className='p-2 flex flex-col items-center'>
            <img src={`/media/IECO${i + 1}.jpg`} className='w-14 h-14' alt='' />
            <h2 className='uppercase text-center text-ieco-2 font-bold'>
              {row[0]}
            </h2>
            <h3 className='text-center'>{row[1]}</h3>
          </div>
        ))}
      </div>
      {/* Side Headers */}
      <div className='row-start-3 col-start-2 col-span-1 row-span-4 grid grid-cols-1 text-center place-items-center'>
        {{
          en: [
            ['Spiritual', 'Higher Spiritual Realm'],
            ['Moral', 'Ethical Realm'],
            ['Pleasant', 'Psychological Realm'],
            ['Useful', 'Physical Realm'],
          ],
          es: [
            ['Espiritual', 'Nivel sobrenatural'],
            ['Moral', 'Nivel ético'],
            ['Agradable', 'Nivel Psicológico'],
            ['Útil', 'Nivel físico'],
          ],
        }[lang].map((row, i) => (
          <div key={i} className='p-2 flex flex-col items-center'>
            <h2 className='uppercase text-center text-ieco-2 font-bold'>
              {row[0]}
            </h2>
            <h3 className='text-center'>{row[1]}</h3>
            <img src={`/media/IECO${i + 5}.jpg`} className='w-14 h-14' alt='' />
          </div>
        ))}
      </div>

      {/* Inner Matrix */}
      <div className='relative row-start-3 col-start-3 col-span-4 row-span-40 grid grid-cols-4 place-items-center'>
        {/* Overlay */}
        {/* TODO: will need to have well defined dimensions for this to work */}
        {page === 1 && (
          <div className='absolute -bottom10 w-full h-full z-10' style={{}}>
            {/* <img
              className='object-cover'
              style={{ height: '100%', width: '100%' }}
              src='/media/arrows.svg'
              alt=''
            /> */}
          </div>
        )}
        {page === 2 && (
          <div
            className='absolute left-20 -top-5 w-full h-full z-10 grid grid-cols-4 grid-rows-4 text-white font-oswald'
            style={{}}
          >
            {FourTypeLabels.map((part, i) => (
              <div
                key={i}
                className={`relative p-2 col-start-${4 - i} row-start-${
                  i + 1
                } col-span-1 flex justify-between items-center bg-ieco-2 h-16`}
              >
                <div className='text-xl'>{sectionScores[part['key']]}/10</div>
                <div>
                  <h2 className=''>
                    {lang === 'en' ? 'The work like' : 'el trabajo como'}
                  </h2>
                  <h1 className='uppercase font-bold'>{part[lang]}</h1>
                </div>
                <img
                  className='absolute -right-5 -bottom-5 p-2 w-10 h-10 bg-gold rounded-full'
                  alt=''
                  src={`/media/IECO${12 - i}.svg`}
                />
              </div>
            ))}
          </div>
        )}
        {page === 3 && (
          <div
            className='absolute left-20 -top-5 w-full h-full z-20 grid grid-cols-4 grid-rows-4 text-white font-oswald'
            style={{ backgroundImage: 'url("/media/Flecha100.png")' }}
          >
            <img
              className='absolute -top-10 -left-10 h-full w-full object-contain object-center'
              alt=''
              src='/media/Flecha100.png'
            />
            {FourTypeLabels.map((part, i) => (
              <div
                key={i}
                className={`relative p-2 col-start-${4 - i} row-start-${
                  i + 1
                } col-span-1 flex justify-between items-center bg-ieco-2 h-16`}
              >
                <div className='text-xl'>
                  {(100 * sectionPercents[part['key']]).toFixed(0)}%
                </div>
                <div>
                  <h2 className=''>
                    {lang === 'en' ? 'The work like' : 'el trabajo como'}
                  </h2>
                  <h1 className='uppercase font-bold'>{part[lang]}</h1>
                </div>
                <img
                  className='absolute -right-5 -bottom-5 p-2 w-10 h-10 bg-gold rounded-full'
                  alt=''
                  src={`/media/IECO${12 - i}.svg`}
                />
              </div>
            ))}
            {/*  */}
          </div>
        )}
        {page === 4 && (
          <div
            className='absolute left-0 top-0 w-full h-full z-20  text-white font-oswald'
            style={{ backgroundImage: 'url("/media/Flecha100.png")' }}
          >
            {/*  */}
            <div className='absolute top-0 left-0 w-full h-full overflow-hidden'>
              <img
                className='object-center w-[80%] h-full'
                alt=''
                src='/media/arrow-4.svg'
              />
            </div>
            {/*  */}
            <div className='absolute grid grid-cols-4 grid-rows-4 h-full w-full text-xl text-bold'>
              <div className='col-start-1 row-start-1 text-ieco-2'>
                {lang === 'en' ? 'Spiritual Growth' : 'Crecimiento espiritual'}
              </div>
              <div className='relative col-start-4 row-start-1 text-ieco-2'>
                <span className='absolute bottom-0 right-0'>
                  {lang === 'en' ? 'Love for the Other' : 'Amor a Dios'}
                </span>
              </div>
              {/*  */}
              <div className='relative col-start-1 row-start-2 text-ieco-2'>
                {lang === 'en' ? 'Moral Growth' : 'Crecimiento Moral'}
              </div>
              <div className='relative col-start-3 row-start-2 text-ieco-2'>
                <span className='absolute bottom-0 right-0'>
                  {lang === 'en' ? 'Love for Others' : 'Amor a los demás'}
                </span>
              </div>
              {/*  */}
              <div className='col-start-1 row-start-3 text-ieco-2'>
                {lang === 'en'
                  ? 'Psychological Growth'
                  : 'Crecimiento psicológico'}
              </div>
              <div className='relative col-start-2 row-start-3 text-ieco-2'>
                <span className='absolute bottom-0 right-0 text-white'>
                  {lang === 'en' ? 'Love for Ourselves' : 'Amor hacia nosotros'}
                </span>
              </div>
              {/*  */}
              <div className='relative col-start-1 row-start-4 text-ieco-2'>
                <span className='absolute bottom-0 right-0 text-white'>
                  {lang === 'en' ? 'Love from Others' : 'Amor de los demás'}
                </span>
              </div>
              {/*  */}
            </div>
            {/*  */}
            <div className='absolute left-20 -top-5 w-full h-full grid grid-cols-4 grid-rows-4'>
              {FourTypeLabels.map((part, i) => (
                <div
                  key={i}
                  className={`relative p-2 col-start-${4 - i} row-start-${
                    i + 1
                  } col-span-1 flex justify-between items-center bg-ieco-2 h-16`}
                >
                  <div className='text-xl'>
                    {(100 * sectionPercents[part['key']]).toFixed(0)}%
                  </div>

                  <div>
                    <h2 className=''>
                      {lang === 'en' ? 'The work like' : 'el trabajo como'}
                    </h2>
                    <h1 className='uppercase font-bold'>{part[lang]}</h1>
                  </div>
                  <img
                    className='absolute -right-5 -bottom-5 p-2 w-10 h-10 bg-gold rounded-full'
                    alt=''
                    src={`/media/IECO${12 - i}.svg`}
                  />
                </div>
              ))}
            </div>
            {/*  */}
          </div>
        )}

        {answers.map((answer, i) => {
          return (
            <div
              key={i}
              className={`${bgColor(i)} ${textColor(
                i
              )} w-full h-full flex flex-col items-center text-center p-3 space-y-2`}
            >
              {page < 4 && (
                <>
                  <h2 className='font-oswald'>{boxText[lang][i][0]}</h2>
                  <ScoreCircle score={answer} bgColor={bgColor(i)} key={i} />
                  <h1 className={`font-bold`}>{boxText[lang][i][1]}</h1>
                  <h3 className='text-sm font-open-sans'>
                    {boxText[lang][i][2]}
                  </h3>
                </>
              )}
              {page === 4 && (
                <>
                  <h2 className='invisible font-oswald'>
                    {boxText[lang][i][0]}
                  </h2>
                  <ScoreCircle score={answer} bgColor={bgColor(i)} key={i} />
                  <h1 className={`invisible font-bold`}>
                    {boxText[lang][i][1]}
                  </h1>
                  <h3 className='invisible font-open-sans'>
                    {boxText[lang][i][2]}
                  </h3>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Legend = () => {
  const lang = useSelector((state: Store) => state.lang);
  const text = {
    en: [
      ['Job', 'Work as a way of obtaining resources'],
      [
        'Career',
        'Work as a way to achieve competence, success, and social position',
      ],
      [
        'Calling',
        'Work as a way to grow personally, serve, and contribute to a purpose',
      ],
      ['Higher Calling', 'Work as a way of responding to a higher mission'],
    ],
    es: [
      ['EMPLEO', 'EL TRABAJO COMO FORMA DE OBTENER RECURSOS'],
      [
        'Carrera',
        'El trabajo como medio para alcanzar la competencia, el éxito y la posición social',
      ],
      [
        'Vocación',
        'El trabajo como una forma de crecer personalmente, servir y contribuir a un propósito.',
      ],
      [
        'Llamada más alta',
        'El trabajo como forma de responder a una misión más elevada',
      ],
    ],
  };
  const ways = text[lang];

  return (
    <div className='md:space-y-8 md:block md:mt-10'>
      {ways.map((way, i) => (
        <div key={i}>
          <img
            className='p-2 w-10 h-10 bg-gold rounded-full'
            alt=''
            src={`/media/IECO${9 + i}.svg`}
          />
          <h2 className='font-bold'>{way[0]}</h2>
          <h3 className='normal-case text-sm font-normal'>{way[1]}</h3>
        </div>
      ))}
    </div>
  );
};

export const ResultsV2 = () => {
  const history = useHistory();
  const params = useParams() as { id: string | null };
  const { id } = params;
  console.log('Params', id);

  const page1Ref = useRef<any>();
  const page2Ref = useRef<any>();
  const page3Ref = useRef<any>();
  const page4Ref = useRef<any>();

  const { currentUser } = FirebaseClient.auth;
  const lang = useSelector((state: Store) => state.lang);

  const storeResponses = useSelector((store: Store) => store.responses);

  const [historyResponses, setHistoryResponses] = useState<Answers>(
    makeEmptyAnswers({})
  );

  const responses = id ? historyResponses : storeResponses;
  console.log('Responses', responses);

  const { workEngagement, flourishing } = responses;
  let flourishingAverage;
  if (!!flourishing) {
    flourishingAverage =
      (flourishing as []).reduce((a, b) => a + b, 0) /
      (flourishing as []).length;
  }

  const mainScores = calcMotivationGoodScores({
    answers1: responses.survey,
    answers2: responses.survey2,
  });

  const matrixScores = calcMatrixScores({
    answers1: responses.survey,
    answers2: responses.survey2,
  });

  const answers = new Array(16)
    .fill(0)
    .map((_, i) => matrixScores[mapMatrixScoresToBox(i)] / 4);

  // TODO: use react-query
  //only fetch if we have params
  useEffect(() => {
    console.log('Params', id);
    //Only fetch once
    if (currentUser && id) {
      FirebaseClient.db
        .collection('users')
        .doc(currentUser.uid)
        .collection('quizzes')
        .doc(id)
        .get()
        .then((snapshot) => {
          console.log('Result to show', snapshot);
          let answers = {
            id: snapshot.id,
            ...snapshot.data(),
          };
          console.log('Answers to show', answers);
          //@ts-ignore
          setHistoryResponses(answers);
        });
    }
  }, [id, currentUser]);

  // TODO: create and show PNG of results
  useEffect(() => {
    if (page1Ref.current === null) {
      return;
    }
    toPng(page1Ref.current, {
      cacheBust: true,
      width: page1Ref.current.width,
      height: page1Ref.current.height,
      backgroundColor: 'white',
    })
      .then((dataUrl) => {
        // const link = document.createElement('a');
        // link.download = `yolo.png`;
        // link.href = dataUrl;
        // link.click();
        let img = document.getElementById('page-1-image');
        // @ts-ignore
        img.src = dataUrl;
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page1Ref]);

  const handleSaveResults = () => {
    if (page1Ref.current === null) {
      console.warn('No page 1 ref');
      return;
    }
    console.log('Saving results');
    toPng(page1Ref.current, {
      cacheBust: true,
      width: page1Ref.current.width,
      height: page1Ref.current.height,
      backgroundColor: 'white',
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `results.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (page2Ref.current === null) {
  //     return;
  //   }
  //   toPng(page2Ref.current, {
  //     cacheBust: true,
  //     width: page2Ref.current.width,
  //     height: page2Ref.current.height,
  //     backgroundColor: 'white',
  //   })
  //     .then((dataUrl) => {
  //       // const link = document.createElement('a');
  //       // link.download = `yolo.png`;
  //       // link.href = dataUrl;
  //       // link.click();
  //       let img = document.getElementById('page-2-image');
  //       // @ts-ignore
  //       img.src = dataUrl;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [page2Ref]);

  return (
    <div className='relative'>
      {/* Hide the below tree off page */}
      {/* <div className='relative overflow-hidden'>
        <div className='absolute overflow-hidden' style={{ width: '2500px' }}>
          <h2>Result Blocks for Images</h2>

          <div id='page-1' ref={page1Ref} className=''>
            <ResultsGrid answers={answers} page={1} />
          </div>

          <div
            id='page-2'
            ref={page2Ref}
            className=''
            style={{ padding: '100px' }}
          >
            <ResultsGrid answers={answers} page={2} />
          </div>
        </div>
      </div> */}
      <button className='p-4 bg-grey-200 w-full' onClick={handleSaveResults}>Save Result</button>

      {/* <img
        id='page-1-image'
        className='object-center object-scale-down'
        alt=''
        src=''
      /> */}
      <div ref={page1Ref} style={{ width: 2500 }}>
        <div
          className='relative bg-white p-10 overflow-x-auto'
          // style={{ width: 2000 }}
        >
          {/* ------------------------------------------------------------------ */}
          {/* PAGE 1 */}
          {/* ------------------------------------------------------------------ */}
          <section
            className='p-4 flex overflow-xauto bgcontain bg-left-top bg-no-repeat'
            style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
          >
            {/*  */}
            <div className='shrink-1 block md:flex uppercase font-oswald font-bold text-ieco-2'>
              <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2'>
                <h1 className='text-4xl font-bold'>
                  {lang === 'en'
                    ? 'Your Work Motivations'
                    : 'Tus motivaciones en el trabajo'}
                </h1>
                <h2 className='font-opens-sans text-xl'>
                  {lang === 'en'
                    ? 'The Map Coordinates'
                    : 'Las coordenadas del mapa'}
                </h2>
                <div className='mt-8' />
                <ScoreCircle score={5} bgColor={bgColor(2)} />
                <p>
                  {lang === 'en'
                    ? 'Score is out of 10'
                    : 'La puntuación es de 10'}
                </p>
              </div>
            </div>
            <div className='shrink-0'>
              <ResultsGrid answers={answers} page={2} />
            </div>
            {/*  */}
            {/* <div className='w-full min-h-screen' style={{  }}>
            <img
              id='page-1-image'
              className='object-center object-scale-down'
              alt=''
              src=''
            />
          </div> */}
          </section>
          {/* PAGE 2 */}
          <section
            className='p-4 flex overflow-xauto bg-left-top bg-no-repeat'
            style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
          >
            {/*  */}
            <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2'>
              <h1 className='text-4xl font-bold'>
                {lang === 'en'
                  ? 'Your Work Meanings'
                  : 'Tus sentidos del trabajo'}
              </h1>
              <h2 className='font-opens-sans text-xl'>
                {lang === 'en' ? 'The Compass' : 'la brújula'}
              </h2>
              <Legend />
            </div>
            {/*  */}
            {/* <div className='flex-none' style={{ width: '80vw' }}>
            <img
              id='page-2-image'
              className='object-center object-contain'
              alt=''
              src=''
            />
          </div> */}
            {/*  */}
            <div className='shrink-0'>
              <ResultsGrid answers={answers} page={2} />
            </div>
          </section>
          {/* PAGE 3 */}
          <section
            className='p-4 flex overflow-xscroll bg-left-top bg-no-repeat'
            style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
          >
            {/*  */}
            <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2'>
              <h1 className='text-4xl font-bold'>
                {lang === 'en'
                  ? 'Your Work Meanings'
                  : 'Tus significados laborales'}
              </h1>
              <h2 className='font-opens-sans text-xl'>
                {lang === 'en' ? 'The Compass' : 'la brújula'}
              </h2>
              <Legend />
            </div>
            {/*  */}
            <div className='shrink-0'>
              <ResultsGrid answers={answers} page={3} />
            </div>
          </section>
          {/* PAGE 4 */}
          <section
            className='p-4 flex overflow-xscroll bg-left-top bg-no-repeat'
            style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
          >
            {/*  */}
            <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2'>
              <h1 className='text-4xl font-bold'>
                {lang === 'en'
                  ? 'Growing in Love at Work'
                  : 'Creciendo en amor en el trabajo'}
              </h1>
              <h2 className='font-opens-sans text-xl'>
                {lang === 'en' ? 'The Roadmap' : 'La hoja de ruta'}
              </h2>
              <Legend />
            </div>
            {/*  */}
            <div className='shrink-0'>
              <ResultsGrid answers={answers} page={4} />
            </div>
          </section>

          {/* PAGE Work Engagement */}
          {!!workEngagement && (
            <section
              className='p-4 flex overflow-xscroll bg-left-top bg-no-repeat'
              style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
            >
              {/*  */}
              <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2 max-w-md'>
                <h1 className='text-4xl font-bold'>
                  {lang === 'en'
                    ? 'Work Engagement'
                    : 'Creciendo en amor en el trabajo'}
                </h1>
                <h2 className='font-opens-sans text-xl'>
                  {lang === 'en' ? 'Score' : 'La hoja de ruta'}
                </h2>
                <div className='md:space-y-8 md:block md:mt-10'>
                  Engagement is a positive, fullfilling, work-related state of
                  mind that rather than momentary and specific state, it refers
                  to a more persistent and pervasive afective-cognitive state.
                </div>
                <div className='md:space-y-8 md:block md:mt-10'>
                  {[
                    {
                      title: { en: 'Vigor', es: '' },
                      body: {
                        en: 'High levels of energy and mental resilience while working. Persistence even in the face of difficulties.',
                        es: '',
                      },
                    },
                    {
                      title: { en: 'Vigor', es: '' },
                      body: {
                        en: "Strongly involved in one's work. Sense of significance, enthusiasm, inspiration, pride and challenge.",
                        es: '',
                      },
                    },
                    {
                      title: { en: 'Vigor', es: '' },
                      body: {
                        en: "Fully concentrated and happily engrossed in one's work. Time passes quickly. Difficulties with detaching oneself from work.",
                        es: '',
                      },
                    },
                  ].map((way, i) => (
                    <div key={i}>
                      <h2 className='font-bold'>{way.title[lang]}</h2>
                      <h3 className='normal-case text-sm font-normal'>
                        {way.body[lang]}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
              <div className='shrink-0'>
                <BarChart
                  width={400}
                  height={600}
                  data={[
                    {
                      pv: workEngagement[0],
                      amt: workEngagement[0],
                      name: lang === 'en' ? 'Vigor' : 'Vigor',
                    },
                    {
                      pv2: workEngagement[1],
                      amt: workEngagement[1],
                      name: lang === 'en' ? 'Dedication' : 'Dedicación',
                    },
                    {
                      pv3: workEngagement[2],
                      amt: workEngagement[2],
                      name: lang === 'en' ? 'Absorption' : 'Absorción',
                    },
                  ]}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Bar dataKey='amt' fill='#8884d8' label={{ position: 'top' }}>
                    {['#ef9b5c', '#A43539', '#33333380'].map((color, index) => (
                      <Cell
                        cursor='pointer'
                        fill={color}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Bar>
                  <XAxis dataKey='name' />
                  <YAxis domain={[0, 6]} />
                </BarChart>
              </div>
            </section>
          )}
          {/* PAGE Flourishing */}
          {!!flourishing && (
            <section
              className='p-4 flex overflow-xscroll bg-left-top bg-no-repeat'
              style={{ backgroundImage: 'url("/media/flecha-gris-fondo.png")' }}
            >
              {/*  */}
              <div className='shrink-1 uppercase font-oswald font-bold text-ieco-2 max-w-md'>
                <h1 className='text-4xl font-bold'>
                  {lang === 'en'
                    ? 'Human Flourishing'
                    : 'Creciendo en amor en el trabajo'}
                </h1>
                <h2 className='font-opens-sans text-xl'>
                  {lang === 'en' ? 'Results' : 'La hoja de ruta'}
                </h2>
                <div className='md:space-y-8 md:block md:mt-10'>
                  <h2 className='font-bold'>
                    Flourishing is a state in which all aspects of a person's
                    life are good.
                  </h2>
                  <h3 className='normal-case text-sm font-normal'>
                    Prof. Tyler J. VanderWeele <br />
                    Ph.D., Directory, Program on Human Flourishing <br />
                    Harvard University
                  </h3>
                </div>
              </div>
              {/*  */}
              <div className='shrink-0 pl-10'>
                <CircularProgress
                  size='400px'
                  value={10 * flourishingAverage}
                  color='#181D39'
                >
                  <CircularProgressLabel
                    className='text-ieco-2'
                    color='#181D39'
                  >
                    {flourishingAverage.toFixed(1)} / 10
                  </CircularProgressLabel>
                </CircularProgress>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};
