//only want four divisions?
// export const setColorLevel = (n) => {
//   if (n < 1) return 'bg-white';
//   if (n < 2) return 'bg-blue-100';
//   if (n < 3) return 'bg-blue-200';
//   if (n < 4) return 'bg-blue-300';
//   if (n < 5) return 'bg-blue-400';
//   if (n < 6) return 'bg-blue-500';
//   if (n < 7) return 'bg-blue-600';
//   if (n < 8) return 'bg-blue-700';
//   if (n < 9) return 'bg-blue-800';
//   return 'bg-blue-900';
// };

export const setColorLevel = (n) => {
  n = parseFloat(n);
  console.log('set color level', n);

  // if (n < 2) return 'bg-crimson-20';
  // if (n < 3) return 'bg-crimson-40';
  // if (n < 4) return 'bg-crimson-80';
  // return 'bg-crimson';

  // if (n < 1) return 'bg-blue-50';
  // if (n < 2) return 'bg-blue-100';
  if (n < 3) return 'bg-blue-100';
  // if (n < 4) return 'bg-blue-300';
  // if (n < 5) return 'bg-blue-400';
  if (n < 6) return 'bg-blue-200';
  // if (n < 7) return 'bg-blue-600';
  if (n < 8) return 'bg-blue-300';
  // if (n < 9) return 'bg-blue-800';
  return 'bg-blue-400';

  // if (n < 2) return 'bg-indigo-100';
  // if (n < 3) return 'bg-indigo-200';
  // if (n < 4) return 'bg-indigo-300';
  // return 'bg-indigo-400';
};

export const setTextColorLevel = (n) => {
  n = parseFloat(n);
  // if (n < 5) {
  return 'text-black';
  // }
  // return 'text-white';
};
