// const scores = categories.map((cat) => ({ [cat]: 0 }));

import { survey, survey2 } from './surveys';

export const calcMotivationGoodScores = ({ answers1, answers2 }) => {
  // const s = new Set();
  // survey.forEach((a) => s.add(a.category));
  // const a = [];
  // s.forEach((s) => a.push(s));
  // console.log('categories', a);
  // console.log(`answer2: ${answers2}`);
  let good = {
    useful: 0,
    pleasant: 0,
    moral: 0,
    spiritual: 0,
  };
  let motivation = {
    extrinsic: 0,
    intrinsic: 0,
    transcendent: 0,
    religious: 0,
  };

  let scores = { good, motivation };

  // Main motivation questions
  answers1.forEach((a, i) => {
    const cat = survey[i].category;
    scores = map2GoodOrMotivation({
      answer: a,
      category: cat,
      good,
      motivation,
    });
  });
  //Religious questions
  answers2.forEach((a, i) => {
    const cat = survey2[i].category;
    scores = map2GoodOrMotivation({
      answer: a,
      category: cat,
      good,
      motivation,
    });
  });
  console.log('scores', scores);

  return scores;
};

const map2GoodOrMotivation = ({ answer, category, good, motivation }) => {
  if (category.includes('Useful')) {
    good.useful += answer;
  } else if (category.includes('Pleasant')) {
    good.pleasant += answer;
  } else if (category.includes('Moral')) {
    good.moral += answer;
  } else if (category.includes('Spiritual')) {
    good.spiritual += answer;
  }
  //
  if (category.includes('Extrinsic')) {
    motivation.extrinsic += answer;
  } else if (category.includes('Intrinsic')) {
    motivation.intrinsic += answer;
  } else if (category.includes('Transcendent')) {
    motivation.transcendent += answer;
  } else if (category.includes('Religious')) {
    motivation.religious += answer;
  }
  return { good, motivation };
};
