import { BookCTA } from '../components/BookCTA';
import { QuizHero } from '../components/quiz/QuizInstructionsHero';

export const QuizInstructionsPage = () => {
  return (
    <div className='relative '>
      <div className=''>
        <QuizHero />
        <BookCTA />
      </div>
    </div>
  );
};
