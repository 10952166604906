import { Flourishing, survey, survey2 } from './surveys';

export const fillSurvey = (n, max) => {
  const s = new Array(n)
    .fill('')
    .map(() => Math.floor(Math.random() * max + 1));
  // .map(() => Math.floor(Math.random() * max).toString());
  // console.log('s', s);
  return s;
};

export type Answers = {
  id?: string;
  survey: number[];
  survey2: number[];
  flourishing: number[];
  workEngagement: number[];
  demographics: string[];
  domain: string;
};

export const makeEmptyAnswers = ({
  domain = 'findmeaningfulwork.com',
}: {
  domain?: string;
}): Answers => {
  return {
    survey: new Array(survey.length).fill(0),
    survey2: new Array(survey2.length).fill(0),
    // flourishing: new Array(12).fill(-1),
    flourishing: new Array(2).fill(-1),
    // workEngagement: new Array(9).fill(-1),
    workEngagement: new Array(3).fill(-1),
    demographics: new Array(4).fill(''),
    domain,
  };
};

export const makeRandomAnswers = (): Answers => {
  return {
    survey: fillSurvey(survey.length, 5),
    survey2: fillSurvey(survey2.length, 5),
    flourishing: fillSurvey(Flourishing.length, 10),
    workEngagement: fillSurvey(9, 6),
    demographics: ['', '', '', ''],
    domain: 'findmeaningfulwork.com',
  };
};
