const responses = [
  { value: 0, en: '0 - Never', es: '0 - Nunca' },
  {
    value: 1,
    en: '1 - Almost never (a few times a year or less)',
    es: '1 - Casi nunca (pocas veces al año)',
  },
  {
    value: 2,
    en: '2 - Rarely (once a month or less)',
    es: '2 - Algunas veces (una vez al mes o menos)',
  },
  {
    value: 3,
    en: '3 - Somemtimes (a few times a month)',
    es: '3 - Regularmente (pocas veces al mes)',
  },
  {
    value: 4,
    en: '4 - Often (once a week)',
    es: '4 - Bastante veces (una vez por semana)',
  },
  {
    value: 5,
    en: '5 - Very Often (a few times a week)',
    es: '5 - Casi siempre (pocas veces por semana)',
  },
  {
    value: 6,
    en: '6 - Always (Every day)',
    es: '6 - Siempre (todos los dias)',
  },
];

export const workEngagement = {
  title: {
    en: 'Work Engagement',
    es: 'Compromiso Laboral',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: 'The following statements are about how you feel at work.',
    es: 'Esta última sección contiene preguntas sobre cómo se siente en su trabajo y su nivel de compromiso.',
  },
  allowSkip: false,
  prompt: {
    en: '',
    es: '',
  },
  questions: [
    {
      en: 'At my work, I feel bursting with energy',
      es: 'En mi trabajo me siento lleno/a de energía',
      responses,
    },
    {
      en: 'I am enthusiastic about my job',
      es: 'Estoy entusiasmado/a con mi trabajo',
      responses,
    },
    {
      en: 'I am immersed in my work',
      es: 'Estoy inmerso/a en mi trabajo',
      responses,
    },
  ],
};
