import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet';

import { Formik, Field, Form, FormikHelpers } from 'formik';
import { doSignInWithGoogle } from '../firebase/sign_in_with_google';
import FirebaseClient from '../firebase/firebase_client';
import { Spinner } from '../components/Spinner';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

interface Values {
  email: string;
  password: string;
}

const SignInPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  const [errorMsg, setErrorMsg] = useState(null);

  const { currentUser } = FirebaseClient.auth;
  const mutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      FirebaseClient.auth.signInWithEmailAndPassword(email, password),
    {
      onError: (error: any) => {
        console.error(error);
        setErrorMsg(error?.message);
      },
    },
  );

  if (currentUser && currentUser?.uid) {
    return <Redirect to='/' />;
  }

  return (
    <div>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className='min-h-screen bg-gray-50 grid md:grid-cols-12 py-4 px-6 lg:px-8'>
        <div className='md:col-span-2 sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900'>
            {lang === 'en' ? 'SIGN IN' : 'INICIAR SESIÓN'}
          </h2>
        </div>

        <div className='md:col-span-9 mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='pb-8'>
            <p className='text-red-500'>{errorMsg} </p>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>,
              ) => {
                const { email, password } = values;
                mutation.mutate({ email, password });
              }}>
              <Form translate='yes'>
                <div>
                  <label
                    htmlFor='email'
                    className='sr-only block text-sm font-medium leading-5 text-gray-700'>
                    {lang === 'en' ? 'Email address' : 'Correo electrónico'}
                  </label>
                  <div className='flex items-center mt-1 rounded-md shadow-sm'>
                    <Field
                      id='email'
                      name='email'
                      type='email'
                      placeholder={
                        lang === 'en' ? 'Email address' : 'Correo electrónico'
                      }
                      autoComplete='username'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                    <svg
                      className='-ml-10 h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'>
                      <path
                        fillRule='evenodd'
                        d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=' password'
                    className='sr-only block text-sm font-medium leading-5 text-gray-700'>
                    {lang === 'en' ? 'Password' : 'Contraseña'}
                    Password
                  </label>
                  <div className='mt-1 flex items-center rounded-md shadow-sm'>
                    <Field
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      placeholder={lang === 'en' ? 'Password' : 'Contraseña'}
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                    <svg
                      className='-ml-10 h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'>
                      <path
                        fillRule='evenodd'
                        d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </div>
                </div>
                <div className='mt-6 flex items-center justify-between'>
                  <div className='text-sm leading-5'>
                    <Link
                      to='/reset-password'
                      className='font-medium text-gray-600 hover:text-skyblue focus:outline-none focus:underline transition ease-in-out duration-150'>
                      {lang === 'en'
                        ? 'Forget your password?'
                        : 'He olvidado mi contraseña'}
                    </Link>
                  </div>
                </div>

                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-gold focus:outline-none transition duration-150 ease-in-out'>
                  {mutation.isLoading && <Spinner />}
                  {lang === 'en' ? 'Sign in' : 'Iniciar sesión'}
                </button>
              </Form>
            </Formik>

            <div className='mt-6'>
              <div className='relative'>
                <div className='absolute inset-0 flex items-center'>
                  <div className='w-full border-t border-gray-300'></div>
                </div>
                <div className='relative flex justify-center text-sm leading-5'>
                  <span className='px-2 text-gray-500'>
                    {lang === 'en' ? 'Or' : 'O'}
                  </span>
                </div>
              </div>
              <div className='mt-4'>
                <button
                  className='flex content-center bg-white px-2 rounded-lg border w-full'
                  onClick={() => doSignInWithGoogle()}>
                  <img
                    className='flex-shrink  p-2 h-10 w-10'
                    src='/google.svg'
                    alt='Google Logo'
                  />
                  <p className='flex-grow p-2'>
                    {lang === 'en'
                      ? 'Sign in with Google'
                      : 'Iniciar sesión con Google'}
                  </p>
                </button>
              </div>
            </div>
          </div>
          <Link
            className='block pt-5 text-sm text-gray-500 underline'
            to='/legal'>
            Legal and Terms of Service Notice
          </Link>
        </div>
      </div>
    </div>
  );
};

const Error = () => {
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>
        &#8203;
        {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
        <div
          className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'>
          <div className='sm:flex sm:items-start'>
            <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
              <svg
                className='h-6 w-6 text-red-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
            </div>
            <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
              <h3
                className='text-lg leading-6 font-medium text-gray-900'
                id='modal-headline'>
                Deactivate account
              </h3>
              <div className='mt-2'>
                <p className='text-sm leading-5 text-gray-500'>
                  Are you sure you want to deactivate your account? All of your
                  data will be permanently removed from our servers forever.
                  This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
            <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
              <button
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
                Deactivate
              </button>
            </span>
            <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
              <button
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignInPage;
