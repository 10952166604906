import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CTA from '../components/LearnMoreCTA';
import { WorkPageTurner } from '../components/WorkPageTurner';
import { Store } from '../redux/store';

export const MapOfMotivationsPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div
          className='p-5 md:p-24'
          style={{
            background:
              'linear-gradient(180deg, rgba(35, 35, 35, 0.7) 0%, rgba(77, 64, 48, 0.7) 100%)',
            backdropFilter: 'blur(30px)',
          }}>
          <h1 className='text-gold text-4xl' style={{ fontFamily: 'Georgia' }}>
            {lang === 'en' ? 'Map of Motivations' : 'Mapa de motivaciones'}
          </h1>
          <img className='mt-2' src='/work-motivation-dots.svg' alt='' />
          <div className='mt-10' />
          <p className='mx-auto max-w-lg text-center text-white text-xl'>
            {lang === 'en'
              ? 'Relational matrix of motivations regarding the motives or goals of my motivation and each kind of good that we pursue when we work'
              : 'Matriz que relaciona las distintas motivaciones con los tipos de bienes que buscamos en el trabajo'}
          </p>
          <div className='mt-10' />

          <div>
            {lang === 'en' ? (
              // <RelationalMatrix height='h-96' width='w-full' />
              <img className='mx-auto' src='/relational-map.svg' alt='' />
            ) : (
              <img className='mx-auto' src='/map-es.svg' alt='' />
            )}

            {/*  */}
            <div className='mt-20' />
            <WorkPageTurner page={'map-of-motivations'} />
          </div>
        </div>
      </div>
      <div className='bg-white'>
        <CTA />
      </div>
    </div>
  );
};
