import { demographics } from './carlos/demographics';
import { jobSatisfactionAndFlourishing } from './carlos/job_satisfaction_and_flourishing';
import { motivations } from './carlos/motivations';
import { organizationalPurpose } from './carlos/organizational_purpose';
import { religiousMotivations } from './carlos/religious_motivations';
import { workEngagement } from './carlos/work_engagement';

import aedrhSections from './aedrh';

export type Response = {
  value: number;
  en: string;
  es: string;
};

export type Question = {
  en: string;
  es: string;
  responses: Response[];
  meta?: any;
};

export type Section = {
  title: { en: string; es: string };
  subtitle: { en: string; es: string };
  instructions: { en: string; es: string };
  allowSkip?: boolean;
  skipInstructions?: { en: string; es: string };
  prompt: { en: string; es: string };
  questions: Question[];
  meta?: any;
};

export type Survey = {
  name: { en: string; es: string };
  instructions: { en: string; es: string };
  sections: Section[];
  meta?: any;
};

// Main Survey

// Carlos's Survey
// - do this one first to figure out the patterns
// 6 sections: organizational purpose, motivations, religious motivations, work engagement, job satisfaction & flourishing domains and demographics

export const carlos: Survey = {
  name: {
    en: 'Purpose Survey',
    es: 'Purpose Survey',
  },
  instructions: {
    en: `The following survey aims to measure your motivations at work and to assess several other important domains of job satisfaction, human flourishing and work engagement. There are a total of 6 sections. It has an expected duration of less than 10 minutes. At the end you will get a personal report with your results.`,
    es: `La siguiente encuesta pretende medir sus motivaciones en el trabajo y evaluar otros ámbitos importantes de la satisfacción laboral, el desarrollo humano y el compromiso laboral. Hay un total de 6 secciones. Tiene una duración prevista de menos de 10 minutos. Al terminar obtendrá un informe personal con sus resultados.`,
  },
  sections: [
    organizationalPurpose,
    motivations,
    religiousMotivations,
    workEngagement,
    jobSatisfactionAndFlourishing,
    demographics,
  ],
};

export const aedrh: Survey = {
  name: {
    en: 'Purpose Survey',
    es: 'Purpose Survey',
  },
  instructions: {
    en: `The following survey aims to measure your motivations at work and to assess several other important domains of job satisfaction, human flourishing and work engagement. There are a total of 6 sections. It has an expected duration of less than 10 minutes. At the end you will get a personal report with your results.`,
    es: `La siguiente encuesta pretende medir la relación entre el propósito corporativo de su organización y el sentido que otorga a su trabajo, sus motivaciones, su satisfacción laboral y el desarrollo humano pleno. 
Hay un total de 5 secciones. Tiene una duración prevista de menos de 5 minutos. 
Al terminar obtendrá un informe personal con sus resultados.`,
  },
  sections: [
    aedrhSections.organizationalPurpose,
    aedrhSections.motivations,
    aedrhSections.religiousMotivations,
    aedrhSections.workEngagement,
    aedrhSections.jobSatisfactionAndFlourishing,
    aedrhSections.demographics,
  ],
};
