import { calcMatrixScores } from '../utils/calc_matrix_scores';
import StatCard from './StatCard';
import WorkCard from './WorkTypes';
import { categories } from '../utils/categories';
import { setColorLevel, setTextColorLevel } from '../utils/set_color_level';
import { calcMotivationGoodScores } from '../utils/calc_motivation_good_scores';
import FlourishingDomains from '../components/FlourishingDomains';
import WorkEngagementResults from './WorkEngagementResults';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

const Results = ({
  answers,
  showResults = true,
}: {
  answers: any;
  showResults?: boolean;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('RESULTS');
    console.log('answers', answers);
    console.groupEnd();
  }

  const lang = useSelector((state: Store) => state.lang);

  // Motivation/Good scores
  let mainScores, totals, scores, jobScores;
  if (showResults) {
    mainScores = calcMotivationGoodScores({
      answers1: answers.survey,
      answers2: answers.survey2,
    });
    totals = { good: 0, motivation: 0 };
    totals.good = Object.keys(mainScores.good).reduce(
      (sum, key) => sum + mainScores.good[key],
      0,
    );
    totals.motivation = Object.keys(mainScores.motivation).reduce(
      (sum, key) => sum + mainScores.motivation[key],
      0,
    );

    // Relational scores
    scores = calcMatrixScores({
      answers1: answers.survey,
      answers2: answers.survey2,
    });

    //Job Scores
    jobScores = { job: 0, career: 0, calling: 0, higher: 0 };
    jobScores.job = (1 * scores.ExtrinsicUseful) / 40;
    jobScores.career =
      (1 *
        (scores.ExtrinsicPleasant +
          scores.IntrinsicPleasant +
          scores.IntrinsicUseful)) /
      120;
    jobScores.calling =
      (1 *
        (scores.ExtrinsicMoral +
          scores.IntrinsicMoral +
          scores.TranscendentMoral +
          scores.TranscendentPleasant +
          scores.TranscendentUseful)) /
      200;
    jobScores.higher =
      (1 *
        (scores.ExtrinsicSpiritual +
          scores.IntrinsicSpiritual +
          scores.TranscendentSpiritual +
          scores.ReligiousSpiritual +
          scores.ReligiousMoral +
          scores.ReligiousPleasant +
          scores.ReligiousUseful)) /
      280;

    // const jobTotal =
    //   jobScores.job + jobScores.career + jobScores.calling + jobScores.higher;

    jobScores.job = 100 * jobScores.job; // / jobTotal;
    jobScores.career = 100 * jobScores.career; // / jobTotal;
    jobScores.calling = 100 * jobScores.calling; // / jobTotal;
    jobScores.higher = 100 * jobScores.higher; // / jobTotal;
  }
  console.log('main scores', mainScores);

  return (
    <div className='mt-10'>
      <div className=''>
        {/* <h2 className='leading-8 text-2xl text-left bg-white px-0 py-2'>
          {mainScores ? 'Your Work Motivation' : 'Work Motivation'}
        </h2> */}
        {/* COLUMNS */}
        <h3 className='text-white text-2xl py-2'>
          {lang === 'en'
            ? 'When I go to work I do it because I expect to:'
            : 'Cuando voy a trabajar lo hago porque busco: '}
        </h3>
        {!showResults && (
          <p className='text-center'>
            <em>(motives or goals of my motivation)</em>
          </p>
        )}
        <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
          <StatCard
            // title='Extrinsic'
            title={lang === 'en' ? 'Receive' : 'RECIBIR'}
            about={lang === 'en' ? 'something good' : 'algo bueno'}
            stat={
              mainScores && mainScores.motivation.extrinsic / 16
              // mainScores && (mainScores.motivation.extrinsic / 1).toFixed(1)
            }
            icon='/receive.svg'
            percent={
              mainScores &&
              ((100 * mainScores.motivation.extrinsic) / 160).toFixed(1)
            }
          />
          <StatCard
            title={lang === 'en' ? 'Achieve' : 'LOGRAR'}
            about={lang === 'en' ? 'something good' : 'algo bueno'}
            stat={
              mainScores && (mainScores.motivation.intrinsic / 1).toFixed(1)
            }
            icon='/achieve.svg'
            percent={
              mainScores &&
              ((100 * mainScores.motivation.intrinsic) / 160).toFixed(1)
            }
          />
          <StatCard
            title={lang === 'en' ? 'Give' : 'DAR'}
            about={lang === 'en' ? 'something good' : 'algo bueno'}
            stat={
              mainScores && (mainScores.motivation.transcendent / 16).toFixed(1)
            }
            icon='/give.svg'
            percent={
              mainScores &&
              ((100 * mainScores.motivation.transcendent) / 160).toFixed(1)
            }
          />
          <StatCard
            title={lang === 'en' ? 'Give back' : 'DEVOLVER'}
            // title='Religious'
            about={lang === 'en' ? 'something good' : 'algo bueno'}
            stat={
              mainScores && (mainScores.motivation.religious / 16).toFixed(1)
            }
            icon='/give-back.svg'
            percent={
              mainScores &&
              ((100 * mainScores.motivation.religious) / 160).toFixed(1)
            }
          />
        </div>
        {/*  */}
        <h3 className='mt-10 text-white text-2xl py-2'>
          {lang === 'en'
            ? 'When I work I do it as a way to get some:'
            : 'Cuando trabajo lo hago como una forma de obtener: '}
        </h3>
        {!showResults && (
          <p className='text-center'>
            <em>(kind of good that we pursue when we work)</em>
          </p>
        )}
        <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
          <StatCard
            title={lang === 'en' ? 'Useful' : 'ÚTIL'}
            about={lang === 'en' ? 'good' : 'un bien'}
            secondaryText='(utility and practicality)'
            stat={mainScores && (mainScores.good.useful / 16).toFixed(1)}
            icon='/useful.svg'
            percent={
              mainScores && ((100 * mainScores.good.useful) / 160).toFixed(1)
            }
            version={lang === 'en' ? 1 : 2}
          />
          <StatCard
            title={lang === 'en' ? 'Pleasant' : 'AGRADABLE'}
            about={lang === 'en' ? 'good' : 'un bien'}
            secondaryText='(enjoyment and satisfaction)'
            stat={mainScores && (mainScores.good.pleasant / 16).toFixed(1)}
            icon='/pleasant.svg'
            percent={
              mainScores && ((100 * mainScores.good.pleasant) / 160).toFixed(1)
            }
            version={lang === 'en' ? 1 : 2}
          />
          <StatCard
            title={lang === 'en' ? 'Moral' : 'MORAL'}
            about={lang === 'en' ? 'good' : 'un bien'}
            secondaryText='(human growth and betterment)'
            stat={mainScores && (mainScores.good.moral / 16).toFixed(1)}
            icon='/moral.svg'
            percent={
              mainScores && ((100 * mainScores.good.moral) / 160).toFixed(1)
            }
            version={lang === 'en' ? 1 : 2}
          />
          <StatCard
            title={lang === 'en' ? 'Spiritual' : 'ESPIRITUAL'}
            about={lang === 'en' ? 'good' : 'un bien'}
            secondaryText='(higher meaning and fulfillment)'
            stat={mainScores && (mainScores.good.spiritual / 16).toFixed(1)}
            icon='/spiritual.svg'
            percent={
              mainScores && ((100 * mainScores.good.spiritual) / 160).toFixed(1)
            }
            version={lang === 'en' ? 1 : 2}
          />
        </div>
      </div>
      {/* YOUR WORK MAP */}
      <div className='m-10' />
      <div
        className='bg-gold py-6 text-center'
        style={{
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <h1
          className='text-4xl'
          style={{
            font: '#6B583F',
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Your results' : 'Tus resultados'}
        </h1>
        <img className='' src='/results-white-dots.svg' alt='' />
        <h2 className='text-white text-2xl'>
          {lang === 'en' ? 'Your work map' : 'Tu mapa de las motivaciones'}
        </h2>
      </div>
      <p className='mt-4 mx-auto max-w-lg text-white text-center'>
        {lang === 'en'
          ? 'Relational matrix of motivations regarding the motives or goals of my motivation and each kind of good that we pursue when you work'
          : 'Matriz que relaciona las distintas motivaciones con los tipos de bienes que buscamos en el trabajo'}
      </p>

      <div className='flex bg-gray-100 m-1 p-2 shadow rounded-lg overflow-hidden overflow-x-auto'>
        <table className='mt-2 min-w-full overflow-x'>
          <thead>
            <tr>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th
                className='text-white rounded-full'
                colSpan={4}
                style={{
                  background: '#5B5B5B',
                }}>
                {lang === 'en' ? 'MOTIVATIONS' : 'MOTIVACIONES'}
              </th>
            </tr>
            <tr>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50'></th>
              <th className='px-2 py-2 bg-gray-50  text-xs leading-4 font-medium text-gray-500 text-right'>
                {lang === 'en' ? 'RECEIVE' : 'RECIBIR'}

                <img
                  alt='hand'
                  src='/receive.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'ACHIEVE' : 'LOGRAR'}
                <img
                  alt='fist'
                  src='/achieve.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'GIVE' : 'DAR'}
                {/* Transcendent */}
                <img
                  src='/give.svg'
                  className='hidden md:inline-block w-10 p-1'
                  alt=''
                />
              </th>
              <th className='px-2 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 '>
                {lang === 'en' ? 'GIVE BACK' : 'DEVOLVER'}
                {/* Religious */}
                <img
                  alt=''
                  src='/give-back.svg'
                  className='hidden md:inline-block w-10 p-1'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {(lang === 'en'
              ? ['SPIRITUAL', 'MORAL', 'PLEASANT', 'USEFUL']
              : ['ESPIRITUAL', 'MORAL', 'AGRADABLE', 'ÚTIL']
            ).map((rowLabel, i) => {
              //
              const offset = i * 4;
              let rowScores;
              if (scores) {
                rowScores = [0, 1, 2, 3].map((i) =>
                  (scores[categories[offset + i]] / 4).toFixed(1),
                );
              } else {
                if (i === 0) {
                  rowScores = ['Grace', 'Holiness', 'Light', 'Praise'];
                } else if (i === 1) {
                  rowScores = ['Respect', 'Excellence', 'Good', 'Adoration'];
                } else if (i === 2) {
                  rowScores = ['Esteem', 'Satisfaction', 'Joy', 'Gratefulness'];
                } else {
                  rowScores = ['Support', 'Competence', 'Service', 'Fidelity'];
                }
              }

              const icons = [
                'spiritual.svg',
                'moral.svg',
                'pleasant.svg',
                'useful.svg',
              ];

              return (
                <tr className='text-center' key={rowLabel}>
                  {i === 0 && (
                    <td rowSpan={4} className='rounded-2xl whitespace-no-wrap'>
                      <div
                        className='py-20 px-1 rounded-full'
                        style={{
                          background: '#5B5B5B',
                        }}>
                        <p className='p-0 -mx-10 transform -rotate-90 text-white text-xs md:text-sm'>
                          {lang === 'en' ? 'TYPE OF GOOD' : 'TIPO DE BIEN'}
                        </p>
                      </div>
                    </td>
                  )}
                  <td className='flex justify-between items-center px-1 py-1 bg-gray-50 text-sm text-gray-500'>
                    <p className='text-xs md:text-base'>{rowLabel}</p>
                    <img
                      src={`/${icons[i]}`}
                      className='hidden md:inline-block w-10 p-1'
                      alt=''
                    />
                  </td>
                  {rowScores.map((s, i) => {
                    return (
                      <td
                        key={i}
                        className={`px-1 py-2 text-xs md:text-sm ${
                          // mainScores ? 'text-blue-900' : 'text-blue-100'
                          mainScores ? setTextColorLevel(s) : 'text-blue-100'
                        } text-center
                      ${mainScores ? setColorLevel(s) : 'bg-blue-500'}`}>
                        {s}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {mainScores && (
        <p className='text-right  mt-2 text-sm text-white'>
          {lang === 'en' ? (
            <span>
              For each motivation there is an intensity that ranges from 0 to
              10.
              <br />
              This intensity is the average score. Yours are displayed above.
            </span>
          ) : (
            <span>
              Cada motivación tiene una intensidad que va de 0 a 10. La
              puntuación <br /> media de intensidad para cada tipo de motivación
              aparece arriba.
            </span>
          )}
        </p>
      )}
      {/*  */}
      <div className='m-10' />
      {/* YOUR WORK MEANINGS */}
      <div
        className='bg-gold py-6 text-center'
        style={{
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <h1
          className='text-4xl'
          style={{
            font: '#6B583F',
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Your results' : 'Tus resultados'}
        </h1>
        <img className='' src='/results-white-dots.svg' alt='' />
        <h2 className='text-white text-2xl'>
          {lang === 'en' ? 'Your work meanings' : 'Tu sentido del trabajo'}
        </h2>
      </div>

      <div className='mt-16'>
        <div className='ml-2 pb-2'>
          <div className='relative mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3'>
            <WorkCard
              title='Job'
              body={jobScores && `${jobScores && jobScores.job.toFixed(1)}%`}
            />
            <WorkCard
              title='Career'
              body={jobScores && `${jobScores && jobScores.career.toFixed(1)}%`}
            />
            <WorkCard
              title='Calling'
              body={
                jobScores && `${jobScores && jobScores.calling.toFixed(1)}%`
              }
            />
            <WorkCard
              title='Higher Calling'
              body={jobScores && `${jobScores && jobScores.higher.toFixed(1)}%`}
            />
          </div>
        </div>
      </div>
      {mainScores && (
        <p className='mt-4 text-sm text-white text-right'>
          {lang === 'en' ? (
            <span>
              There are four meanings of work. <br /> Above are how you scored
              in each of the four meanings.
            </span>
          ) : (
            <span>
              Existen cuatro sentidos del trabajo. Tu puntuación para cada uno
              <br />
              de los sentidos del trabajo aparece arriba.
            </span>
          )}
        </p>
      )}
      {/* YOUR FLOURISHING RESULTS */}
      {answers.flourishing[0] !== -1 && (
        <div className='relative pt-20'>
          {/* <h2 className='leading-8 text-2xl text-left bg-white px-2 py-2'>
          {mainScores ? 'Your Flourishing Results' : 'Flourishing Domains'}
        </h2> */}
          <FlourishingDomains responses={mainScores && answers.flourishing} />
        </div>
      )}

      {/* YOUR WORK ENGAGMENT RESULTS */}
      {answers.workEngagement[0] !== -1 && (
        <div className='relative pt-20'>
          <WorkEngagementResults
            responses={mainScores && answers.workEngagement}
          />
        </div>
      )}

      {/* <div className='m-10' /> */}

      {/*  */}
      {/* <div className='relative mx-auto bg-white min-w-full'> */}
      {/* {mainScores ? <BookCTA /> : <LearnMoreCTA />} */}
      {/* </div> */}
    </div>
  );
};

export default Results;
