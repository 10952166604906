import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CharacterIcon,
  FinancialIcon,
  HappyIcon,
  MeaningIcon,
  MentalIcon,
  RelationshipIcon,
} from '../assets/icons';
import CTA from '../components/LearnMoreCTA';
import { WorkPageTurner } from '../components/WorkPageTurner';
import { Store } from '../redux/store';

export const FlourishingDomainsPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div
          className='p-5 md:p-24'
          style={{
            background:
              'linear-gradient(180deg, rgba(35, 35, 35, 0.7) 0%, rgba(77, 64, 48, 0.7) 100%)',
            backdropFilter: 'blur(30px)',
          }}>
          <h1 className='text-gold text-4xl' style={{ fontFamily: 'Georgia' }}>
            {lang === 'en'
              ? 'Flourishing Domains'
              : 'Ámbitos del desarrollo humano pleno'}
          </h1>
          <img className='mt-2' src='/work-motivation-dots.svg' alt='' />
          <div className='mt-10' />
          <p className='text-white text-2xl'>
            {lang === 'en'
              ? 'There are six distinct domains of flourishing we cover here'
              : 'Los seis ámbitos del desarrollo humano pleno'}
          </p>
          <div className='mt-10' />
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'>
            {lang === 'en' ? (
              <>
                <Card
                  title='HAPPINESS AND LIFE SATISFACTION'
                  text='How happy and satisfied we think we are'
                  icon={HappyIcon}
                />
                <Card
                  title='MENTAL AND PHYSICAL HEALTH'
                  text='Dealing with the various ailments that may come our way throughout our lives'
                  icon={MentalIcon}
                />
                <Card
                  title='MEANING AND PURPOSE'
                  text='How we view our individual purpose within the world'
                  icon={MeaningIcon}
                />
                <Card
                  title='CHARACTER AND VIRTUE'
                  text='How we act towards ourselves and others'
                  icon={CharacterIcon}
                />
                <Card
                  title='CLOSE SOCIAL RELATIONSHIP'
                  text='Being content and satisfied with friendships, family, and relationships'
                  icon={RelationshipIcon}
                />
                <Card
                  title='FINANCIAL AND MATERIAL STABILITY'
                  text='To not worry about safety, food, housing as well as our monthly living expenses'
                  icon={FinancialIcon}
                />
              </>
            ) : (
              <>
                {' '}
                <Card
                  title='FELICIDAD Y SATISFACCIÓN EN LA VIDA'
                  text='Cómo estamos de felices y satisfechos'
                  icon={HappyIcon}
                />
                <Card
                  title='SALUD FÍSICA Y MENTAL'
                  text='Cómo afrontamos la falta de salud'
                  icon={MentalIcon}
                />
                <Card
                  title='SENTIDO Y PROPÓSITO'
                  text='Cómo vemos nuestro propósito en la vida'
                  icon={MeaningIcon}
                />
                <Card
                  title='VIRTUD Y CARÁCTER'
                  text='Cómo nos comportamos y tratamos a los demás'
                  icon={CharacterIcon}
                />
                <Card
                  title='RELACIONES SOCIALES'
                  text='Cómo nos sentimos de satisfechos con nuestras relaciones sociales y familiares'
                  icon={RelationshipIcon}
                />
                <Card
                  title='ESTABILIDAD ECONÓMICA'
                  text='Cómo estamos de preocupados por nuestra seguridad material'
                  icon={FinancialIcon}
                />
              </>
            )}
          </div>
          {/*  */}
          <div className='mt-20' />
          <WorkPageTurner page={'flourishing-domains'} />
        </div>
      </div>
      <div className='bg-white'>
        <CTA />
      </div>
    </div>
  );
};

const Card = ({
  title,
  text,
  icon,
}: {
  title: string;
  text: string;
  icon: JSX.Element;
}) => {
  return (
    <div className='mx-auto w-48 flex flex-col justify-start overflow-hidden'>
      <div
        className='h-56 w-48 pb-20 rounded-2xl shadow'
        style={{
          backgroundColor: '#C4C4C4',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <div className={`flex flex-col items-center px-0 py-0 sm:px-0 `}>
          {/* <div className={`${bg} flex flex-col items-center px-2 py-2 sm:px-3 `}> */}
          <div className='mt-4' />
          {/* <div className='rounded-full p-4 shadow-xl overflow-hidden'> */}
          <div className='h-24'>{icon}</div>
          {/* </div> */}
          <div className='mt-3' />
          <h3
            className='text-xl text-black text-center'
            style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            {title}
          </h3>
        </div>
      </div>
      <div className='flex-grow text-base text-center text-white'>
        <div className='px-4 py-5 sm:p-6'>{text}</div>
      </div>
    </div>
  );
};
