import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/store';

const flourishingLabels = [
  ['Not Satisfied at All', 'Completely Satisfied'],
  ['Extremely Unhappy', 'Extremely Happy'],
  ['Poor', 'Excellent'],
  ['Poor', 'Excellent'],
  ['Not at All Worthwhile', 'Completely Worthwhile'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Not True of Me', 'Completely True of Me'],
  ['Not True of Me', 'Completely True of Me'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Worry All of the Time', 'Do Not Ever Worry'],
  ['Worry All of the Time', 'Do Not Ever Worry'],
];

const flourishingESLabels = [
  ['Nada satisfecho/a', 'Completamente satisfecho/a'],
  ['Muy infeliz', 'Muy feliz'],
  ['Mala', 'Excelente'],
  ['Mala', 'Excelente'],
  ['No valen la pena para nada', 'Valen la pena completamente'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Nada cierto', 'Completamente cierto'],
  ['Nada cierto', 'Completamente cierto'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Nunca me preocupa', 'Me preocupa todo el tiempo'],
  ['Nunca me preocupa', 'Me preocupa todo el tiempo'],
];

const FlourishingQuestions = ({ questions, section, handleCompleted }) => {
  const numberOfSections = 5;
  console.log('questions', questions);
  

  const lang = useSelector((state: Store) => state.lang);

  const [questionNumber, setQuestionNumber] = useState(0);

  const [showTitle, setShowTitle] = useState(true);

  const [answers, setAnswers] = useState(
    new Array(questions.length).fill(null),
  );
  console.log('answers', answers);

  const question = questions[questionNumber];

  const numbers =
    lang === 'en'
      ? [
          { n: 0, label: `0 - ${flourishingLabels[questionNumber][0]}` },
          { n: 1, label: '1' },
          { n: 2, label: '2' },
          { n: 3, label: '3' },
          { n: 4, label: '4' },
          { n: 5, label: '5' },
          { n: 6, label: '6' },
          { n: 7, label: '7' },
          { n: 8, label: '8' },
          { n: 9, label: '9' },
          { n: 10, label: `10 - ${flourishingLabels[questionNumber][1]}` },
        ]
      : [
          { n: 0, label: `0 - ${flourishingESLabels[questionNumber][0]}` },
          { n: 1, label: '1' },
          { n: 2, label: '2' },
          { n: 3, label: '3' },
          { n: 4, label: '4' },
          { n: 5, label: '5' },
          { n: 6, label: '6' },
          { n: 7, label: '7' },
          { n: 8, label: '8' },
          { n: 9, label: '9' },
          { n: 10, label: `10 - ${flourishingESLabels[questionNumber][1]}` },
        ];

  const progress = (100 * (questionNumber + 1)) / questions.length;

  const handleNext = (value) => {
    const n = parseInt(value);
    console.log('Handle next', value, n);

    // Exit because complete
    if (questionNumber === questions.length - 1) {
      answers[questionNumber] = n;
      handleCompleted({ answers, section });
    }
    // To the next question
    if (questionNumber < questions.length - 1) {
      answers[questionNumber] = n;
      setAnswers([...answers]);
      setQuestionNumber(questionNumber + 1);
    }
  };


  const handleBack = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
    }
  };

  if (showTitle) {
    return (
      <div className=''>
        <div className=''>
          <h2 className='text-2xl'>
            {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
          </h2>
          {lang === 'en' ? (
            <div className='mt-10 text-xl'>
              <p>
                This Section has {questions.length} questions about the
                different domains contributing to a fulfilling and flourishing
                human life.
              </p>
              <p className='mt-4'>Press next to start.</p>
            </div>
          ) : (
            <div className='mt-10 text-xl'>
              <p>
                Esta sección consta de {questions.length} preguntas sobre los
                diferentes ámbitos que contribuyen a un desarrollo humano pleno.
              </p>
              <p className='mt-4'>Presione siguiente para comenzar</p>
            </div>
          )}

          <div className='flex mt-10'>
            <button
              className='flex  px-8 py-2 bg-gold text-white rounded-full'
              onClick={() => setShowTitle(false)}>
              {lang === 'en' ? <span>Next</span> : <span>Siguiente</span>}
              <svg
                className='flex-shrink-1 h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        </div>
        <div className='ml-2' />
      </div>
    );
  }

  return (
    <div className=''>
      <div className=''>
        <h2 className='text-2xl'>
          {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
        </h2>
      </div>
      <div className='mt-10' />
      <h3 className='bg-gold bg-opacity-25 rounded-full py-1 mt-10 text-center text-2xl font-bold'>
        {question.q}
      </h3>

      <div className='mt-4' />

      <div className=''>
        <ul className='block sm:hidden'>
          {numbers.map((n, i) => {
            return (
              <label
                key={n.n}
                className='flex items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0'>
                <input
                  className='mx-2'
                  type='radio'
                  value={n.n}
                  checked={answers[questionNumber] === n.n}
                  onChange={(event) => handleNext(event.target.value)}
                />
                <span>{n.label}</span>
              </label>
            );
          })}
        </ul>

        <ul className='hidden sm:block'>
          {numbers.map((n, i) => {
            return (
              <label
                key={n.n}
                className='flex items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0'>
                <input
                  className='mx-2'
                  type='radio'
                  value={n.n}
                  checked={answers[questionNumber] === n.n}
                  onChange={(event) => handleNext(event.target.value)}
                />
                <span>{n.label}</span>
              </label>
            );
          })}
        </ul>
      </div>

      {/* PROGRESS BAR */}
      <div
        id='progress'
        className='mt-2 overflow-hiddern border-gold border-2 h-4 mb-4 flex rounded-full overflow-hidden '>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col bg-gold bg-opacity-90'
        />
      </div>
      <p className='mt-4 text-center'>
        {lang === 'en' ? 'Question' : 'Pregunta'} {questionNumber + 1} /{' '}
        {questions.length}
      </p>

      <div className='mt-5'>
        {questionNumber > 0 && (
          <button
            className='flex px-8 py-1 bg-gold rounded-lg text-white text-sm'
            onClick={handleBack}>
            <svg
              className='flex-shrink-1 h-6 w-6'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>

            {lang === 'en' ? <span>Back</span> : <span>Atrás</span>}
          </button>
        )}
      </div>
    </div>
  );
};

export default FlourishingQuestions;
