import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CTA from '../components/LearnMoreCTA';
import { WorkPageTurner } from '../components/WorkPageTurner';
import { Store } from '../redux/store';

export const WorkMotivationsPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div
          className='p-24'
          style={{
            background:
              'linear-gradient(180deg, rgba(35, 35, 35, 0.7) 0%, rgba(77, 64, 48, 0.7) 100%)',
            backdropFilter: 'blur(30px)',
          }}>
          <h1
            className='text-gold text-4xl'
            style={{
              fontFamily: 'Georgia',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}>
            {lang === 'en' ? 'Work Motivation' : 'Motivación en el trabajo'}
          </h1>
          <img className='' src='/work-motivation-dots.svg' alt='' />
          <div className='mt-10' />
          <p className='text-white text-2xl'>
            {lang === 'en'
              ? 'When I go to work I do it because I expect to:'
              : 'Cuando voy a trabajar lo hago porque busco: '}
          </p>
          <div className='mt-10' />
          <div className='grid grid-cols-1 md:grid-cols-4 gap-8'>
            <Card
              lang={lang}
              title='Receive'
              text='something good'
              icon={'/receive.svg'}
            />
            <Card
              lang={lang}
              title='Achieve'
              text='something good'
              icon={'/achieve.svg'}
            />
            <Card
              lang={lang}
              title='Give'
              text='something good'
              icon={'/give.svg'}
            />
            <Card
              lang={lang}
              title='Give Back'
              text='something good'
              icon={'/give-back.svg'}
            />
          </div>
          {/*  */}

          <div className='mt-10' />
          <p className='text-white text-2xl'>
            {lang === 'en'
              ? 'When I work I do it as a way to get some:'
              : 'Cuando trabajo lo hago como una forma de obtener: '}
          </p>
          <div className='mt-10' />

          <div className='grid grid-cols-1 md:grid-cols-4 gap-8'>
            <Card
              lang={lang}
              title='Useful'
              text='good'
              subtext='(utility and practicality)'
              icon={'/useful.svg'}
            />
            <Card
              lang={lang}
              title='Pleasant'
              text='good'
              subtext='(enjoyment and satisfaction)'
              icon={'/pleasant.svg'}
            />
            <Card
              lang={lang}
              title='Moral'
              text='good'
              subtext='(human growth and betterment)'
              icon={'/moral.svg'}
            />
            <Card
              lang={lang}
              title='Spiritual'
              text='good'
              subtext='(higher meaning and fulfillment)'
              icon={'/spiritual.svg'}
            />
          </div>
          {/*  */}
          <div className='mt-20' />
          <WorkPageTurner page={'work-motivation'} />
        </div>
      </div>

      <div className='bg-white'>
        <CTA />
      </div>
    </div>
  );
};

const Card = ({
  title,
  text,
  subtext,
  icon,
  lang,
}: {
  title: string;
  text: string;
  subtext?: string;
  lang: string;
  icon: string;
}) => {
  if (lang === 'es') {
    if (title.toLowerCase() === 'receive') {
      title = 'RECIBIR';
      text = 'algo bueno';
    }
    if (title.toLowerCase() === 'achieve') {
      title = 'LOGRAR';
      text = 'algo bueno';
    }
    if (title.toLowerCase() === 'give') {
      title = 'DAR';
      text = 'algo bueno';
    }
    if (title.toLowerCase() === 'give back') {
      title = 'DEVOLVER';
      text = 'algo bueno';
    }
    if (title.toLowerCase() === 'useful') {
      title = 'ÚTIL';
      text = 'Un bien';
      subtext = '(utilidad y practicidad)';
    }
    if (title.toLowerCase() === 'pleasant') {
      title = 'AGRADABLE';
      text = 'Un bien';
      subtext = '(disfrute y satisfacción)';
    }
    if (title.toLowerCase() === 'moral') {
      title = 'MORAL';
      text = 'Un bien';
      subtext = '(mejora y desarrollo personal)';
    }
    if (title.toLowerCase() === 'spiritual') {
      title = 'ESPIRITUAL';
      text = 'Un bien';
      subtext = '(sentido y plenitud existencial)';
    }
  }

  return (
    <div className='group relative p-4 bg-gray-300 bg-opacity-100 rounded-xl shadow flex flex-col items-center overflow-hidden'>
      {subtext && (
        <div className='z-10 absolute invisible group-hover:visible inset-0 w-full h-full bg-gray-300'>
          <div className='relative top-1/2 text-center'>{subtext}</div>
        </div>
      )}
      <img className='z-0 h-16 w-16' src={icon} alt='' />
      <h1
        className='z-0 uppercase text-xl'
        style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
        {title}
      </h1>
      <h2 className='z-0 text-base'>{text}</h2>
    </div>
  );
};
