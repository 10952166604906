/* This example requires Tailwind CSS v2.0+ */
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function PrivacyBanner() {
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (agreed) {
      window.localStorage.setItem('findmeaningfulworkBannerAgreed', 'true');
    }
  }, [agreed, setAgreed]);

  if (agreed) {
    return null;
  }

  return (
    <div className='fixed bottom-0 inset-x-0 pb-2 sm:pb-5'>
      <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='p-2 rounded-lg bg-crimson-default shadow-lg sm:p-3'>
          <div className='flex items-center justify-between flex-wrap'>
            <div className='w-0 flex-1 flex items-center'>
              <span className='flex p-2 rounded-lg bg-gold'>
                <SpeakerphoneIcon
                  className='h-6 w-6 text-white'
                  aria-hidden='true'
                />
              </span>
              <p className='ml-3 font-medium text-white truncate'>
                <span className='md:hidden'>Privacy and Cookie Policy</span>
                <span className='hidden md:inline'>
                  By using this website you agree to the Terms and Service
                </span>
              </p>
            </div>
            <div className='order-3 mt-2 flex flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto'>
              <Link
                to='/terms'
                className='flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-indigo-50'>
                Learn more
              </Link>
              <button
                className='ml-2 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-gold hover:bg-indigo-50'
                onClick={() => setAgreed(true)}>
                Agree
              </button>
            </div>
            <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-2'>
              <button
                type='button'
                className='-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white'>
                <span className='sr-only'>Agree</span>
                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
