import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CallingIcon,
  CareerIcon,
  HigherCallingIcon,
  JobIcon,
} from '../assets/icons';
import CTA from '../components/LearnMoreCTA';
import { WorkPageTurner } from '../components/WorkPageTurner';
import { Store } from '../redux/store';

export const WorkMeaningsPage = () => {
  const lang = useSelector((state: Store) => state.lang);

  return (
    <div className='overflow-hidden bg-black'>
      <div
        className='bg-cover'
        style={{ backgroundImage: 'url("/laptop.png")' }}>
        <div
          className='p-5 md:p-24'
          style={{
            background:
              'linear-gradient(180deg, rgba(35, 35, 35, 0.7) 0%, rgba(77, 64, 48, 0.7) 100%)',
            backdropFilter: 'blur(30px)',
          }}>
          <h1
            className='text-gold text-4xl'
            style={{
              fontFamily: 'Georgia',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}>
            {lang === 'en' ? 'Work Meanings' : 'Sentidos del trabajo'}
          </h1>
          <img className='mt-2' src='/work-motivation-dots.svg' alt='' />
          <div className='mt-10' />
          {/* <p className='text-white text-xl'>
            When I go to work I do it because I expect to: <br /> (motives or
            goals of my motivation)
          </p> */}
          <div className='mt-10' />
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3'>
            {lang === 'en' ? (
              <>
                <Card
                  uppercase
                  title='Job'
                  text='Work as a means to a paycheck'
                  icon={'/noun_Carpenter_23826.svg'}
                />
                <Card
                  uppercase
                  title='Career'
                  text='Work as a means to achieve mastery, success and status'
                  icon={'/climber.svg'}
                />
                <Card
                  uppercase
                  title='Calling'
                  text='Work as a means to grow, serve and contribute to a purpose'
                  icon={'/doctor.svg'}
                />
                <Card
                  uppercase
                  title='Higher Calling'
                  text='Work as a means to a higher transcendent mission'
                  icon={'/noun_Lazy_27162.svg'}
                />
              </>
            ) : (
              <>
                <Card
                  uppercase
                  title='Empleo'
                  text='El trabajo como forma de obtener recursos'
                  icon={'/noun_Carpenter_23826.svg'}
                />
                <Card
                  uppercase
                  title='Carrera'
                  text='El trabajo como forma de lograr competencia, éxito y una posición social '
                  icon={'/climber.svg'}
                />
                <Card
                  uppercase
                  title='VOCACIÓN'
                  text='El trabajo como forma de crecer personalmente, servir y contribuir a un propósito'
                  icon={'/doctor.svg'}
                />
                <Card
                  uppercase
                  title='Llamada MÁS ALTA'
                  text='El trabajo como forma de responder a una misión más elevada'
                  icon={'/noun_Lazy_27162.svg'}
                />
              </>
            )}
          </div>
          {/*  */}
          <div className='mt-20' />
          <WorkPageTurner page={'work-meanings'} />
        </div>
      </div>
      <div className='bg-white'>
        <CTA />
      </div>
    </div>
  );
};

const Card = ({
  uppercase = false,
  title,
  text,
  icon,
}: {
  title: string;
  text: string;
  uppercase?: boolean;
  icon: string;
}) => {
  return (
    <div className='flex flex-col overflow-hidden'>
      <div className=''>
        <div className={`flex flex-col items-center px-0 py-0 sm:px-0 `}>
          {/* <div className={`${bg} flex flex-col items-center px-2 py-2 sm:px-3 `}> */}
          <div className='mt-4' />
          <div className='bg-gold rounded-full p-4 shadow-xl overflow-hidden'>
            <img className='h-15 w-15' src={icon} alt='' />
          </div>
          <div className='mt-3' />
          <h3
            className={`${
              uppercase && 'uppercase'
            } font-bold text-2xl text-emerald text-center`}>
            {title}
          </h3>
        </div>
      </div>
      <div className='text-base text-center text-white flex-grow'>
        <div className='px-4 py-5 sm:p-6'>{text}</div>
      </div>
    </div>
  );
};
