import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

const DOMAINS = [
  {
    title: 'Happiness and Life Satisfaction',
    about: 'How happy and satisfied we think we are',
    icon: 'happiness.svg',
  },
  {
    title: 'Mental and Physical Health',
    about:
      'Dealing with the various ailments that may come our way throughout our lives',
    icon: 'health.svg',
  },
  {
    title: 'Meaning and Purpose',
    about: 'How we view our individual purpose within the world',
    icon: 'meaning.svg',
  },
  {
    title: 'Character and Virtue',
    about: 'How we act towards ourselves and others',
    icon: 'virtue.svg',
  },
  {
    title: 'Close Social Relationship',
    about:
      'Being content and satisfied with friendships, family, and relationships',
    icon: 'social.svg',
  },
  {
    title: 'Financial and Material Stability',
    about:
      'To not worry about safety, food, housing as well as our monthly living expenses',
    icon: 'financial.svg',
  },
];

const DOMAINS_ES = [
  {
    title: 'FELICIDAD Y SATISFACCIÓN EN LA VIDA',
    about: 'Cómo estamos de felices y satisfechos',
    icon: 'happiness.svg',
  },
  {
    title: 'SALUD FÍSICA Y MENTAL',
    about: 'Cómo afrontamos la falta de salud)',
    icon: 'health.svg',
  },
  {
    title: 'SENTIDO Y PROPÓSITO',
    about: 'Cómo vemos nuestro propósito en la vida',
    icon: 'meaning.svg',
  },
  {
    title: 'VIRTUD Y CARÁCTER',
    about: 'Cómo nos comportamos y tratamos a los demás',
    icon: 'virtue.svg',
  },
  {
    title: 'RELACIONES SOCIALES ',
    about:
      'Cómo nos sentimos de satisfechos con nuestras relaciones sociales y familiares',
    icon: 'social.svg',
  },
  {
    title: 'ESTABILIDAD ECONÓMICA',
    about: 'Cómo estamos de preocupados por nuestra seguridad material',
    icon: 'financial.svg',
  },
];

export default function FlourishingDomains({ responses }: { responses: any }) {
  const lang = useSelector((state: Store) => state.lang);
  let avg, domains: any;
  if (responses) {
    avg = responses.reduce((v: any, s: any) => v + s, 0);
    avg = avg / 12;
    domains = [0, 2, 4, 6, 8, 10].map((i) => {
      let s = responses[i] + responses[i + 1];
      s = s / 2;
      return s;
    });
  }

  return (
    <div>
      <div className='list-none'></div>
      {/* <hr /> */}
      {responses && (
        <div className='p-2 shadow rounded-lg'>
          {/* <div className='text-center'>Avg Flourishing Score</div> */}
          <div
            className='p-2 bg-emerald rounded-lg text-center'
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <img
              className='-mt-10 mx-auto object-cover'
              src='/flourishing.svg'
              alt=''
            />
            <div
              className='flex-grow text-white text-4xl'
              style={{
                fontFamily: 'Georgia',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}>
              {lang === 'en' ? (
                'Average Flourishing Score'
              ) : (
                <span>
                  Puntuación media <br /> Desarrollo pleno
                </span>
              )}
            </div>
            {/* @ts-ignore */}
            <div className='text-white'>{avg.toFixed(1)}/10pts</div>
            <img className='text-' src='/flourishing-results-dots.svg' alt='' />
            <p
              className='text-2xl'
              style={{
                color: '#6B583F',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}>
              {lang === 'en' ? 'Your flourishing results' : 'Tus resultados'}
            </p>
          </div>
        </div>
      )}
      {responses ? (
        <h2 className='ml-2 mt-4 mb-2 text-lg'>
          {/* Your Flourishing Domain Results */}
        </h2>
      ) : (
        <h2 className='ml-2 mt-2 mb-2 text-lg'>
          There are six distinct domains of flourishing we cover here.
        </h2>
      )}
      <div className='grid grid-cols-1 md:grid-cols-3 gap-3'>
        {(lang === 'en' ? DOMAINS : DOMAINS_ES).map((domain, i) => (
          <div
            key={domain.title}
            className='flex flex-col p-2 shadow rounded-lg'>
            <div
              className='h-56 justify-end flex flex-col p-2 rounded-lg text-center'
              style={{ backgroundColor: '#C4C4C4' }}>
              <img
                className='p-2 mx-auto h-20 object-cover'
                src={`/${domain.icon}`}
                alt=''
              />
              <div className='flex-grow text-black text-xl'>
                {domain.title.toUpperCase()}
              </div>
              {responses && (
                <div
                  className='mt-3 text-emerald text-2xl font-bold'
                  style={{ fontFamily: 'Georgia' }}>
                  {domains[i].toFixed(1)}/10pts
                </div>
              )}
            </div>
            <div className='flex-grow text-center text-white text-sm'>
              {domain.about}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
