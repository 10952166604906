import WorkCard from '../WorkTypes';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/store';
import { calcMatrixScores } from './WorkMapResultsAEDRH';

interface Props {
  responses: any[];
}

export const WorkMeaningResults = (props: Props) => {
  const { responses } = props;
  const lang = useSelector((state: Store) => state.lang);

  // Relational scores
  const scores: any = calcMatrixScores({
    answers1: responses[1].map((a) => (a < 0 ? 0 : a)),
    answers2: responses[2].map((a) => (a < 0 ? 0 : a)),
  });

  //Job Scores
  let jobScores = { job: 0, career: 0, calling: 0, higher: 0 };
  jobScores.job = (1 * scores.ExtrinsicUseful) / 10;
  jobScores.career =
    (1 *
      (scores.ExtrinsicPleasant +
        scores.IntrinsicPleasant +
        scores.IntrinsicUseful)) /
    30;
  jobScores.calling =
    (1 *
      (scores.ExtrinsicMoral +
        scores.IntrinsicMoral +
        scores.TranscendentMoral +
        scores.TranscendentPleasant +
        scores.TranscendentUseful)) /
    50;
  jobScores.higher =
    (1 *
      (scores.ExtrinsicSpiritual +
        scores.IntrinsicSpiritual +
        scores.TranscendentSpiritual +
        scores.ReligiousSpiritual +
        scores.ReligiousMoral +
        scores.ReligiousPleasant +
        scores.ReligiousUseful)) /
    70;

  // const jobTotal =
  //   jobScores.job + jobScores.career + jobScores.calling + jobScores.higher;

  jobScores.job = 100 * jobScores.job; // / jobTotal;
  jobScores.career = 100 * jobScores.career; // / jobTotal;
  jobScores.calling = 100 * jobScores.calling; // / jobTotal;
  jobScores.higher = 100 * jobScores.higher; // / jobTotal;

  return (
    <div>
      <div
        className='bg-gold py-6 text-center'
        style={{
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <h1
          className='text-4xl'
          style={{
            font: '#6B583F',
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Your results' : 'Tus resultados'}
        </h1>
        <img className='' src='/results-white-dots.svg' alt='' />
        <h2 className='text-white text-2xl'>
          {lang === 'en' ? 'Your work meanings' : 'Tu sentido del trabajo'}
        </h2>
      </div>
      <div className='mt-16'>
        <div className='ml-2 pb-2'>
          <div className='relative mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3'>
            <WorkCard
              title='Job'
              body={jobScores && `${jobScores && jobScores.job.toFixed(1)}%`}
            />
            <WorkCard
              title='Career'
              body={jobScores && `${jobScores && jobScores.career.toFixed(1)}%`}
            />
            <WorkCard
              title='Calling'
              body={
                jobScores && `${jobScores && jobScores.calling.toFixed(1)}%`
              }
            />
            <WorkCard
              title='Higher Calling'
              body={jobScores && `${jobScores && jobScores.higher.toFixed(1)}%`}
            />
          </div>
        </div>
      </div>
      <p className='mt-4 text-sm text-white text-right'>
        {lang === 'en' ? (
          <span>
            There are four meanings of work. <br /> Above are how you scored in
            each of the four meanings.
          </span>
        ) : (
          <span>
            Existen cuatro sentidos del trabajo. Tu puntuación para cada uno
            <br />
            de los sentidos del trabajo aparece arriba.
          </span>
        )}
      </p>
    </div>
  );
};
