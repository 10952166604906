const responses = [
  { value: 0, en: '0 - Never', es: '0 - Nunca' },
  { value: 1, en: '1', es: '1' },
  { value: 2, en: '2', es: '2' },
  { value: 3, en: '3', es: '3' },
  { value: 4, en: '4', es: '4' },
  { value: 5, en: '5', es: '5' },
  { value: 6, en: '6', es: '6' },
  { value: 7, en: '7', es: '7' },
  { value: 8, en: '8', es: '8' },
  { value: 9, en: '9', es: '9' },
  {
    value: 10,
    en: '10 - Always (Every day)',
    es: '10 - Siempre (todos los dias)',
  },
];

export const jobSatisfactionAndFlourishing = {
  title: {
    en: 'Job Satisfaction and Flourishing',
    es: 'Satisfacción en el trabajo y Desarrollo humano pleno',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: 'Questions in this section ask about job satisfaction and different domains contributing to a flourishing human life.',
    es: 'Esta sección contiene preguntas sobre la satisfacción en el trabajo y los diferentes ámbitos que contribuyen a un desarrollo humano pleno.',
  },
  allowSkip: false,
  prompt: {
    en: '',
    es: '',
  },
  questions: [
    {
      en: 'Overall, how satisfied are you with your job?',
      es: 'En general, ¿cómo de satisfecho/a está con su trabajo?',
      responses: [
        {
          value: 1,
          en: '1 - Totally unsatisfied',
          es: '1 - Totalmente insatisfecho/a',
        },
        { value: 2, en: '2', es: '2' },
        { value: 3, en: '3', es: '3' },
        { value: 4, en: '4', es: '4' },
        {
          value: 5,
          en: '5 - Totally satisfied',
          es: '5 - Totalmente satisfecho/a',
        },
      ],
    },
    {
      en: 'Overall, how satisfied are you with life as a whole these days?',
      es: 'En general, ¿cómo está de satisfecho/a con su vida?',
      responses: [
        {
          value: 0,
          en: '0 - Totally unsatisfied',
          es: '0 - Nada satisfecho/a',
        },
        { value: 1, en: '1', es: '1' },
        { value: 2, en: '2', es: '2' },
        { value: 3, en: '3', es: '3' },
        { value: 4, en: '4', es: '4' },
        { value: 5, en: '5', es: '5' },
        { value: 6, en: '6', es: '6' },
        { value: 7, en: '7', es: '7' },
        { value: 8, en: '8', es: '8' },
        { value: 9, en: '9', es: '9' },
        {
          value: 10,
          en: '10 - Totally satisfied',
          es: '10 - Completamente satisfecho/a',
        },
      ],
    },
    {
      en: 'Overall, to what extent do you feel the things you do in your life are worthwhile?',
      es: 'En general, ¿cree que las cosas que hace en la vida valen la pena?',
      responses: [
        { value: 0, en: '0 - Never', es: '0 - Nada' },
        { value: 1, en: '1', es: '1' },
        { value: 2, en: '2', es: '2' },
        { value: 3, en: '3', es: '3' },
        { value: 4, en: '4', es: '4' },
        { value: 5, en: '5', es: '5' },
        { value: 6, en: '6', es: '6' },
        { value: 7, en: '7', es: '7' },
        { value: 8, en: '8', es: '8' },
        { value: 9, en: '9', es: '9' },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Completamente',
        },
      ],
    },
    {
      en: 'I always act to promote good in all circumstances, even in difficult and challenging situation',
      es: 'Procuro actuar bien siempre, también en situaciones más difíciles',
      responses: [
        { value: 0, en: '0 - Never', es: '0 - Nunca' },
        { value: 1, en: '1', es: '1' },
        { value: 2, en: '2', es: '2' },
        { value: 3, en: '3', es: '3' },
        { value: 4, en: '4', es: '4' },
        { value: 5, en: '5', es: '5' },
        { value: 6, en: '6', es: '6' },
        { value: 7, en: '7', es: '7' },
        { value: 8, en: '8', es: '8' },
        { value: 9, en: '9', es: '9' },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
    },
  ],
};
