import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyCvaPERyDfTb1uBEINrJ21qFCRA9P0TTEc',
  authDomain: 'motivation-work.firebaseapp.com',
  databaseURL: 'https://motivation-work.firebaseio.com',
  projectId: 'motivation-work',
  storageBucket: 'motivation-work.appspot.com',
  messagingSenderId: '771904423999',
  appId: '1:771904423999:web:63449d8e88d5425f4f908a',
  measurementId: 'G-61M4EFT7QE',
};

export interface FireBaseClientInterface {
  app: firebase.app.App;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  functions: firebase.functions.Functions;
  analytics: firebase.analytics.Analytics;
}

class FirebaseClient implements FireBaseClientInterface {
  app: firebase.app.App;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  functions: firebase.functions.Functions;
  analytics: firebase.analytics.Analytics;

  constructor() {
    this.app = firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.functions = firebase.functions();
    this.analytics = firebase.analytics();
  }
}

// Use freeze for a singleton pattern
const instance = new FirebaseClient();
Object.freeze(instance);

export default instance;
