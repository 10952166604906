export const demographics = {
  title: {
    en: 'Demographic Information',
    es: 'Segmentación demográfica',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: '',
    es: '',
  },
  allowSkip: false,
  prompt: {
    en: '',
    es: '',
  },
  questions: [{ en: '', es: '', responses: [] }],
};
