import { useSelector } from 'react-redux';
import {
  CallingIcon,
  CareerIcon,
  DottedLines,
  HigherCallingIcon,
  JobIcon,
} from '../assets/icons';
import { Store } from '../redux/store';

const enMap = {
  Job: {
    header: 'Job',
    Icon: '/noun_Carpenter_23826.svg',
    text: 'Work as a means to a paycheck',
    img: '/',
  },
  Career: {
    header: 'Career',
    Icon: '/climber.svg',
    text: 'Work as a means to achieve mastery, success and status',
  },
  Calling: {
    header: 'Calling',
    Icon: '/doctor.svg',
    text: 'Work as a means to grow, serve and contribute to a purpose',
  },
  'Higher Calling': {
    header: 'Higher Calling',
    Icon: '/noun_Lazy_27162.svg',
    text: 'Work as a means to a higher transcendent mission',
  },
};

const esMap = {
  Job: {
    header: 'Empleo',
    Icon: '/noun_Carpenter_23826.svg',
    text: 'El trabajo como forma de obtener recursos',
    img: '/',
  },
  Career: {
    header: 'Carrera',
    Icon: '/climber.svg',
    text: 'El trabajo como forma de lograr competencia, éxito y una posición social',
  },
  Calling: {
    header: 'Vocación',
    Icon: '/doctor.svg',
    text: 'El trabajo como forma de crecer personalmente, servir y contribuir a un propósito',
  },
  'Higher Calling': {
    header: 'Llamada más alta',
    Icon: '/noun_Lazy_27162.svg',
    text: 'El trabajo como forma de responder a una misión más elevada',
  },
};

const WorkCard = ({ title, body }: { title: string; body?: string }) => {
  const lang = useSelector((state: Store) => state.lang);
  let { header, Icon, text } = lang === 'en' ? enMap[title] : esMap[title];

  if (body) {
    return (
      <div className='flex flex-col text-center overflow-hidden '>
        <div className='mx-auto bg-gold rounded-full p-4 shadow-xl overflow-hidden'>
          <img className='h-15 w-15' src={Icon} alt='' />
        </div>
        <h3 className='mt-3 text-xl text-center uppercase text-emerald font-bold'>
          {header}
        </h3>
        {/* <div className='flex flex-col justify-around'> */}
        <div className='flex-grow text-sm text-white px-4 py-5 sm:p-6'>
          {text}
        </div>
        <h2 className='text-emerald text-2xl'>{body}</h2>
        {/* </div> */}
      </div>
    );
  }

  return (
    <div className='flex flex-col overflow-hidden'>
      <div className='pt-2'>
        <div
          className={`flex flex-col border-t-4 items-center mt-2`}
          style={{
            borderStyle: 'dotted',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderImageSource: "url('/home-dots.svg')",
            borderImageSlice: '23% 23%',
            borderImageRepeat: 'round',
          }}>
          <div className='bg-emerald -mt-3 h-5 w-5 shadow-xl rounded-full' />
          <div className='mt-4' />
          <div className='bg-gold rounded-full p-4 shadow-xl overflow-hidden'>
            <img className='h-15 w-15' src={Icon} alt='' />
            {/* <Icon /> */}
          </div>
          <div className='mt-3' />
          <h3 className='text-xl text-center font-bold'>{header}</h3>
        </div>
      </div>
      <div className='text-base text-center flex-grow'>
        {body}
        <div className='px-4 py-5 sm:p-6'>{text}</div>
      </div>
    </div>
  );
};

export default WorkCard;
