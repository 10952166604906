// const scores = categories.map((cat) => ({ [cat]: 0 }));

import { categories } from './categories';
import { survey, survey2 } from './surveys';

export const calcMatrixScores = ({ answers1, answers2 }) => {
  // const s = new Set();
  // survey.forEach((a) => s.add(a.category));
  // const a = [];
  // s.forEach((s) => a.push(s));
  // console.log('categories', a);
  console.log(`answer2: ${answers2}`);

  const scores = {};
  categories.forEach((cat) => (scores[cat] = 0));

  answers1.forEach((a, i) => {
    const cat = survey[i].category;
    scores[cat] += parseInt(a);
  });
  //Religious questions
  answers2.forEach((a, i) => {
    const cat = survey2[i].category;
    scores[cat] += parseInt(a);
  });
  console.log('scores', scores);
  return scores;
};
