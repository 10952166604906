import FirebaseClient from './firebase_client';

export function onAuthStateChange(callback: Function) {
  return FirebaseClient.auth.onAuthStateChanged((user) => {
    if (user) {
      console.log('The user is logged in');
      callback(user);
    } else {
      callback(user);
      console.log('The user is not logged in');
    }
  });
}
