const motivations = {
  title: {
    en: 'Motivations',
    es: 'Motivaciones',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: 'Questions in this Section ask about your general work motivations or what really makes you strive more and work better or harder.',
    es: 'Esta sección contiene preguntas sobre sus motivaciones en el trabajo o los motivos que realmente le llevan a esforzarse más y trabajar mejor o más duro.',
  },
  allowSkip: false,
  prompt: {
    en: 'What really makes me strive more and work better or harder is...',
    es: 'Lo que realmente me hace esforzarme más y trabajar mejor o más duro es…',
  },
  questions: [
    {
      en: 'The desire to please others',
      es: 'El deseo de complacer a los demás',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M5',
        category: 'ExtrinsicPleasant',
      },
    },
    {
      en: 'The desire to be counted on',
      es: 'El deseo de que cuenten conmigo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M6',
        category: 'ExtrinsicPleasant',
      },
    },
    {
      en: 'The desire to be liked',
      es: 'El deseo de caer bien',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M7',
        category: 'ExtrinsicPleasant',
      },
    },
    {
      en: 'The desire to be valued in my workplace',
      es: 'El deseo de que se me valore',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M8',
        category: 'ExtrinsicPleasant',
      },
    },
    {
      en: 'The desire to earn the respect of others ',
      es: 'El deseo de contar con el respeto de los demás',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M9',
        category: 'ExtrinsicMoral',
      },
    },
    {
      en: 'The desire to be valued as a good person by others',
      es: 'El deseo de que se me considere una buena persona ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M10',
        category: 'ExtrinsicMoral',
      },
    },
    {
      en: 'The desire to receive fair and good treatment from others',
      es: 'El deseo de recibir un trato bueno y justo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M11',
        category: 'ExtrinsicMoral',
      },
    },
    {
      en: 'The desire to receive what I deserve at work',
      es: 'El deseo de recibir lo que merezco',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M12',
        category: 'ExtrinsicMoral',
      },
    },
    {
      en: 'The desire to obtain some spiritual good in my work',
      es: 'El deseo de obtener algún bien espiritual',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M13',
        category: 'ExtrinsicSpiritual',
      },
    },
    {
      en: 'The desire to gain inner peace at work',
      es: 'El deseo de obtener paz interior ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M14',
        category: 'ExtrinsicSpiritual',
      },
    },
    {
      en: 'The desire to integrate the spiritual dimension in my work',
      es: 'El deseo de hacer presente la dimensión espiritual en mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M15',
        category: 'ExtrinsicSpiritual',
      },
    },
    {
      en: 'The desire to gain spiritual goods from my work',
      es: 'El deseo de conseguir dones o gracias espirituales ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M16',
        category: 'ExtrinsicSpiritual',
      },
    },
    {
      en: 'The desire to make money',
      es: 'El deseo de ganar dinero',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M1',
        category: 'ExtrinsicUseful',
      },
    },
    {
      en: 'The desire to keep the job',
      es: 'El deseo de conservar el trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M2',
        category: 'ExtrinsicUseful',
      },
    },
    {
      en: 'The desire to have financial stability',
      es: 'El deseo de tener estabilidad económica ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M3',
        category: 'ExtrinsicUseful',
      },
    },
    {
      en: 'The desire to maintain a certain standard of living',
      es: 'El deseo de mantener un cierto nivel de vida',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M4',
        category: 'ExtrinsicUseful',
      },
    },
    {
      en: 'The desire to do what I like',
      es: 'El deseo de hacer lo que me gusta',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M21',
        category: 'IntrinsicPleasant',
      },
    },
    {
      en: 'The desire to feel good about myself',
      es: 'El deseo de sentirme bien',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M22',
        category: 'IntrinsicPleasant',
      },
    },
    {
      en: 'The desire to feel fulfilled at work',
      es: 'El deseo de sentirme realizado',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M23',
        category: 'IntrinsicPleasant',
      },
    },
    {
      en: 'The desire to feel satisfied with my work',
      es: 'El deseo de sentirme satisfecho',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M24',
        category: 'IntrinsicPleasant',
      },
    },
    {
      en: 'The desire to become a better person every day at work',
      es: 'El deseo de convertirme en una mejor persona trabajando',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M25',
        category: 'IntrinsicMoral',
      },
    },
    {
      en: 'The desire to put my own values into practice in daily work',
      es: 'El deseo de poner en práctica mis valores en el trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M26',
        category: 'IntrinsicMoral',
      },
    },
    {
      en: 'The desire to do my duty at work, to do well',
      es: 'El deseo de cumplir con mi deber, de trabajar bien',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M27',
        category: 'IntrinsicMoral',
      },
    },
    {
      en: 'The desire to grow morally as person at work',
      es: 'El deseo de crecer moralmente como persona trabajando',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M28',
        category: 'IntrinsicMoral',
      },
    },
    {
      en: 'The desire to grow spiritually through my work',
      es: 'El deseo de crecer espiritualmente trabajando',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M29',
        category: 'IntrinsicSpiritual',
      },
    },
    {
      en: 'The desire to live a meaningful life through my work',
      es: 'El deseo de vivir una vida llena de sentido trabajando',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M30',
        category: 'IntrinsicSpiritual',
      },
    },
    {
      en: 'The desire to achieve inner harmony through work',
      es: 'El deseo de alcanzar armonía interior a través del trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M31',
        category: 'IntrinsicSpiritual',
      },
    },
    {
      en: 'The desire to achieve inner peace through my work',
      es: 'El deseo de alcanzar paz interior a través del trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M32',
        category: 'IntrinsicSpiritual',
      },
    },
    {
      en: 'The desire to practice my skills and knowledge on the job',
      es: 'El deseo de poner en práctica mis habilidades y conocimientos',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M17',
        category: 'IntrinsicUseful',
      },
    },
    {
      en: 'The desire to be competent at work',
      es: 'El deseo de ser competente',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M18',
        category: 'IntrinsicUseful',
      },
    },
    {
      en: 'The desire to grow in experience',
      es: 'El deseo de crecer en experiencia',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M19',
        category: 'IntrinsicUseful',
      },
    },
    {
      en: 'The desire not to fail in what I do',
      es: 'El deseo de no fallar en lo que hago',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M20',
        category: 'IntrinsicUseful',
      },
    },
    {
      en: 'The desire to make life pleasant for others with my work',
      es: 'El deseo de hacer la vida agradable a los demás con mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M37',
        category: 'TranscendentPleasant',
      },
    },
    {
      en: 'The desire to contribute to a good working environment',
      es: 'El deseo de contribuir a un buen ambiente de trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M38',
        category: 'TranscendentPleasant',
      },
    },
    {
      en: 'The desire to make my coworkers have a good time',
      es: 'El deseo de hacer que mis compañeros de trabajo estén satisfechos',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M39',
        category: 'TranscendentPleasant',
      },
    },
    {
      en: 'The desire to help my work colleagues feel at easy at work',
      es: 'El deseo de ayudar a que mis compañeros de trabajo estén contentos',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M40',
        category: 'TranscendentPleasant',
      },
    },
    {
      en: 'The desire to do good through my work',
      es: 'El deseo de hacer el bien a los demás a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M41',
        category: 'TranscendentMoral',
      },
    },
    {
      en: 'The desire to contribute to the common good through my work',
      es: 'El deseo de contribuir al bien común a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M42',
        category: 'TranscendentMoral',
      },
    },
    {
      en: 'The desire to contribute to my work colleagues to become better people',
      es: 'El deseo de ayudar a mis compañeros a ser mejores personas',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M43',
        category: 'TranscendentMoral',
      },
    },
    {
      en: 'The desire to contribute to the good of society with my work',
      es: 'El deseo de contribuir con mi trabajo al bienestar de la sociedad ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M44',
        category: 'TranscendentMoral',
      },
    },
    {
      en: 'The desire for spiritual good to grow in others',
      es: 'El deseo de contribuir con mi trabajo al bien espiritual de los demás',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M45',
        category: 'TranscendentSpiritual',
      },
    },
    {
      en: 'The desire for others to find inner peace',
      es: 'El deseo de que los demás encuentren paz interior',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M46',
        category: 'TranscendentSpiritual',
      },
    },
    {
      en: 'The desire for others to find greater meaning in their lives',
      es: 'El deseo de contribuir a que los demás encuentren más sentido a sus vidas',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M47',
        category: 'TranscendentSpiritual',
      },
    },
    {
      en: 'The desire to foster a higher spiritual meaning in others',
      es: 'El deseo de promover un sentido espiritual más alto en el trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M48',
        category: 'TranscendentSpiritual',
      },
    },
    {
      en: 'The desire to help others with my work',
      es: 'El deseo de ayudar a otras personas',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M33',
        category: 'TranscendentUseful',
      },
    },
    {
      en: "The desire to contribute to my family's future",
      es: 'El deseo de contribuir al bienestar futuro de los demás y de mi familia',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M34',
        category: 'TranscendentUseful',
      },
    },
    {
      en: 'The desire to serve others',
      es: 'El deseo de servir a los demás',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M35',
        category: 'TranscendentUseful',
      },
    },
    {
      en: 'The desire to be useful to others with my work',
      es: 'El deseo de ser útil para los demás',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M36',
        category: 'TranscendentUseful',
      },
    },
  ],
};

const questionsToInclude = [
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '13',
  '15',
  '16',
  '1',
  '3',
  '4',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '29',
  '31',
  '32',
  '17',
  '18',
  '19',
  '37',
  '39',
  '40',
  '41',
  '42',
  '43',
  '45',
  '46',
  '48',
  '33',
  '35',
  '36',
  '53',
  '54',
  '55',
  '57',
  '59',
  '60',
  '62',
  '63',
  '64',
  '50',
  '51',
  '52',
].map((q) => `m${q}`);

motivations.questions = motivations.questions.filter((x) => {
  return questionsToInclude.includes(x.meta.code.toLowerCase());
});

export { motivations };
