export const survey = [
  {
    code: 'M5',
    q: 'The desire to please others',
    category: 'ExtrinsicPleasant',
  },
  {
    code: 'M6',
    q: 'The desire to be counted on',
    category: 'ExtrinsicPleasant',
  },
  { code: 'M7', q: 'The desire to be liked', category: 'ExtrinsicPleasant' },
  {
    code: 'M8',
    q: 'The desire to be valued in my workplace',
    category: 'ExtrinsicPleasant',
  },
  {
    code: 'M9',
    q: 'The desire to earn the respect of others ',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M10',
    q: 'The desire to be valued as a good person by others',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M11',
    q: 'The desire to receive fair and good treatment from others',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M12',
    q: 'The desire to receive what I deserve at work',
    category: 'ExtrinsicMoral',
  },
  {
    code: 'M13',
    q: 'The desire to obtain some spiritual good in my work',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M14',
    q: 'The desire to gain inner peace at work',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M15',
    q: 'The desire to integrate the spiritual dimension in my work',
    category: 'ExtrinsicSpiritual',
  },
  {
    code: 'M16',
    q: 'The desire to gain spiritual goods from my work',
    category: 'ExtrinsicSpiritual',
  },
  { code: 'M1', q: 'The desire to make money', category: 'ExtrinsicUseful' },
  { code: 'M2', q: 'The desire to keep the job', category: 'ExtrinsicUseful' },
  {
    code: 'M3',
    q: 'The desire to have financial stability',
    category: 'ExtrinsicUseful',
  },
  {
    code: 'M4',
    q: 'The desire to maintain a certain standard of living',
    category: 'ExtrinsicUseful',
  },

  {
    code: 'M21',
    q: 'The desire to do what I like',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M22',
    q: 'The desire to feel good about myself',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M23',
    q: 'The desire to feel fulfilled at work',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M24',
    q: 'The desire to feel satisfied with my work',
    category: 'IntrinsicPleasant',
  },
  {
    code: 'M25',
    q: 'The desire to become a better person every day at work',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M26',
    q: 'The desire to put my own values into practice in daily work',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M27',
    q: 'The desire to do my duty at work, to do well',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M28',
    q: 'The desire to grow morally as person at work',
    category: 'IntrinsicMoral',
  },
  {
    code: 'M29',
    q: 'The desire to grow spiritually through my work',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M30',
    q: 'The desire to live a meaningful life through my work',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M31',
    q: 'The desire to achieve inner harmony through work',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M32',
    q: 'The desire to achieve inner peace through my work',
    category: 'IntrinsicSpiritual',
  },
  {
    code: 'M17',
    q: 'The desire to practice my skills and knowledge on the job',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M18',
    q: 'The desire to be competent at work',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M19',
    q: 'The desire to grow in experience',
    category: 'IntrinsicUseful',
  },
  {
    code: 'M20',
    q: 'The desire not to fail in what I do',
    category: 'IntrinsicUseful',
  },

  {
    code: 'M37',
    q: 'The desire to make life pleasant for others with my work',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M38',
    q: 'The desire to contribute to a good working environment',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M39',
    q: 'The desire to make my coworkers have a good time',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M40',
    q: 'The desire to help my work colleagues feel at easy at work',
    category: 'TranscendentPleasant',
  },
  {
    code: 'M41',
    q: 'The desire to do good through my work',
    category: 'TranscendentMoral',
  },
  {
    code: 'M42',
    q: 'The desire to contribute to the common good through my work',
    category: 'TranscendentMoral',
  },
  {
    code: 'M43',
    q: 'The desire to contribute to my work colleagues to become better people',
    category: 'TranscendentMoral',
  },
  {
    code: 'M44',
    q: 'The desire to contribute to the good of society with my work',
    category: 'TranscendentMoral',
  },
  {
    code: 'M45',
    q: 'The desire for spiritual good to grow in others',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M46',
    q: 'The desire for others to find inner peace',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M47',
    q: 'The desire for others to find greater meaning in their lives',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M48',
    q: 'The desire to foster a higher spiritual meaning in others',
    category: 'TranscendentSpiritual',
  },
  {
    code: 'M33',
    q: 'The desire to help others with my work',
    category: 'TranscendentUseful',
  },
  {
    code: 'M34',
    q: "The desire to contribute to my family's future",
    category: 'TranscendentUseful',
  },
  {
    code: 'M35',
    q: 'The desire to serve others',
    category: 'TranscendentUseful',
  },
  {
    code: 'M36',
    q: 'The desire to be useful to others with my work',
    category: 'TranscendentUseful',
  },
];

export const survey2 = [
  {
    code: 'M53',
    q: 'The desire to please God with my work',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M54',
    q: 'The desire to delight God with my work',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M55',
    q: 'The desire to satisfy God with my work',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M56',
    q: 'The desire to thank God through my work',
    category: 'ReligiousPleasant',
  },
  {
    code: 'M57',
    q: 'The desire to do my work well as an offering to God',
    category: 'ReligiousMoral',
  },
  {
    code: 'M58',
    q: 'The desire to use well the talents I received from God in my work',
    category: 'ReligiousMoral',
  },
  {
    code: 'M59',
    q: 'The desire to give back to God through work the gifts I received',
    category: 'ReligiousMoral',
  },
  {
    code: 'M60',
    q: 'The desire to give God the fruits of my labor',
    category: 'ReligiousMoral',
  },
  {
    code: 'M61',
    q: 'The desire to praise God with my work',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M62',
    q: "The desire to show God's goodness through my work",
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M63',
    q: "The desire to show God's greatness through my work",
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M64',
    q: 'The desire to give glory to God with my work',
    category: 'ReligiousSpiritual',
  },
  {
    code: 'M49',
    q: "The desire to do God's will with my work",
    category: 'ReligiousUseful',
  },
  {
    code: 'M50',
    q: 'The desire to serve God with my work',
    category: 'ReligiousUseful',
  },
  {
    code: 'M51',
    q: 'The desire to do what God expects of me at work',
    category: 'ReligiousUseful',
  },
  {
    code: 'M52',
    q: 'The desire to be faithful to God through my work',
    category: 'ReligiousUseful',
  },
];

export const FlourishingV2 = [
  {
    code: '65',
    q: 'Overall, how satisfied are you with life as a whole these days?',
    category: '',
  },
  {
    code: '69',
    q: 'Overall, to what extent do you feel the things you do in your life are worthwhile? ',
    category: '',
  },
];

export const Flourishing = [
  {
    code: '65',
    q: 'Overall, how satisfied are you with life as a whole these days?',
    category: '',
  },
  {
    code: '66',
    q: 'In general, how happy or unhappy do you usually feel?',
    category: '',
  },
  {
    code: '67',
    q: 'In general, how would you rate your physical health? ',
    category: '',
  },
  {
    code: '68',
    q: 'How would you rate your overall mental health? ',
    category: '',
  },
  {
    code: '69',
    q: 'Overall, to what extent do you feel the things you do in your life are worthwhile? ',
    category: '',
  },
  { code: '70', q: 'I understand my purpose in life', category: '' },
  {
    code: '71',
    q: 'I always act to promote good in all circumstances, even in difficult and challenging situations.',
    category: '',
  },
  {
    code: '72',
    q: 'I am always able to give up some happiness now for greater happiness later. ',
    category: '',
  },
  {
    code: '73',
    q: 'I am content with my friendships and relationships',
    category: '',
  },
  {
    code: '74',
    q: 'My relationships are as satisfying as I would want them to be',
    category: '',
  },
  {
    code: '75',
    q: 'How often do you worry about being able to meet normal monthly living expenses? ',
    category: '',
  },
  {
    code: '76',
    q: 'How often do you worry about safety, food, or housing? ',
    category: '',
  },
];
