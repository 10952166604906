import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/store';
import StatCard from '../StatCard';
import { aedrh as survey } from '../../data/surveys';

export const calcMotivationGoodScores = ({ answers1, answers2 }) => {
  // const s = new Set();
  // survey.forEach((a) => s.add(a.category));
  // const a = [];
  // s.forEach((s) => a.push(s));
  // console.log('categories', a);
  // console.log(`answer2: ${answers2}`);
  let good = {
    useful: 0,
    pleasant: 0,
    moral: 0,
    spiritual: 0,
  };
  let motivation = {
    extrinsic: 0,
    intrinsic: 0,
    transcendent: 0,
    religious: 0,
  };

  let scores = { good, motivation };

  // Main motivation questions
  answers1.forEach((a, i) => {
    const cat = survey.sections[1].questions[i].meta.category;
    scores = map2GoodOrMotivation({
      answer: a,
      category: cat,
      good,
      motivation,
    });
  });

  //Religious questions
  answers2.forEach((a, i) => {
    const cat = survey.sections[2].questions[i].meta.category;
    scores = map2GoodOrMotivation({
      answer: a,
      category: cat,
      good,
      motivation,
    });
  });
  console.log('scores', scores);

  return scores;
};

const map2GoodOrMotivation = ({ answer, category, good, motivation }) => {
  if (category.includes('Useful')) {
    good.useful += answer;
  } else if (category.includes('Pleasant')) {
    good.pleasant += answer;
  } else if (category.includes('Moral')) {
    good.moral += answer;
  } else if (category.includes('Spiritual')) {
    good.spiritual += answer;
  }
  //
  if (category.includes('Extrinsic')) {
    motivation.extrinsic += answer;
  } else if (category.includes('Intrinsic')) {
    motivation.intrinsic += answer;
  } else if (category.includes('Transcendent')) {
    motivation.transcendent += answer;
  } else if (category.includes('Religious')) {
    motivation.religious += answer;
  }
  return { good, motivation };
};

interface Props {
  responses: any[];
}

export const WorkMotivationResults = (props: Props) => {
  const { responses } = props;
  const lang = useSelector((state: Store) => state.lang);

  const mainScores = calcMotivationGoodScores({
    answers1: responses[1].map((a) => (a < 0 ? 0 : a)),
    answers2: responses[2].map((a) => (a < 0 ? 0 : a)),
  });

  // mainScores = calcMotivationGoodScores({
  //   answers1: answers.survey,
  //   answers2: answers.survey2,
  // });
  const totals = { good: 0, motivation: 0 };
  totals.good = Object.keys(mainScores.good).reduce(
    (sum, key) => sum + mainScores.good[key],
    0,
  );
  totals.motivation = Object.keys(mainScores.motivation).reduce(
    (sum, key) => sum + mainScores.motivation[key],
    0,
  );

  const statDenom = 12;
  const percentDenom = statDenom * 10;

  console.log('main scores', mainScores);

  return (
    <div className=''>
      <div
        className='bg-gold py-2 text-center'
        style={{
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <h1
          className='text-4xl'
          style={{
            font: '#6B583F',
            fontFamily: 'Georgia',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}>
          {lang === 'en' ? 'Your Results' : 'Tus resultados'}
        </h1>
        <img className='' src='/results-white-dots.svg' alt='' />
        <h2 className='text-white text-2xl'>
          {lang === 'en'
            ? 'Your work motivation'
            : 'Tu motivación en el trabajo'}
        </h2>
      </div>
      {/* <h2 className='leading-8 text-2xl text-left bg-white px-0 py-2'>
          {mainScores ? 'Your Work Motivation' : 'Work Motivation'}
        </h2> */}
      {/* COLUMNS */}
      <h3 className='text-white text-2xl py-2'>
        {lang === 'en'
          ? 'When I go to work I do it because I expect to:'
          : 'Cuando voy a trabajar lo hago porque busco: '}
      </h3>
      {/* {!showResults && (
          <p className='text-center'>
            <em>(motives or goals of my motivation)</em>
          </p>
        )} */}
      <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
        <StatCard
          // title='Extrinsic'
          title={lang === 'en' ? 'Receive' : 'RECIBIR'}
          about={lang === 'en' ? 'something good' : 'algo bueno'}
          stat={
            (mainScores && mainScores.motivation.extrinsic / statDenom).toFixed(
              1,
            )
            // mainScores && (mainScores.motivation.extrinsic / 1).toFixed(1)
          }
          icon='/receive.svg'
          percent={
            mainScores &&
            ((100 * mainScores.motivation.extrinsic) / percentDenom).toFixed(1)
          }
        />
        <StatCard
          title={lang === 'en' ? 'Achieve' : 'LOGRAR'}
          about={lang === 'en' ? 'something good' : 'algo bueno'}
          stat={mainScores && (mainScores.motivation.intrinsic / 1).toFixed(1)}
          icon='/achieve.svg'
          percent={
            mainScores &&
            ((100 * mainScores.motivation.intrinsic) / percentDenom).toFixed(1)
          }
        />
        <StatCard
          title={lang === 'en' ? 'Give' : 'DAR'}
          about={lang === 'en' ? 'something good' : 'algo bueno'}
          stat={
            mainScores &&
            (mainScores.motivation.transcendent / statDenom).toFixed(1)
          }
          icon='/give.svg'
          percent={
            mainScores &&
            ((100 * mainScores.motivation.transcendent) / percentDenom).toFixed(
              1,
            )
          }
        />
        <StatCard
          title={lang === 'en' ? 'Give back' : 'DEVOLVER'}
          // title='Religious'
          about={lang === 'en' ? 'something good' : 'algo bueno'}
          stat={
            mainScores &&
            (mainScores.motivation.religious / statDenom).toFixed(1)
          }
          icon='/give-back.svg'
          percent={
            mainScores &&
            ((100 * mainScores.motivation.religious) / percentDenom).toFixed(1)
          }
        />
      </div>
      {/*  */}
      <h3 className='mt-10 text-white text-2xl py-2'>
        {lang === 'en'
          ? 'When I work I do it as a way to get some:'
          : 'Cuando trabajo lo hago como una forma de obtener: '}
      </h3>
      {/* {!showResults && (
        <p className='text-center'>
          <em>(kind of good that we pursue when we work)</em>
        </p>
      )} */}
      <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
        <StatCard
          title={lang === 'en' ? 'Useful' : 'ÚTIL'}
          about={lang === 'en' ? 'good' : 'un bien'}
          secondaryText='(utility and practicality)'
          stat={mainScores && (mainScores.good.useful / statDenom).toFixed(1)}
          icon='/useful.svg'
          percent={
            mainScores &&
            ((100 * mainScores.good.useful) / percentDenom).toFixed(1)
          }
          version={lang === 'en' ? 1 : 2}
        />
        <StatCard
          title={lang === 'en' ? 'Pleasant' : 'AGRADABLE'}
          about={lang === 'en' ? 'good' : 'un bien'}
          secondaryText='(enjoyment and satisfaction)'
          stat={mainScores && (mainScores.good.pleasant / statDenom).toFixed(1)}
          icon='/pleasant.svg'
          percent={
            mainScores &&
            ((100 * mainScores.good.pleasant) / percentDenom).toFixed(1)
          }
          version={lang === 'en' ? 1 : 2}
        />
        <StatCard
          title={lang === 'en' ? 'Moral' : 'MORAL'}
          about={lang === 'en' ? 'good' : 'un bien'}
          secondaryText='(human growth and betterment)'
          stat={mainScores && (mainScores.good.moral / statDenom).toFixed(1)}
          icon='/moral.svg'
          percent={
            mainScores &&
            ((100 * mainScores.good.moral) / percentDenom).toFixed(1)
          }
          version={lang === 'en' ? 1 : 2}
        />
        <StatCard
          title={lang === 'en' ? 'Spiritual' : 'ESPIRITUAL'}
          about={lang === 'en' ? 'good' : 'un bien'}
          secondaryText='(higher meaning and fulfillment)'
          stat={
            mainScores && (mainScores.good.spiritual / statDenom).toFixed(1)
          }
          icon='/spiritual.svg'
          percent={
            mainScores &&
            ((100 * mainScores.good.spiritual) / percentDenom).toFixed(1)
          }
          version={lang === 'en' ? 1 : 2}
        />
      </div>
    </div>
  );
};
