// import and then export the other files in this directory
import { demographics } from './demographics';
import { jobSatisfactionAndFlourishing } from './job_satisfaction_and_flourishing';
import { motivations } from './motivations';
import { organizationalPurpose } from './organizational_purpose';
import { religiousMotivations } from './religious_motivations';
import { workEngagement } from './work_engagement';

export const aedrh = {
  organizationalPurpose,
  motivations,
  religiousMotivations,
  workEngagement,
  jobSatisfactionAndFlourishing,
  demographics,
};
export default aedrh;
