export const Footer = () => {
  // Have to not show the footer on Carlos' page(s)
  if (
    window.location.host.includes('purpose') ||
    window.location.pathname.includes('purpose') ||
    window.location.pathname.includes('aedrh')
  ) {
    return null;
  }

  return (
    <div className='relative py-20 px-4' style={{ backgroundColor: '#D3D3D3' }}>
      <div className='grid grid-cols-4 gap-8'>
        <div className='col-span-3'>
          <div className=''>
            <img className='h-20' src='/media/IECO-LOGO.png' alt='IECO logo' />
            <div className='mt-3 max-w-md font-open-sans-bold text-ieco-2'>
              Instituto para la ética en la comunicación y las organizaciones
              con el objetivo de mejorar la sociedad desde la ética
            </div>
          </div>

          <div className='pt-4' />

          <div className='mt-10 mx-auto flex flex-wrap'>
            {[
              { img: '/media/logo-UV-horizontal.png', to: '#' },
              { img: '/HFP_LogoText.png', to: 'https://hfh.fas.harvard.edu/' },

              {
                img: '/media/Harvard_University_logo.svg.png',
                to: 'https://harvard.edu/',
              },
              {
                img: '/media/F.TATIANA_Timagen_horizontal.svg',
                to: '#',
              },
              {
                img: '/LogoAbigailAdams.png',
                to: 'https://www.abigailadamsinstitute.org/',
              },
            ].map((link, i) => {
              return (
                <a
                  key={i}
                  className='block px-4'
                  href={link.to}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    className='object-contain object-center h-20'
                    src={link.img}
                    alt=''
                  />
                </a>
              );
            })}
          </div>
        </div>
        {/* Contact Column */}
        <div className='text-right'>
          <div className='uppercase font-open-sans font-bold text-ieco-crimson'>
            Contact
          </div>
          <div className='text-ieco-2'>
            Correo: info@iecoinstitute.org
            <br />
            Teléfonos: +34 963 922 947 / +34 657 746 067
            <br />
            Dirección: C/ Mar, 26, 2o, 4a – 46003 Valencia, España
          </div>
          <div className='relative mt-10 flex items-center justify-end space-x-4'>
            <BottomIcon
              icon='/media/twitter.svg'
              to='https://twitter.com/IecoInstitute'
              text='Twitter'
            />
            <BottomIcon
              icon='/media/youtube.svg'
              to='https://www.youtube.com/channel/UCqZ4KAUQ8Wx4i_-8TrrUoTw'
              text='YouTube'
            />
            <BottomIcon
              icon='/media/linkedin.svg'
              to='https://www.linkedin.com/company/ieco-institute-for-ethics-in-communication-and-organizations-/'
              text='LinkedIn'
            />
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className='px-4 py-10 text-center'>
        Copyright © 2022 IECO. Todos los derechos reservados. Aviso Legal /
        Política de privacidad
      </div>
    </div>
  );
};

export const BottomIcon = ({ icon, to, text }) => {
  return (
    <a
      className='relative flex flex-col items-center text-center'
      href={to}
      target='_blank'
      rel='noopener noreferrer'>
      <img width='30px' className='' src={icon} alt='' />
    </a>
  );
};
