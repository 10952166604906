const carlosOrgPurposeResponses = [
  {
    value: 1,
    en: '1 - Almost always untrue',
    es: '1 - Casi siempre no es cierto',
  },
  { value: 2, en: '2 - Often untrue', es: '2 - Frecuentemente no es cierto' },
  {
    value: 3,
    en: '3 - Sometimes untrue / Sometimes true',
    es: '3 - A veces no es cierto / A veces es cierto',
  },
  { value: 4, en: '4 - Often true', es: '4 - Frecuentemente es cierto' },
  { value: 5, en: '5 - Almost always true', es: '5 - Casi siempre es cierto' },
];

export const organizationalPurpose = {
  title: {
    en: 'Organizational Purpose',
    es: 'Propósito organizacional',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: 'Questions in this Section ask about your experience in your current organization.',
    es: 'Esta sección contiene preguntas sobre su experiencia en su organización actual.',
  },
  allowSkip: false,
  skipInstructions: {
    en: "If this does not apply to you, just score 0 for each answer or skip the whole section by clicking 'Skip' below.",
    es: "If this does not apply to you, just score 0 for each answer or skip the whole section by clicking 'Skip' below.",
  },
  prompt: {
    en: 'Based on my experience in the organization I belong to...',
    es: 'En base a mi experiencia en la organización a la que pertenezco…',
  },
  questions: [
    {
      en: 'My work has special meaning: this is not just a job',
      es: 'Mi trabajo tiene un sentido especial: no es “simplemente un trabajo”',
      responses: carlosOrgPurposeResponses,
      meta: {},
    },
    {
      en: 'When I look at what we accomplish, I feel a sense of pride',
      es: 'Cuando veo lo que logramos, me siento orgulloso',
      responses: carlosOrgPurposeResponses,
      meta: {},
    },
    {
      en: 'I feel good about the ways we contribute to the community',
      es: 'Me siento bien por la forma en que contribuimos a la sociedad',
      responses: carlosOrgPurposeResponses,
      meta: {},
    },
    {
      en: 'I’m proud to tell others I work here',
      es: 'Me siento orgulloso/a cuando digo que trabajo en esta empresa',
      responses: carlosOrgPurposeResponses,
      meta: {},
    },
  ],
};
