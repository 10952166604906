export const TermsOfService = () => {
  return (
    <div className='p-4 mx-auto max-w-7xl'>
      <header>
        <h1 className='text-5xl'>Terms of Service</h1>
      </header>
      <p>
        This is Beta software. Terms of service and privacy policies will be
        forthcoming.
      </p>
    </div>
  );
};
