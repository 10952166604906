import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Field, Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';

import { doSignInWithGoogle } from '../firebase/sign_in_with_google';
import FirebaseClient from '../firebase/firebase_client';
import { Spinner } from '../components/Spinner';
import { SignUpIcon } from '../assets/icons';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';
import { Link } from 'react-router-dom';

export const SignUpPage = () => {
  const lang = useSelector((state: Store) => state.lang);
  const [errorMsg, setErrorMsg] = useState(null);

  const { currentUser } = FirebaseClient.auth;

  const mutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      FirebaseClient.auth.createUserWithEmailAndPassword(email, password),
    {
      onError: (error: any) => {
        console.error(error);
        setErrorMsg(error?.message);
      },
    },
  );

  if (currentUser && currentUser.uid) {
    return <Redirect to='/' />;
  }

  return (
    <div>
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <div className='min-h-screen bg-gray-50 grid grid-cols-12 py-4 sm:px-6 lg:px-8'>
        <div className='col-span-2 sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900 whitespace-nowrap'>
            {lang === 'en' ? 'SIGN UP' : 'REGISTRARSE'}
          </h2>
        </div>

      <div className='col-span-9 mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <Link className='block mb-10' to='/legal'>
          {lang === 'en'
            ? 'Signing up grants you to receive updates, periodical reports, personalized feedback and specific materials on motivation and meaningful work'
            : 'Registrarse te permite recibir notificaciones, informes periódicos, feedback personalizado y material único sobre motivación y sentido del trabajo'}
        </Link>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values) => {
              const { email, password } = values;
              mutation.mutate({ email, password });
            }}>
            <Form translate='yes'>
              <p className='text-red-500'>{errorMsg}</p>
              <div>
                <label
                  htmlFor='email'
                  className='sr-only block text-sm font-medium leading-5 text-gray-700'>
                  {lang === 'en' ? 'Email address' : 'Correo electrónico'}
                </label>
                <div className='flex items-center mt-1 rounded-md shadow-sm'>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    placeholder={
                      lang === 'en' ? 'Email address' : 'Correo electrónico'
                    }
                    autoComplete='username'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                  <svg
                    className='-ml-10 h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
                      clipRule='evenodd'
                    />
                  </svg>
                </div>
              </div>
              <div>
                <label
                  htmlFor='password'
                  className='sr-only block text-sm font-medium leading-5 text-gray-700'>
                  {lang === 'en' ? 'Password' : 'Contraseña'}
                </label>
                <div className='mt-1 flex items-center rounded-md shadow-sm'>
                  <Field
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    placeholder={lang === 'en' ? 'Password' : 'Contraseña'}
                    required
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                  <svg
                    className='-ml-10 h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                      clipRule='evenodd'
                    />
                  </svg>
                </div>
              </div>
              {/* <button type='submit'>Submit</button> */}
              <button
                type='submit'
                className='mt-2 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-gold'>
                {mutation.isLoading && <Spinner />}
                {lang === 'en' ? 'Sign up' : 'Registrarse'}
              </button>
            </Form>
          </Formik>

          <div className='mt-6'>
            <div className='relative'>
              <div className='absolute inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300'></div>
              </div>
              <div className='relative flex justify-center text-sm leading-5'>
                <span className='px-2 bg-gray-50 text-gray-500'>
                  {lang === 'en' ? 'Or' : 'O'}
                </span>
              </div>
            </div>
            <div className='mt-4'>
              <button
                className='flex content-center bg-white px-2 rounded-lg border w-full'
                onClick={() => doSignInWithGoogle()}>
                <img
                  className='flex-shrink  p-2 h-10 w-10'
                  src='/google.svg'
                  alt='Google Logo'
                />
                <p className='flex-grow p-2'>
                  {lang === 'en'
                    ? 'Sign in with Google'
                    : 'Iniciar sesión con Google'}
                </p>
              </button>
            </div>
          </div>
        <Link
          className='block pt-5 text-sm text-gray-500 underline'
          to='/legal'>
          Legal and Terms of Service Notice
        </Link>
        </div>

      </div>
    </div>
  );
};
