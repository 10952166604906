import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { doSignOut } from '../firebase/sign_out';
import { QuizMenu } from './QuizMenu';
import { AboutMenu } from './AboutMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../redux/store';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';

export const MobileNavBar = ({ currentUser }: { currentUser: any }) => {
  const location = useLocation();
  const [isMenuOpen, setMenuState] = useState(false);
  const [profileDisplayOpen, setProfileDisplayOpen] = useState(false);
  const [showLang, setShowLang] = useState(false);
  // console.log(isMenuOpen);

  const lang = useSelector((state: Store) => state.lang);
  const dispatch = useDispatch();

  return (
    <nav
      className='fixed z-40 top-0 h-14 px-5 w-full flex justify-between items-center lg:hidden shadow-2xl'
      style={{
        backgroundColor: '#E5E5E5',
        filter: 'dropShadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        // backdropFilter: 'blur(10px)',
      }}
    >
      <MainMenu currentUser={currentUser} />
      <div className='flex items-center space-x-5'>
        <div
          className='ml-8 inline-flex items-center px-1 pt-1'
          onMouseEnter={() => setShowLang(true)}
          onMouseLeave={() => setShowLang(false)}
        >
          {lang === 'en' && showLang && (
            <img
              src='/language-en.svg'
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'en' ? 'es' : 'en' },
                });
              }}
            />
          )}
          {lang === 'es' && showLang && (
            <img
              src='/language-es.svg'
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'es' ? 'en' : 'es' },
                });
              }}
            />
          )}
          {!showLang && (
            <img
              className=''
              src={lang === 'en' ? '/english-gray.svg' : '/spanish-gray.svg'}
              alt=''
              onClick={() => {
                dispatch({
                  type: 'SET_LANG',
                  payload: { lang: lang === 'en' ? 'es' : 'en' },
                });
              }}
            />
          )}
        </div>
        <ProfileDropdownPanel account={currentUser} />
      </div>
    </nav>
  );
};

const MainMenu = ({ currentUser }: { currentUser: any }) => {
  const lang = useSelector((state: Store) => state.lang);

  return (
    <Popover className='relative z-40'>
      {({ open }) => {
        return (
          <>
            <Popover.Button className='h-8 text-black'>
              {!open ? (
                <MenuIcon className='h-8 ' />
              ) : (
                <XIcon className='h-8 ' />
              )}
            </Popover.Button>
            {/* <Popover.Overlay
              className={`${
                open ? 'opacity-30 fixed inset-0' : 'opacity-0'
              } bg-black`}
            /> */}
            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              {/*  */}
              {/* FIXME: this doesn't look perfect */}
              <Popover.Panel className='absolute z-40 mt-3 px-2 w-screen max-w-xs bg-gray-100 rounded-xl shadow-xl sm:px-0'>
                {/* Home */}
                <Popover.Button>
                  <Link
                    to='/quiz/instructions'
                    className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'
                  >
                    {lang === 'en' ? 'Home' : 'Inicio'}
                  </Link>
                </Popover.Button>
                <br />
                {/* Quiz */}
                <Popover.Button>
                  <Link
                    to='/quiz'
                    className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'
                  >
                    {lang === 'en' ? 'Quiz' : 'Quiz'}
                  </Link>
                </Popover.Button>

                <div />

                <Popover.Button className='block'>
                  <a
                    className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'
                    href='https://app.iecoinstitute.org/interpret-your-results'
                  >
                    {lang === 'en'
                      ? 'Interpret Your Results'
                      : 'Interpreta tus resultados'}
                  </a>
                </Popover.Button>
                <Popover.Button className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'>
                  <a
                    className='block'
                    href='https://app.iecoinstitute.org/interpret-your-results'
                  >
                    {lang === 'en' ? 'Resources' : 'Recursos'}
                  </a>
                </Popover.Button>
                <Popover.Button className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'>
                  <a
                    className='block'
                    href='https://app.iecoinstitute.org/interpret-your-results'
                  >
                    {lang === 'en' ? 'About Us' : 'Quiénes somos'}
                  </a>
                </Popover.Button>

                {/* <Popover.Button as='div'>
                  <Link
                    to='/book'
                    className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'>
                    {lang === 'es' ? 'Libro' : 'Book'}
                  </Link>
                </Popover.Button> */}
                {/* <Popover.Button as='div'>
                  <Link
                    to='/resources'
                    className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'>
                    {lang === 'es' ? 'Recursos' : 'Resources'}
                  </Link>
                </Popover.Button> */}

                <Popover.Button as='div'>
                  {currentUser && (
                    <Link
                      to='/history'
                      className='block pl-3 pr-4 py-2 text-base font-medium text-gray-600 bg-crimson-50 focus:outline-none focus:text-crimson-800 focus:bg-emerald focus:border-crimson-700 transition duration-150 ease-in-out'
                    >
                      {lang === 'es' ? 'Historial' : 'History'}
                    </Link>
                  )}
                </Popover.Button>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

/**
 *  Profile dropdown panel, show/hide based on dropdown state.
 *  Entering: "transition ease-out duration-200"
 *    From: "transform opacity-0 scale-95"
 *    To: "transform opacity-100 scale-100"
 *  Leaving: "transition ease-in duration-75"
 *    From: "transform opacity-100 scale-100"
 *    To: "transform opacity-0 scale-95"
 */
const ProfileDropdownPanel = ({ account }: { account: any }) => {
  // const dispatch = useDispatch();
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div className='relative'>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className='relative'>
              <img
                className='h-8 w-8 rounded-full'
                src={account?.photoURL || '/media/avatar.svg'}
                alt="User's profile avatar"
              />
            </Menu.Button>

            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              {/*  */}
              <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {!account ? (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/sign-up'
                          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                          role='menuitem'
                        >
                          {lang === 'en' ? 'Sign up' : 'Registrarse'}
                        </Link>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/sign-in'
                          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                          role='menuitem'
                        >
                          {lang === 'en' ? 'Sign in' : 'Iniciar sesión'}
                        </Link>
                      )}
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    {/* Home */}
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/'
                          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                        >
                          {lang === 'en' ? 'Home' : 'Inicio'}
                        </Link>
                      )}
                    </Menu.Item>
                    {/*  */}
                    {/* Account */}
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/account'
                          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                          role='menuitem'
                        >
                          {lang === 'en' ? 'Account' : 'Cuenta'}
                        </Link>
                      )}
                    </Menu.Item>
                    {/* Sign out */}
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => doSignOut()}
                          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                          role='menuitem'
                        >
                          {lang === 'en' ? 'Sign out' : 'Desconectar'}
                        </div>
                      )}
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
