/**
 * spiritual good
 * moral good
 * pleasent good
 * useful good
 *
 * extrinsic motivation
 * intrinsic motivation
 * transcendent motivation
 * religious motivation
 */
export const categories = [
  'ExtrinsicSpiritual',
  'IntrinsicSpiritual',
  'TranscendentSpiritual',
  'ReligiousSpiritual', //

  'IntrinsicMoral',
  'ExtrinsicMoral',
  'TranscendentMoral',
  'ReligiousMoral', //

  'ExtrinsicPleasant',
  'IntrinsicPleasant',
  'TranscendentPleasant',
  'ReligiousPleasant', //

  'ExtrinsicUseful',
  'IntrinsicUseful',
  'TranscendentUseful',
  'ReligiousUseful', //
];
