import FirebaseClient from './firebase_client';

export const saveQuiz = async (responses) => {
  if (!FirebaseClient.auth.currentUser) {
    const response = await FirebaseClient.functions.httpsCallable('saveQuiz')(
      responses,
    );
    console.log('Response: ', response);
    return null;
  }
  const response = await FirebaseClient.functions.httpsCallable('saveUserQuiz')(
    responses,
  );
  console.log('Response: ', response);
};
