import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Store } from '../../redux/store';

export const QuizHero = () => {
  const lang = useSelector((state: Store) => state.lang);
  return (
    <div
      className='relative py-10 bg-cover'
      style={{ backgroundImage: 'url(/media/BN-shutterstock_1029299632.jpg)' }}>
      <div className='absolute bg-gray-800 bg-opacity-75 inset-0 h-full w-full' />
      <div className='relative z-10 px-4 md:px-0'>
        <div className='flex flex-col items-center text-center text-white'>
          {lang === 'en' ? (
            <div className='pt-20'>
              <h1 className='font-oswald text-5xl'>Work motivations</h1>
              <h2 className='font-comforter font-bold text-gold text-7xl'>
                Quiz instructions
              </h2>
              <div className='py-12 space-y-7 max-w-2xl textxl text-white'>
                <p className='font-oswald'>
                  The following quiz will help you identify your motivations at
                  work over the past few days and, from them, assess the meaning
                  you are giving to your work (as a job, a career, a calling or
                  a higher calling). It is expected to last less than 5 minutes.
                </p>
              </div>
            </div>
          ) : (
            <div className='pt-20'>
              <h1 className='text-center text-5xl font-oswald'>
                Motivación en el trabajo
              </h1>
              <h2 className='font-comforter font-bold text-gold text-7xl'>
                Instrucciones sobre la encuesta
              </h2>
              <div className='py-12 space-y-7 max-w-2xl'>
                <p className='font-oswald'>
                  La siguiente encuesta te ayudará a identificar tus
                  motivaciones en el trabajo en los últimos días y, a partir de
                  ellas, a evaluar el sentido que le estás dando a tu trabajo
                  (como un empleo, una carrera, una vocación o una vocación más
                  alta). Tiene una duración prevista de menos de 5 minutos.
                </p>
              </div>
            </div>
          )}
          {/* TODO: begin below || sign in */}
          <Link
            to='/quiz'
            className='ml-2 px-8 py-2 bg-gold font-open-sans font-bold uppercase text-white rounded-full'
            role='button'>
            {lang === 'en' ? 'Measure your motivation' : 'mide tu motivación'}
          </Link>
        </div>
      </div>
    </div>
  );
};
