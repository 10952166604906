// import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { BookCTA } from '../components/BookCTA';
import Hero from '../components/Hero';

export function HomePage() {

  return (
    <div className=''>
      <Helmet>
        <title>Motivations</title>
      </Helmet>

      <Hero />
      <BookCTA />
    </div>
  );
}
