import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

const questions = [
  'At my work, I feel bursting with energy',
  'I am enthusiastic about my job',
  'I am immersed in my work',
];

const questionsES = [
  'En mi trabajo me siento lleno de energía',
  'Estoy entusiasmado con mi trabajo',
  'Estoy inmerso en mi trabajo',
];

/**
 * Work Engagement Questions
 * - using 3 of the 9 question shortened version
 * https://www.wilmarschaufeli.nl/publications/Schaufeli/Test%20Manuals/Test_manual_UWES_English.pdf
 *
 */
const WorkEngagementQuestions = ({ section, handleCompleted }) => {
  const lang = useSelector((state: Store) => state.lang);
  // console.log('questions', questions);

  const numberOfSections = 5;

  const [questionNumber, setQuestionNumber] = useState(0);
  // const [questionNumber, setQuestionNumber] = useState(questions.length - 2);
  const [showTitle, setShowTitle] = useState(true);
  const [answers, setAnswers] = useState(
    new Array(questions.length).fill(null),
  );
  // const [answers, setAnswers] = useState(makeEmptyAnswers());
  console.log('answers', answers);

  const question =
    lang === 'en' ? questions[questionNumber] : questionsES[questionNumber];
  // console.log(question);

  const progress = (100 * (questionNumber + 1)) / questions.length;

  const numbers =
    lang === 'en'
      ? [
          { n: 0, label: '0 - Never' },
          { n: 1, label: '1 - Almost never (a few times a year or less)' },
          { n: 2, label: '2 - Rarely (once a month or less)' },
          { n: 3, label: '3 - Somemtimes (a few times a month)' },
          { n: 4, label: '4 - Often (once a week)' },
          { n: 5, label: '5 - Very Often (a few times a week)' },
          { n: 6, label: '6 - Always (Every day)' },
        ]
      : [
          { n: 0, label: '0 - Nunca' },
          { n: 1, label: '1 - Casi nunca (pocas veces al año)' },
          { n: 2, label: '2 - Algunas veces (una vez al mes o menos)' },
          { n: 3, label: '3 - Regularmente (pocas veces al mes)' },
          { n: 4, label: '4 - Bastante veces (una vez por semana)' },
          { n: 5, label: '5 - Casi siempre (pocas veces por semana)' },
          { n: 6, label: '6 - Siempre (todos los dias)' },
        ];

  const handleNext = (value) => {
    const n = parseInt(value);
    console.log('Handle next', value, n);

    // Exit because complete
    if (questionNumber === questions.length - 1) {
      answers[questionNumber] = n;
      handleCompleted({ answers, section });
    }
    // To the next question
    if (questionNumber < questions.length - 1) {
      answers[questionNumber] = n;
      setAnswers([...answers]);
      setQuestionNumber(questionNumber + 1);
    }
  };

  const handleSkip = () => {
    handleCompleted({
      answers: new Array(questions.length).fill(0),
      section,
    });
  };

  const handleBack = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
    }
  };

  if (showTitle) {
    return (
      <div className=''>
        <div className=''>
          {/* <h1 className='text-gold text-5xl text-center'>
            {lang === 'en' ? 'Work Motivation' : 'Motivación el en trabajo'}
          </h1> */}
          {/* <h2 className='text-2xl text-white text-center italic'>{title}</h2> */}
          <h2 className='text-2xl'>
            {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
          </h2>
          {lang === 'en' ? (
            <div className='mt-10 text-xl'>
              <p>The following statements are about how you feel at work.</p>
              <p className='mt-4'>Press next to start.</p>
            </div>
          ) : (
            <div className='mt-10 text-xl'>
              <p>
                Esta última sección contiene algunas preguntas sobre su trabajo
                y nivel de compromiso.
              </p>
              <p className='mt-4'>Presione siguiente para comenzar</p>
            </div>
          )}

          <div className='flex mt-10'>
            <button
              className='flex  px-8 py-2 bg-gold text-white rounded-full'
              onClick={() => setShowTitle(false)}>
              {lang === 'en' ? <span>Next</span> : <span>Siguiente</span>}
              <svg
                className='flex-shrink-1 h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
            {section === 2 && (
              <button
                className='ml-2 flex px-8 py-2 bg-white text-gold rounded-full'
                onClick={handleSkip}>
                <span>Skip</span>
                <svg
                  className='flex-shrink-1 h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        <div className='ml-2' />
      </div>
    );
  }

  return (
    <div className=''>
      <div className=''>
        {/* <h1 className='text-gold text-5xl'>
          {lang === 'en' ? 'Work Motivation' : 'Motivación en el trabajo'}
        </h1> */}
        {/* <h2 className='text-2xl italic'>{title}</h2> */}
        <h2 className='text-2xl'>
          {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
        </h2>
      </div>
      <div className='mt-10' />
      <h3 className='bg-gold bg-opacity-25 rounded-full py-1 mt-10 text-center text-2xl font-bold'>
        {question}
      </h3>

      <div className='mt-4' />

      <div className=''>
        <ul className='block sm:hidden'>
          {numbers.map((n, i) => {
            return (
              <label
                key={n.n}
                className='flex items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0'>
                <input
                  className='mx-2'
                  type='radio'
                  value={n.n}
                  checked={answers[questionNumber] === n.n}
                  onChange={(event) => handleNext(event.target.value)}
                />
                <span>{n.label}</span>
              </label>
            );
          })}
        </ul>

        <ul className='hidden sm:block'>
          {numbers.map((n, i) => {
            return (
              <label
                key={n.n}
                className='flex items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0'>
                <input
                  className='mx-2'
                  type='radio'
                  value={n.n}
                  checked={answers[questionNumber] === n.n}
                  onChange={(event) => handleNext(event.target.value)}
                />
                <span>{n.label}</span>
              </label>
            );
          })}
        </ul>
      </div>

      {/* PROGRESS BAR */}
      <div
        id='progress'
        className='mt-2 overflow-hiddern border-gold border-2 h-4 mb-4 flex rounded-full overflow-hidden '>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col bg-gold bg-opacity-90'
        />
      </div>
      <p className='mt-4 text-center'>
        {lang === 'en' ? 'Question' : 'Pregunta'} {questionNumber + 1} /{' '}
        {questions.length}
      </p>

      <div className='mt-5'>
        {questionNumber > 0 && (
          <button
            className='flex px-8 py-1 bg-gold rounded-lg text-white text-sm'
            onClick={handleBack}>
            <svg
              className='flex-shrink-1 h-6 w-6'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>

            {lang === 'en' ? <span>Back</span> : <span>Atrás</span>}
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkEngagementQuestions;
