export const religiousMotivations = {
  title: {
    en: 'Religious Motivations',
    es: 'Motivaciones Religiosas',
  },
  subtitle: {
    en: '',
    es: '',
  },
  instructions: {
    en: 'By religious motivations we mean here the kind of spiritual motivation of those who believe in God. Some people do not believe, others do, and that belief may be a motivation in your work. The following questions are only intended to assess to what extent this type of motivation is or is not present in your case when you work, as we have previously asked about spiritual motivations other than these.',
    es: 'Por motivaciones religiosas entendemos aquí el tipo de motivación espiritual de quienes creen en Dios. Algunas personas no creen, otras sí, y esa creencia puede ser una motivación en su trabajo. Las siguientes preguntas sólo pretenden evaluar hasta qué punto este tipo de motivación está o no presente en su caso cuando trabaja, ya que antes hemos preguntado por otro tipo de motivaciones espirituales distintas a éstas.',
  },
  allowSkip: true,
  skipInstructions: {
    en: 'If you do not have these motivations at all, you can always skip the entire section by clicking "Skip" below.',
    es: 'Si no tiene estas motivaciones en absoluto, siempre puede saltarse toda la sección haciendo clic en "Saltar" más abajo.',
  },
  prompt: {
    en: 'What really makes me strive more and work better or harder is...',
    es: 'Lo que realmente me hace esforzarme más y trabajar mejor o más duro es…',
  },
  questions: [
    {
      en: 'The desire to please God with my work',
      es: 'El deseo de complacer a Dios a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M53',
        category: 'ReligiousPleasant',
      },
    },
    {
      en: 'The desire to delight God with my work',
      es: 'El deseo de agradar a Dios con mi trabajo ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M54',
        category: 'ReligiousPleasant',
      },
    },
    {
      en: 'The desire to satisfy God with my work',
      es: 'El deseo de satisfacer a Dios con mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M55',
        category: 'ReligiousPleasant',
      },
    },
    {
      en: 'The desire to do my work well as an offering to God',
      es: 'El deseo de hacer bien mi trabajo como una ofrenda para Dios',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M57',
        category: 'ReligiousMoral',
      },
    },
    {
      en: 'The desire to give back to God through work the gifts I received',
      es: 'El deseo de corresponder a Dios por los dones que me ha regalado',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M59',
        category: 'ReligiousMoral',
      },
    },
    {
      en: 'The desire to give God the fruits of my labor',
      es: 'El deseo de dar a Dios los frutos de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M60',
        category: 'ReligiousMoral',
      },
    },
    {
      en: "The desire to show God's goodness through my work",
      es: 'El deseo de reflejar la bondad de Dios a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M62',
        category: 'ReligiousSpiritual',
      },
    },
    {
      en: "The desire to show God's greatness through my work",
      es: 'El deseo de mostrar la grandeza de Dios a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M63',
        category: 'ReligiousSpiritual',
      },
    },
    {
      en: 'The desire to give glory to God with my work',
      es: 'El deseo de dar honor y gloria a Dios con mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M64',
        category: 'ReligiousSpiritual',
      },
    },
    {
      en: 'The desire to serve God with my work',
      es: 'El deseo de servir a Dios con mi trabajo ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M50',
        category: 'ReligiousUseful',
      },
    },
    {
      en: 'The desire to do what God expects of me at work',
      es: 'El deseo de hacer lo que Dios espera de mí en el trabajo ',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M51',
        category: 'ReligiousUseful',
      },
    },
    {
      en: 'The desire to be faithful to God through my work',
      es: 'El deseo de ser fiel a Dios a través de mi trabajo',
      responses: [
        {
          value: 0,
          en: '0 - Never',
          es: '0 - Nunca',
        },
        {
          value: 1,
          en: '1',
          es: '1',
        },
        {
          value: 2,
          en: '2',
          es: '2',
        },
        {
          value: 3,
          en: '3',
          es: '3',
        },
        {
          value: 4,
          en: '4',
          es: '4',
        },
        {
          value: 5,
          en: '5',
          es: '5',
        },
        {
          value: 6,
          en: '6',
          es: '6',
        },
        {
          value: 7,
          en: '7',
          es: '7',
        },
        {
          value: 8,
          en: '8',
          es: '8',
        },
        {
          value: 9,
          en: '9',
          es: '9',
        },
        {
          value: 10,
          en: '10 - Always',
          es: '10 - Siempre',
        },
      ],
      meta: {
        code: 'M52',
        category: 'ReligiousUseful',
      },
    },
  ],
};
