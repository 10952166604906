import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Store } from '../redux/store';

const flourishingLabels = [
  ['Not Satisfied at All', 'Completely Satisfied'],
  ['Extremely Unhappy', 'Extremely Happy'],
  ['Poor', 'Excellent'],
  ['Poor', 'Excellent'],
  ['Not at All Worthwhile', 'Completely Worthwhile'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Not True of Me', 'Completely True of Me'],
  ['Not True of Me', 'Completely True of Me'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Strongly Disagree', 'Strongly Agree'],
  ['Worry All of the Time', 'Do Not Ever Worry'],
  ['Worry All of the Time', 'Do Not Ever Worry'],
];

const flourishingESLabels = [
  ['Nada satisfecho/a', 'Completamente satisfecho/a'],
  ['Muy infeliz', 'Muy feliz'],
  ['Mala', 'Excelente'],
  ['Mala', 'Excelente'],
  ['No valen la pena para nada', 'Valen la pena completamente'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Nada cierto', 'Completamente cierto'],
  ['Nada cierto', 'Completamente cierto'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Totalmente en desacuerdo', 'Totalmente de acuerdo'],
  ['Nunca me preocupa', 'Me preocupa todo el tiempo'],
  ['Nunca me preocupa', 'Me preocupa todo el tiempo'],
];

const SurveyQuestions = ({ questions, section, handleCompleted }) => {
  const numberOfSections = 5;
  const lang = useSelector((state: Store) => state.lang);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [showTitle, setShowTitle] = useState(true);
  const [answers, setAnswers] = useState(
    new Array(questions.length).fill(null),
  );

  const question = questions[questionNumber];
  const progress = (100 * (questionNumber + 1)) / questions.length;

  const numbers =
    section < 3
      ? [
          { n: 0, label: '0 - Never' },
          { n: 1, label: '1' },
          { n: 2, label: '2' },
          { n: 3, label: '3' },
          { n: 4, label: '4' },
          { n: 5, label: '5' },
          { n: 6, label: '6' },
          { n: 7, label: '7' },
          { n: 8, label: '8' },
          { n: 9, label: '9' },
          { n: 10, label: '10 - Always' },
        ]
      : lang === 'en'
      ? [
          { n: 0, label: `0 - ${flourishingLabels[questionNumber][0]}` },
          { n: 1, label: '1' },
          { n: 2, label: '2' },
          { n: 3, label: '3' },
          { n: 4, label: '4' },
          { n: 5, label: '5' },
          { n: 6, label: '6' },
          { n: 7, label: '7' },
          { n: 8, label: '8' },
          { n: 9, label: '9' },
          { n: 10, label: `10 - ${flourishingLabels[questionNumber][1]}` },
        ]
      : [
          { n: 0, label: `0 - ${flourishingESLabels[questionNumber][0]}` },
          { n: 1, label: '1' },
          { n: 2, label: '2' },
          { n: 3, label: '3' },
          { n: 4, label: '4' },
          { n: 5, label: '5' },
          { n: 6, label: '6' },
          { n: 7, label: '7' },
          { n: 8, label: '8' },
          { n: 9, label: '9' },
          { n: 10, label: `10 - ${flourishingESLabels[questionNumber][1]}` },
        ];

  const title =
    lang === 'en'
      ? section === 1
        ? 'Motivations'
        : section === 2
        ? 'Religious'
        : 'Flourishing'
      : section === 1
      ? 'Motivaciones'
      : section === 2
      ? 'Religiosa'
      : 'Desarrollo humano pleno ';

  const handleNext = (value) => {
    const n = parseInt(value);
    console.log('Handle next', value, n);

    // Exit because complete
    if (questionNumber === questions.length - 1) {
      answers[questionNumber] = n;
      handleCompleted({ answers, section });
    }
    // To the next question
    if (questionNumber < questions.length - 1) {
      answers[questionNumber] = n;
      setAnswers([...answers]);
      setQuestionNumber(questionNumber + 1);
    }
  };

  const handleSkip = () => {
    handleCompleted({
      answers: new Array(questions.length).fill(0),
      section,
    });
  };

  const handleBack = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
    }
  };

  if (showTitle) {
    return (
      <div className=''>
        {lang === 'en' ? (
          <>
            <header className='space-y-2'>
              <h2 className='text-2xl font-bold'>
                Section {section}/{numberOfSections}
              </h2>
              {/* <h2 className='text-2xl'>{title}</h2> */}
              {/* <h1 className='text-5xl'>Work Motivation</h1> */}
            </header>
            <div className='mt-10' />
            <div className='text-xl space-y-5'>
              {section === 1 && (
                <div className='space-y-5'>
                  <p className='leading-8'>
                    Questions in this Section ask about your general work
                    motivations or{' '}
                    <span className='font-bold bg-gold bg-opacity-50 p-1'>
                      what really makes you strive more and work better or
                      harder.
                    </span>
                  </p>
                  <p className=''>Press next to start</p>
                </div>
              )}
              {section === 2 && (
                <div className='space-y-7 text-xl'>
                  <p>
                    For those who have a relationship with God, this
                    relationship can be a motivation in their work and for
                    others it is not.
                  </p>
                  <p>
                    The following questions are only intended to assess the
                    extent to which this type of motivation is or is not present
                    in your case when you work, since we have asked about other
                    types of motivations than these before.
                  </p>
                  <p className='font-bold p-2'>
                    <span className='bg-gold bg-opacity-50 leading-5'>
                      If this does not apply to you, just score 0 for each
                      answer or skip the whole section by clicking 'Skip' below.
                    </span>
                  </p>
                </div>
              )}
              {section === 3 && (
                <div className='space-y-5'>
                  <p>
                    This Section has {questions.length} questions about the
                    different domains contributing to a fulfilling and
                    flourishing human life.
                  </p>
                  <p>
                    They include:{' '}
                    <strong className='font-bold '>
                      Happiness and Life Satisfaction, Mental and Physical
                      Health, Meaning and Purpose, Character and Virtue,
                    </strong>{' '}
                    and{' '}
                    <strong className='font-bold '>
                      Close Social Relationships
                    </strong>
                    . A sixth domain,{' '}
                    <strong className='text-medium font-bold '>
                      Financial and Material Stability
                    </strong>{' '}
                    is an important means to sustain the other domains over
                    time.
                  </p>

                  <p>Press next to start.</p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {/* {'SPANISH '} */}
            <header className='space-y-2'>
              <h2 className='text-2xl font-bold'>
                Sección {section}/{numberOfSections}
              </h2>
              {/* <h2 className='text-2xl italic'>{title}</h2> */}
              {/* <h1 className='text-5xl text-center'>Motivación en el trabajo</h1> */}
            </header>
            <div className='mt-10' />
            <div className='text-xl space-y-5'>
              {section === 1 && (
                <div className='space-y-5'>
                  <p className='leading-8'>
                    Esta sección consta de 48 preguntas sobre sus motivaciones
                    en el trabajo o los motivos{' '}
                    <span className='font-bold bg-gold bg-opacity-50 p-1'>
                      que realmente le llevan a esforzarse más y trabajar mejor
                      o más duro.
                    </span>
                  </p>
                  <p className=''>Presione siguiente para comenzar</p>
                </div>
              )}
              {section === 2 && (
                <div className='space-y-7 text-xl'>
                  <p>
                    En este apartado del cuestionario no se pregunta sobre las
                    ideas religiosas. Para algunas personas la religión o su
                    relación con Dios, puede ser una motivación en su trabajo y
                    para otras no.
                  </p>
                  <p>
                    Las preguntas que vienen a continuación sólo pretenden
                    evaluar en qué medida ese tipo de motivación está o no
                    presente en su vida a la hora de trabajar, como antes hemos
                    preguntado por otro tipo de motivaciones distintas a éstas.
                  </p>
                  <p className='font-bold p-2'>
                    <span className='bg-gold bg-opacity-50 leading-5'>
                      Si esto no se aplica a usted, simplemente marque 0 para
                      cada respuesta u omita toda la sección haciendo “clic” en
                      el botón omitir más abajo.
                    </span>
                  </p>
                </div>
              )}
              {section === 3 && (
                <div className='space-y-5'>
                  <p>
                    Esta sección consta de {questions.length} preguntas sobre los diferentes
                    ámbitos que contribuyen a un desarrollo humano pleno.
                  </p>
                  <p>
                    Éstos incluyen: Felicidad y Satisfacción en la Vida, Salud
                    Física y mental, Sentido y Propósito, Virtud y Carácter, y
                    Relaciones Sociales. El sexto, Estabilidad Económica, es un
                    aspecto importante para mantener los otros ámbitos a lo
                    largo del tiempo.
                  </p>
                  <p>Presione siguiente para comenzar</p>
                </div>
              )}
            </div>
          </>
        )}

        <div className='flex mt-10'>
          <button
            className='flex px-8 py-2 bg-gold text-white rounded-full'
            onClick={() => setShowTitle(false)}>
            <span>{lang === 'en' ? 'Next' : 'Siguiente'}</span>
            <svg
              className='flex-shrink-1 h-6 w-6'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </button>

          {section === 2 && (
            <button
              className='ml-2 flex px-8 py-2 bg-gold bg-opacity-50 text-gray-700 rounded-full'
              onClick={handleSkip}>
              {lang === 'en' ? <span>Skip</span> : <span>Omitir</span>}
              <svg
                className='flex-shrink-1 h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          )}
        </div>
        <div className='ml-2' />
      </div>
    );
  }

  const handleSplit = (question: string) => {
    if (lang === 'es') {
      return ['El deseo de', question.split('El deseo de')[1]];
    }
    if (question.includes('The desire to')) {
      return ['The desire to ', question.split('The desire to')[1]];
    }
    if (question.includes('The desire not to')) {
      return ['The desire not to ', question.split('The desire not to')[1]];
    }
    return ['The desire for', question.split('The desire for')[1]];
  };

  const questionParts = handleSplit(question.q);
  console.log('questionParts', question.q, questionParts);

  return (
    <section className=''>
      <header className=''>
        <h2 className='text-2xl font-bold'>
          {lang === 'en' ? 'Section' : 'Sección'} {section}/{numberOfSections}
        </h2>
        {/* <h1 className='text-5xl'>
          {lang === 'en' ? 'Work Motivation' : 'Motivación en el trabajo'}
        </h1> */}
      </header>
      <div className='mt-10' />
      <p className='text-xl'>
        {lang === 'en'
          ? 'What really makes me strive more and work better or harder is...'
          : 'Lo que realmente me hace esforzarme más y trabajar mejor o más duro es…'}
      </p>

      <h3 className='bg-gold bg-opacity-25 rounded-full py-1 mt-10 text-center text-2xl'>
        <span>{questionParts[0]}</span>
        <span className='text-ieco-2 font-bold'>{questionParts[1]}</span>
      </h3>
      <div className='mt-4' />
      {/* for sections 1,2 */}
      {section !== 3 && (
        // Mobile view
        <div className=''>
          <div className='block sm:hidden hscreen'>
            <label>Select a response below</label>

            {numbers.map((n, i) => {
              return (
                <label
                  key={n.n}
                  className='block items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0 overflow-y-scroll'>
                  <input
                    className='mx-2'
                    type='radio'
                    value={n.n}
                    checked={answers[questionNumber] === n.n}
                    onChange={(event) => handleNext(event.target.value)}
                  />
                  <span>{i}</span>
                  {i === 0 ? (
                    <span className='ml-2'>
                      {lang === 'en' ? 'Never' : 'Nunca'}
                    </span>
                  ) : i === 10 ? (
                    <span className='ml-2'>
                      {lang === 'en' ? 'Always' : 'Siempre'}
                    </span>
                  ) : null}
                </label>
              );
            })}
          </div>
          {/* Desktop view */}
          <ul className='hidden sm:flex justify-around'>
            {numbers.map((n, i) => {
              return (
                <label
                  key={n.n}
                  className='flex flex-col items-center px-2 py-2 active:bg-gold-200 mt-2 md:mt-0'>
                  <input
                    className='mx-2 '
                    type='radio'
                    value={n.n}
                    checked={answers[questionNumber] === n.n}
                    onChange={(event) => handleNext(event.target.value)}
                  />
                  <span>{i}</span>
                  {i === 0 ? (
                    <span>{lang === 'en' ? 'Never' : 'Nunca'}</span>
                  ) : i === 10 ? (
                    <span>{lang === 'en' ? 'Always' : 'Siempre'}</span>
                  ) : null}
                </label>
              );
            })}
          </ul>
        </div>
      )}
      <div
        id='progress'
        className='mt-2 overflow-hiddern border-gold border-2 h-4 mb-4 flex rounded-full overflow-hidden'>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col bg-gold bg-opacity-90'
        />
      </div>
      <p className='mt-4 text-center'>
        {lang === 'en' ? 'Question' : 'Pregunta'} {questionNumber + 1} /{' '}
        {questions.length}
      </p>
      <div className='mt-5'>
        {questionNumber > 0 && (
          <button
            className='flex px-8 py-1 bg-gold rounded-lg text-sm text-white'
            onClick={handleBack}>
            <svg
              className='flex-shrink-1 h-6 w-6'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>
            {lang === 'en' ? <span>Back</span> : <span>Atrás</span>}
          </button>
        )}
      </div>
    </section>
  );
};

export default SurveyQuestions;
