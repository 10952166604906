import { useSelector } from 'react-redux';
import { Store } from '../redux/store';

export const Legal = () => {
  const lang = useSelector((state: Store) => state.lang);

  return (
    <div className='mx-auto max-w-7xl mt-10'>
      {lang === 'en' ? (
        <>
          <p>
            Terms of Service and Privacy Policies can be found at the following
            link:{' '}
            <a
              className='underline'
              href='https://docs.google.com/document/d/e/2PACX-1vQKR9nsZPBJ6-SbzSnxPjwgNChAdfNlE47xZRKuiw-wtRVaoQCCbecsz-_GEA3khQVVJE9V6nwIrJYy/pub'
              target='_blank'
              rel='noopener noreferrer'>
              Terms of Service
            </a>
            .{' '}
          </p>
          <p>We reserve the right to update policies at any time.</p>
        </>
      ) : (
        <>
          <p>
            Las condiciones de uso y las políticas de privacidad están
            disponibles en el siguiente enlace{' '}
            <a
              className='underline'
              href='https://docs.google.com/document/d/e/2PACX-1vQKR9nsZPBJ6-SbzSnxPjwgNChAdfNlE47xZRKuiw-wtRVaoQCCbecsz-_GEA3khQVVJE9V6nwIrJYy/pub'
              target='_blank'
              rel='noopener noreferrer'>
              Aviso legal.
            </a>
            .{' '}
          </p>
          <p>
            Reservado el derecho a actualizar las políticas en cualquier
            momento.
          </p>
        </>
      )}
    </div>
  );
};
