import React, { useEffect, useState } from 'react';
import { NavBar } from './nav/NavBar';
import { MobileNavBar } from './nav/MobileNavBar';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { persistor, store } from './redux/store';
import { Router } from './router/Router';
import { ScrollToTop } from './router/ScrollToTop';

import FirebaseClient from './firebase/firebase_client';
import { onAuthStateChange } from './firebase/on_auth_change';
import { PersistGate } from 'redux-persist/integration/react';
import PrivacyBanner from './components/PrivacyBanner';
import { Footer } from './components/Footer';

// import './styles/index.css';

const queryClient = new QueryClient();

function PageViews() {
  let location = useLocation();
  React.useEffect(() => {
    FirebaseClient.analytics.logEvent('page_view', {
      page_location: location.pathname,
      page_path: window.location.pathname,
    });
  }, [location]);

  return <></>;
}

function DefaultLanguage() {
  const dispatch = useDispatch();
  // Set default language based upon url
  useEffect(() => {
    console.log(window.location);

    if (window.location.host === 'trabajoenbuscadesentido.com') {
      dispatch({
        type: 'SET_LANG',
        payload: { lang: 'es' },
      });
    }

    if (
      window.location.host.includes('aedrh') ||
      window.location.pathname.includes('aedrh')
    ) {
      dispatch({
        type: 'SET_LANG',
        payload: { lang: 'es' },
      });
    }
  }, [dispatch]);
  return <></>;
}

export const App = () => {
  // console.log('Store', store);

  const [agreed, setAgreed] = useState(false);

  const [user, setUser] = useState(FirebaseClient.auth?.currentUser);
  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const item = window.localStorage.getItem('findmeaningfulworkBannerAgreed');
    console.log(item);

    setAgreed(item === 'true');
  }, [agreed, setAgreed]);

  const v2 =
    window.location.host.includes('purpose') ||
    window.location.pathname.includes('purpose') ||
    window.location.pathname.includes('aedrh');
  

  return (
    <BrowserRouter>
      <PageViews />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <DefaultLanguage />
            {/* Contact Banner */}
            <div className='flex justify-between p-4 bg-crimson-default text-white'>
              <div>
                <a href='http://app.iecoinstitute.org/'>
                  {'trabajoenbuscadesentido.com'}
                </a>
              </div>
              <div>{'Contacto'}</div>
            </div>

            {v2 ? null : (
              <>
                <NavBar currentUser={user} />
                <MobileNavBar currentUser={user} />
              </>
            )}

            <div
              className={`${!v2 && 'mt14'} lg:mt-0`}
              style={{ minHeight: '80vh' }}>
              {process.env.NODE_ENV === 'production' && <ScrollToTop />}
              <Router />
            </div>
            {/*  */}
            {!v2 && <Footer />}
            {!agreed && <PrivacyBanner />}
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
