import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SurveyQuestions from '../components/SurveyQuestions';
import Results from '../components/Results';
import { Flourishing, FlourishingV2, survey, survey2 } from '../utils/surveys';
import { FlourishingES, surveyES, survey2ES, FlourishingESV2 } from '../utils/surveys_es';
import { makeEmptyAnswers } from '../utils/random_answers';

import { useDispatch, useSelector } from 'react-redux';
import { saveQuiz } from '../firebase/save_quiz';
import FirebaseClient from '../firebase/firebase_client';
import DemographicQuestions from '../components/DemographicQuestions';
import WorkEngagementQuestions from '../components/WorkEngagementQuestions';
import { Store } from '../redux/store';
import { QuizHero } from '../components/quiz/QuizInstructionsHero';
import FlourishingQuestions from '../components/questions/FlourishingQuestions';

/**
 *
 */
export function QuizPage() {
  // const id = useParams<{id:string}>();
  const lang = useSelector((state: Store) => state.lang);
  const history = useHistory();
  const dispatch = useDispatch();
  const [sequence, setSequence] = useState(1);

  const domain = window.location.host;
  console.log(domain);

  const [answers, setAnswers] = useState(makeEmptyAnswers({ domain }));

  const handleSectionCompleted = (results) => {
    console.log('Completed section: ', results.section, results.answers);
    if (results.section === 1) {
      answers.survey = results.answers;
    }
    if (results.section === 2) {
      answers.survey2 = results.answers;
    }
    if (results.section === 3) {
      answers.flourishing = results.answers;
    }
    if (results.section === 4) {
      answers.workEngagement = results.answers;
    }
    if (results.section === 5) {
      answers.demographics = results.answers;
    }

    setAnswers({ ...answers });
    setSequence(sequence + 1);
  };

  const showResults = () => {
    console.log('Final answers', answers);
    const answersToSubmit = { ...answers, domain };

    saveQuiz(answersToSubmit);

    dispatch({ type: 'SET_RESPONSES', payload: answersToSubmit });
    history.push('/result');
  };

  console.log('Answers', answers);

  return (
    <div className='bg-cover bg-center bg-no-repeat' style={{minHeight:'100vh'}}>
      <div className='relative'>
        <Helmet>
          <title>Quiz</title>
        </Helmet>

        <QuizHero />


        <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <main className='mt-4 max-w-3xl mx-auto'>
            <div className=''>
              {sequence === 0 && (
                <div className='flex'>
                  <div className=''>
                    {/* <h1>Work Motivation Quiz Instructions</h1> */}
                    {/* <hr /> */}
                    <p className='prose'>
                      The following survey aims to{' '}
                      <span className='text-ieco-crimson'>
                        measure your motivations at work
                      </span>{' '}
                      and to assess several important domains of well-being or
                      <span className='text-ieco-crimson'>
                        {' '}
                        human flourishing.
                      </span>
                      <br />
                      It has an expected duration of less than{' '}
                      <span className='text-ieco-crimson'>10 minutes</span>.
                    </p>

                    <div className='flex justify-end'>
                      {!FirebaseClient?.auth?.currentUser && (
                        <Link
                          to='/sign-up'
                          className='float-right border border-crimson-500   px-4 py-2 text-sm text-gray-800 rounded-lg no-underline'>
                          <span className='text-crimson-500'>Sign up</span>
                        </Link>
                      )}
                      <button
                        className='ml-2  bg-crimson-500 hover:bg-crimson-600 px-4 py-2 text-sm text-gray-50 rounded-lg'
                        onClick={() => setSequence(1)}>
                        Ready to begin
                      </button>
                    </div>
                  </div>
                  <div className='ml-2' />
                  <div className='relative md:block'>
                    <img
                      className='absolute left-0 top-0 -mt-10 z-0'
                      src='bg_accent.svg'
                      alt=''
                    />
                    <h1 className='relative z-10 text-3xl leading-9  p-16 text-white'>
                      Work Motivation Quiz Instructions
                    </h1>
                  </div>
                </div>
              )}
              <br />

              {sequence === 1 && (
                <SurveyQuestions
                  questions={lang === 'en' ? survey : surveyES}
                  section={1}
                  handleCompleted={handleSectionCompleted}
                />
              )}
              {sequence === 2 && (
                <SurveyQuestions
                  questions={lang === 'en' ? survey2 : survey2ES}
                  section={2}
                  handleCompleted={handleSectionCompleted}
                />
              )}
              {sequence === 3 && (
                <FlourishingQuestions
                  questions={lang === 'en' ? FlourishingV2 : FlourishingESV2}
                  section={3}
                  handleCompleted={handleSectionCompleted}
                />
              )}
              {sequence === 4 && (
                <WorkEngagementQuestions
                  section={4}
                  handleCompleted={handleSectionCompleted}
                />
              )}
              {sequence === 5 && (
                <DemographicQuestions
                  section={5}
                  handleCompleted={handleSectionCompleted}
                />
              )}

              {sequence === 6 && (
                <div>
                  <div className=''>
                    <div className='text-center'>
                      <p>
                        {lang === 'en'
                          ? 'Press next to see your results.'
                          : 'Presione siguiente para ver sus resultados'}
                      </p>
                      <div className='flex justify-center'>
                        <button
                          className='mt-10 bg-gold px-8 py-2 text-white rounded-full hover:bg-emerald'
                          onClick={showResults}>
                          {lang === 'en' ? 'Next' : 'Siguiente'}
                        </button>
                      </div>
                    </div>
                    {/*  */}
                    <div className='ml-2' />
                  </div>
                  <div className='grid grid-cols-3'></div>
                </div>
              )}
              <br />
            </div>

            <div className='mt-14' />

            {/* <div>
          <button
            className='bg-yellow-200 px-3 py-1 rounded-lg'
            onClick={() => setQuizCompleted(true)}>
            DEV: go to results page
          </button>
        </div> */}
            {sequence === 0 && (
              <>
                <div className='mt-14' />
                <div className='prose'>
                  <hr />
                  <h1 className=''>Know more about the Quiz</h1>
                </div>

                <Results answers={answers} showResults={false} />
              </>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
